import {FC, memo} from 'react';
import firebase from 'firebase'
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import LogoAwanOutline from '@material-ui/icons/CloudQueue';
import LogoChat from '@material-ui/icons/Chat';
import IkonListAnime from '@material-ui/icons/ListAlt';
import { SvgIconTypeMap } from '@material-ui/core';
import {Link} from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { IPengguna } from '../../Data/Pengguna'
import './NavigasiKanan.scss';

const NavigasiKanan: FC<INavigasiKanan> = memo((prop) => {
  const [auth] = useAuthState(firebase.auth())
  return(
    <nav className='navigasi-kanan'>
      <div className='navigasi-kanan-atas'>
        <IsiNavigasiKanan
          lokasiTautan={auth != null ? '/p/' + auth.uid : '/h/login'}
          teksNavigasiKanan={prop.pengguna != null ? prop.pengguna?.namaPengguna ?? '' : 'Login'}
          logoNavigasiKanan={AccountCircleOutlinedIcon}
          urlFoto={prop.pengguna?.urlFotoProfilKecilPengguna}
        />
        <IsiNavigasiKanan
          lokasiTautan='/'
          teksNavigasiKanan='Beranda'
          logoNavigasiKanan={HomeOutlinedIcon}
        />
        <IsiNavigasiKanan
          lokasiTautan='/h/forum-ortu'
          teksNavigasiKanan='Forum'
          logoNavigasiKanan={LogoChat}
        />
        <IsiNavigasiKanan
          lokasiTautan='/h/musiman/ini'
          teksNavigasiKanan='Musiman'
          logoNavigasiKanan={LogoAwanOutline}
        />
        <IsiNavigasiKanan
          lokasiTautan='/h/daftar/nonton'
          teksNavigasiKanan='Daftarku'
          logoNavigasiKanan={IkonListAnime}
        />
      </div>
      {/* <div className='navigasi-kanan-bawah'>
      </div> */}
    </nav>
  )
}, (pSeb, pSet) => {
  if(pSeb.pengguna !== pSet.pengguna ){
    return false
  }
  return true
})

export default NavigasiKanan;

const IsiNavigasiKanan: FC<IIsiNavigasiKanan> = (prop) => {
  return(
    <Link to={prop.lokasiTautan} style={{textDecoration: 'none'}}>
      <div className='isi-navigasi-kanan'>
        {prop.urlFoto != null ?
          <img src={prop.urlFoto} className='gambar-isi-navigasi-kanan'/>
          :
          prop.logoNavigasiKanan !== null && prop.logoNavigasiKanan !== undefined ? 
          <div className='gambar-isi-navigasi-kanan'>
            <prop.logoNavigasiKanan/>
          </div>
          :
          <div className='gambar-isi-navigasi-kanan'>
            {prop.logoPengganti}
          </div>
        }
        <div className='teks-isi-navigasi-kanan'>
          {prop.teksNavigasiKanan}
        </div>
      </div>
    </Link>
  )
}



interface INavigasiKanan {
  pengguna?: IPengguna | null;
}

interface IIsiNavigasiKanan{
  teksNavigasiKanan: string;
  lokasiTautan: string;
  logoNavigasiKanan?: OverridableComponent<SvgIconTypeMap<{}, "svg">> ;
  urlFoto?: string | null;
  logoPengganti?: OverridableComponent<SvgIconTypeMap<{}, "svg">> ;
}