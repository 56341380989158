import { FC } from "react";
import ChevKanan from '@material-ui/icons/ChevronRight';
import { useHistory } from "react-router-dom";
import "./KeKoleksiPengguna.scss"

const KeKoleksiPengguna: FC<IKeKoleksiPengguna> = (p) => {
  const sejarah = useHistory()
  const handelKlik = () => {
    sejarah.push(p.href)
  }
  return(
    <button className="ke-koleksi-pengguna-profil-apl-animemo" onClick={handelKlik}>
      <h6>
        {p.teks}
      </h6>
      <ChevKanan width={50} height={50}/>
    </button>
  )
}

export default KeKoleksiPengguna


interface IKeKoleksiPengguna{
  teks: string;
  href: string;

}