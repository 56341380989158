import {FC, memo} from 'react';
import {DatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {toast} from 'react-toastify';
import { useState, useEffect} from 'react';


const InputTanggal: FC<IInputTanggal> = memo((prop) => {
  const [berapaxBerubah, setBerapaxBerubah] = useState<number>(0)
  useEffect(() => {
    if((berapaxBerubah == 2 || berapaxBerubah == 12) 
    && (prop.promptPetunjukTahun != null ? prop.promptPetunjukTahun : true)){
      toast.dark('ubah tahun dengan mengklik tahun di pojok kiri atas dialog', {
        position: "bottom-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
  }, [berapaxBerubah])

  return(
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <DatePicker
        id='pemilih-tanggal-lahir'
        color='primary'
        variant='dialog'
        format="dd/MM/yyyy"
        label={prop.label}
        onChange={prop.handelTanggalBerubah}
        value={prop.tanggalTerpilih}
        onMonthChange={() => setBerapaxBerubah(berapaxBerubah + 1)}
        onClose={() => setBerapaxBerubah(0)}
        size='medium'
      />
    </MuiPickersUtilsProvider>
  )
}, (pSeb, pSet) => {
  if(pSeb.tanggalTerpilih !== pSet.tanggalTerpilih){
    return false
  }
  return true
}
)

export default InputTanggal;

interface IInputTanggal{
  handelTanggalBerubah: (date: Date | null) => void;
  tanggalTerpilih: Date | null;
  label?: string;
  promptPetunjukTahun?: boolean
}