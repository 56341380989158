import { useAuthState } from 'react-firebase-hooks/auth';
import { toast } from "react-toastify";
import firebase from "firebase"
import {DataIkutDiikuti} from "Data/Pengguna"
import { useState } from 'react';
import { waktuRealtime } from 'fungsi/fungsiWaktu';

const useIkutDiikuti: TUseIkutDiikuti = (mengikuti: boolean, 
  idPengguna: string | null | undefined, 
  namaPengguna: string | null | undefined) => {
  
  const [auth] = useAuthState(firebase.auth())
  const [tblAktif, setTblAktif] = useState(true)

  const handleIlikIkutIkuti = async () => {
    if(auth){
      if(mengikuti){
        try{
          setTblAktif(false)
          await firebase.firestore().doc(`ikutDiikuti/${auth.uid + idPengguna}`).delete()
          setTimeout(() => {
            toast.success(`Berhenti mengikuti ${namaPengguna}`)
            setTblAktif(true)
          }, 1500)
        }catch(e){
          setTblAktif(true)
        }
      }else{
        try{
          setTblAktif(false)
          const ikutDiikuti = new DataIkutDiikuti();
          const [waktu] = await waktuRealtime()
          ikutDiikuti.idDiikuti = idPengguna || null
          ikutDiikuti.idPengikut = auth.uid
          ikutDiikuti.waktuMengikuti = waktu
          await firebase.firestore().doc(`ikutDiikuti/${auth.uid + idPengguna}`).set(Object.assign({}, ikutDiikuti))
          setTimeout(() => {
            toast.success(`Berhasil mengikuti ${namaPengguna}`)
            setTblAktif(true)
          }, 1500)
        }catch(e){
          setTblAktif(true)
        }
      }
    }
  }

  return [handleIlikIkutIkuti, tblAktif]
}

type TUseIkutDiikuti = (mengikuti: boolean, 
  idPengguna: string | null | undefined, 
  namaPengguna: string | null | undefined) => [() => Promise<void>, boolean]

export default useIkutDiikuti