import { FC, useEffect, useRef, useState } from 'react'
import './ForumAnak.scss'
import { DataAnakForum, DataForum } from 'Data/DataForum'
import {DataLaporanPengguna, DataLaporkanPos} from 'Data/LaporanPengguna'
import { RouteComponentProps, useHistory } from 'react-router'
import useFetchFirestore from 'fungsi/useFetchFirestore'
import firebase from 'firebase'
import IsiForumAnak from '../Komponen/IsiForumAnak'
import LoadingLaman from 'laman/loadingLaman/LoadingLaman'
import BalasForum from '../Komponen/BalasForum'
import InputRadioDialog from "komponen/input/InputRadioDialog"
import { useAuthState } from 'react-firebase-hooks/auth'
import { waktuRealtime } from 'fungsi/fungsiWaktu'
import { toast } from 'react-toastify'
import IkonBendera from '@material-ui/icons/Flag'
import useHapusAnakForum from '../Komponen/fungsi/useHapusAnakForum'
import DialogHapusUmum from '../Komponen/DialogHapusUmum'
import useHapusForum from '../Komponen/fungsi/useHapusForum'
import IkonHapus from '@material-ui/icons/Close';
import store from 'store'
import { hapusForum as hapusForumCache } from 'store/actions'


const ForumAnak: FC<IForumAnak> = (p) => {
  //AnFor === Anak Forum
  const [balasanPengguna, setBalasanPengguna] = useState<string | null>(null)
  const [urutanAnForTerpilih, setUrutanAnForTerpilih] = useState(
    p.match.params.slugTab === 'terbaru' ? indeksUrutanAnakForum.TERBARU : indeksUrutanAnakForum.TERLAMA
    )
  const [bukaLaporkan, setBukaLaporkan] = useState(false)
  const [bukaLaporkanForum, setBukaLaporkanForum] = useState(false)
  const [isiLaporkanPos, setIsiLaporkanPos] = useState<string[]>([])
  const [isiLaporkanForum, setIsiLaporkanForum] = useState<string[]>([])
  const [anakForumLaporkan, setAnakForumLaporkan] = useState<DataAnakForum | null>(null)
  const [forum, setForum] = useState<DataForum | null>(null)
  const [auth] = useAuthState(firebase.auth())
  const sejarah = useHistory()

  const [arAnakForum, refAnForTerakhir, indeksAnForTerakhir, loading, dokTerakhirDalamLayar, cacheLaman, refreshLaman] =
  useFetchFirestore<DataAnakForum>(
    10, 
    (firebase.firestore().collection('koleksiAnakForum')
      .where('idForum', '==', p.match.params.idForum)
      .orderBy('tanggalBuatAnakForum', 'asc')), 
    'forumAnak' + p.match.params.idForum,
    urutanAnForTerpilih,
    undefined,
    500,
    indeksUrutanAnakForum.TERLAMA
  )
  const [arAnakForumTrblk, 
    refAnForTerakhirTrblk, 
    indeksAnForTerakhirTrblk, 
    loadingTrblk, 
    dokTerakhirDalamLayarTrblk, 
    cacheLamanTrblk,
    refreshLamanTerbalik] =
  useFetchFirestore<DataAnakForum>(
    10, 
    (firebase.firestore().collection('koleksiAnakForum')
      .where('idForum', '==', p.match.params.idForum)
      .orderBy('tanggalBuatAnakForum', 'desc')), 
    'forumAnakTerbaru' + p.match.params.idForum,
    urutanAnForTerpilih,
    undefined,
    500,
    indeksUrutanAnakForum.TERBARU
  )

  const refAnForPertama = useRef<HTMLDivElement | null>(null)
  const refAnForPertamaTrblk = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if(urutanAnForTerpilih === indeksUrutanAnakForum.TERLAMA){
      refAnForPertama.current?.scrollIntoView()
    }else{
      refAnForPertamaTrblk.current?.scrollIntoView()
    }
  }, [urutanAnForTerpilih])

  useEffect(() => {
    const dapatkanFirestore = async () => {
      try{
        const dokLaporPos: any = (await firebase.firestore().doc('nilaiServer/laporkanPos').get()).data()
        const laporkanPosDapat: DataLaporkanPos = dokLaporPos
        setIsiLaporkanPos(laporkanPosDapat.anakForum || ["KOSONG"])
        setIsiLaporkanForum(laporkanPosDapat.forum || ["KOSONG"])
      }catch(e){
        setIsiLaporkanPos(isiLaporkanCadangan)
        setIsiLaporkanForum(isiLaporkanCadangan)
      }
      try{
        const dokForum: any = (await firebase.firestore().doc('forumPengguna/' + p.match.params.idForum).get()).data()
        const forumDapat: DataForum = dokForum
        setForum(forumDapat)
      }catch(e){
        setForum(null)
      }
    }
    dapatkanFirestore()
  }, [])

  const handelKlikTab = (indeksTerpilih: number) => {
    setUrutanAnForTerpilih(indeksTerpilih)
    let slugTab: TSlugForumAnak = 'terbaru'
    switch(indeksTerpilih){
      case indeksUrutanAnakForum.TERLAMA:
        slugTab = 'terlama';
        break;
      case indeksUrutanAnakForum.TERBARU:
        slugTab = 'terbaru';
        break;
    }
    sejarah.push(`/h/forum-balasan/${p.match.params.idForum}/${slugTab}`)
  }
  
  const handelLaporkanPos = async (nilaiLaporkan: number | null) => {
    if(anakForumLaporkan){
      const [waktuSaatIni] = await waktuRealtime()
      const laporkan = new DataLaporanPengguna()
      const idPelapor = auth?.uid || "Anonim"
      laporkan.idAnakForum = anakForumLaporkan.idAnakForum
      laporkan.idForum = p.match.params.idForum
      laporkan.idOrtuForum = null
      laporkan.gambarAnakForum = anakForumLaporkan.gambarAnakForum
      laporkan.gambarForum = null
      laporkan.balasanAnakForum = anakForumLaporkan.balasanAnakForum
      laporkan.idPelapor = auth?.uid || "Anonim"
      laporkan.idPengguna = anakForumLaporkan.idPengguna
      laporkan.isiAnakForum = anakForumLaporkan.isiAnakForum
      laporkan.isiForum = null
      laporkan.jenisLaporan = 0
      laporkan.namaPengguna = anakForumLaporkan.namaPengguna
      laporkan.sebabLapor = nilaiLaporkan || 0
      laporkan.tanggalLapor = waktuSaatIni
      const dbLaporan = firebase.firestore()
        .doc('laporanDariPengguna/Lapor' + idPelapor + anakForumLaporkan.idPengguna + waktuSaatIni.seconds)
      await dbLaporan.set(Object.assign({}, laporkan))
      toast.dark("Terlaporkan, terima kasih sudah melaporkan")
    }
  }

  const handelLaporkanForum  = async (nilaiLaporkan: number | null) => {
    if(forum){
      const [waktuSaatIni] = await waktuRealtime()
      const laporkan = new DataLaporanPengguna()
      const idPelapor = auth?.uid || "Anonim"
      laporkan.idAnakForum = null
      laporkan.idForum = p.match.params.idForum
      laporkan.idOrtuForum = forum.idOrtuForum
      laporkan.gambarAnakForum = null
      laporkan.gambarForum = forum.gambarForum
      laporkan.balasanAnakForum = null
      laporkan.idPelapor = auth?.uid || "Anonim"
      laporkan.idPengguna = forum.idPengguna
      laporkan.isiAnakForum = null
      laporkan.isiForum = null
      laporkan.jenisLaporan = 1
      laporkan.namaPengguna = forum.namaPengguna
      laporkan.sebabLapor = nilaiLaporkan || 0
      laporkan.tanggalLapor = waktuSaatIni
      const dbLaporan = firebase.firestore()
        .doc('laporanDariPengguna/Lapor' + idPelapor + forum.idPengguna + waktuSaatIni.seconds)
      await dbLaporan.set(Object.assign({}, laporkan))
      toast.dark("Terlaporkan, terima kasih sudah melaporkan")
    }
  }

  //hapus Anak forum
  const [bukaHapusAnakForum, setBukaHapusAnakForum] = useState(false)
  const [anakForumHapus, setAnakForumHapus] = useState<DataAnakForum | null>(null)
  const hapusAnakForum = useHapusAnakForum()

  const [bukaHapusForum, setBukaHapusForum] = useState(false)
  const hapusForum = useHapusForum()

  return(
    <div className='forum-anak-apl-animemo'>
      <div className='div-kosong-atas' ref={refAnForPertama}/>
      <div className='kontainer-urutan-anak-forum'>
        {(auth && forum && (auth.uid === forum.idPengguna)) ?
          <button className='ikon-bendera-forum-anak-apl' onClick={() => {
            setBukaHapusForum(true)
            }}>
            <IkonHapus style={{width: '25px', height: '25px'}}/>
          </button>
          :
          <button className='ikon-bendera-forum-anak-apl' onClick={() => {
            setBukaLaporkanForum(true)
            }}>
            <IkonBendera style={{width: '25px', height: '25px'}}/>
          </button>
        }
        <div className='urutan-anak-forum'>
          <h5 className={urutanAnForTerpilih === indeksUrutanAnakForum.TERLAMA ? 'teks-terpilih' : 'teks'} 
            onClick={() => {handelKlikTab(indeksUrutanAnakForum.TERLAMA)}}>
            Balasan Lama
          </h5>
          <h5 className={urutanAnForTerpilih === indeksUrutanAnakForum.TERBARU ? 'teks-terpilih' : 'teks'} 
            onClick={() => {handelKlikTab(indeksUrutanAnakForum.TERBARU)}}>
            Balasan Baru
          </h5>
        </div>
      </div>
      {(loadingTrblk && urutanAnForTerpilih === indeksUrutanAnakForum.TERLAMA) && <LoadingLaman />}
      <ul className='list-anak-forum' 
      style={indeksUrutanAnakForum.TERLAMA === urutanAnForTerpilih ? {} : {flexDirection: 'column-reverse'}}>
        {indeksUrutanAnakForum.TERLAMA === urutanAnForTerpilih ? arAnakForum.map((nilai, indeks) => {
          return <IsiForumAnak 
            isi={nilai}
            indeks={indeks}
            indeksTerakhir={indeksAnForTerakhir}
            refTerakhir={refAnForTerakhir}
            cacheLaman={cacheLaman}
            setBalasanPengguna={setBalasanPengguna}
            setBukaLaporkan={setBukaLaporkan}
            setAnakForumLaporkan={setAnakForumLaporkan}
            setAnakForumHapus={setAnakForumHapus}
            setBukaHapus={setBukaHapusAnakForum}
          />
        })
        :
        arAnakForumTrblk.map((nilai, indeks) => {
          return <IsiForumAnak 
            isi={nilai}
            indeks={indeks}
            indeksTerakhir={indeksAnForTerakhirTrblk}
            refTerakhir={refAnForTerakhirTrblk}
            cacheLaman={cacheLamanTrblk}
            setBalasanPengguna={setBalasanPengguna}
            setBukaLaporkan={setBukaLaporkan}
            setAnakForumLaporkan={setAnakForumLaporkan}
            setAnakForumHapus={setAnakForumHapus}
            setBukaHapus={setBukaHapusAnakForum}
          />
        })}
      </ul>
      {forum?.statusEditPengguna && <BalasForum balasan={balasanPengguna} setBalasan={setBalasanPengguna} 
        idForum={p.match.params.idForum}
        refreshLamanTerbalik={refreshLamanTerbalik}
        setUrutanAnFor={setUrutanAnForTerpilih}
        />}
      <div className='div-kosong-atas' ref={refAnForPertamaTrblk}/>
      {(loading || loadingTrblk) && <LoadingLaman />}
      <InputRadioDialog 
        judulPilihan={"Laporkan Balasan"}
        isiPilihan={isiLaporkanPos} 
        pilihanTerpilih={0}
        buka={bukaLaporkan}
        setBuka={setBukaLaporkan}
        onOK={(nilai) => {
          handelLaporkanPos(nilai)
        }}
      />
      <InputRadioDialog 
        judulPilihan={"Laporkan Forum"}
        isiPilihan={isiLaporkanForum} 
        pilihanTerpilih={0}
        buka={bukaLaporkanForum}
        setBuka={setBukaLaporkanForum}
        onOK={(nilai) => {
          handelLaporkanForum(nilai)
        }}
      />
      <DialogHapusUmum buka={bukaHapusAnakForum}
        handelBatal={() => {setBukaHapusAnakForum(false); setAnakForumHapus(null)}}
        handelOK={async () => {
          console.log("hapus")
          const berhasil = await hapusAnakForum(anakForumHapus); 
          if(berhasil){
            refreshLamanTerbalik(); refreshLaman();
            setBukaHapusAnakForum(false)
          }
        }}
      />
      <DialogHapusUmum buka={bukaHapusForum}
        handelBatal={() => {setBukaHapusForum(false)}}
        handelOK={async () => {
          const berhasil = await hapusForum(forum); 
          if(forum && forum.idOrtuForum && berhasil){
            store.dispatch(hapusForumCache("forum" + forum.idOrtuForum))
            store.dispatch(hapusForumCache("forumbalasan" + forum.idOrtuForum))
            sejarah.push('/h/forum-ortu')
          }
        }}
        teks={"hapus forum"}
      />
    </div>
  )
}

const isiLaporkanCadangan = [
  "Pornografi atau vulgar yang berlebihan",
  "Kekerasan atau kekejaman belebihan",
  "Mengandung unsur SARA atau rasisme",
  "Pembullyan atau kata kasar berlebihan",
  "Obat-obatan terlarang atau aktivitas ilegal lainnya",
  "Membahayakan anak",
  "Spam atau menyesatkan"
]

export const indeksUrutanAnakForum = {
  TERBARU: 0,
  TERLAMA: 1,
}

type TSlugForumAnak = 'terbaru' | 'terlama'

interface IForumAnak extends RouteComponentProps<{idForum: string, slugTab: TSlugForumAnak}>{
  
}

export default ForumAnak