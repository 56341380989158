import { FC } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useSelector } from "react-redux";
import {IRootRedux} from "store/reducers"
import IsiPemberitahuan from "../IsiPemberitahuan";
import "./Pemberitahuan.scss";
import firebase from "firebase";
import { Redirect } from "react-router-dom";

const Pemberitahuan: FC<IPemberitahuan> = (p) => {
  const dataPemberitahuan = useSelector((state: IRootRedux) => state.pengguna?.pemberitahuan)
  const [auth] = useAuthState(firebase.auth())
  if(auth){
    return(
      <section className="pemberitahuan-apl-animemo">
        {(dataPemberitahuan && dataPemberitahuan.length > 0) ? <ul className="list-pemberitahuan">
          {dataPemberitahuan?.map((nilai, indeks) => (
            <IsiPemberitahuan pemberitahuan={nilai} indeks={indeks}/>
          ))}
        </ul> :
        <h5>
          Pemberitahuan Kosong
        </h5>
        }
      </section>
    )
  }else{
    return <Redirect to={'/h/login'} />
  }
}

interface IPemberitahuan{}

export default Pemberitahuan