import { FC } from "react";
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader'
import { CssLoading } from '../../css/Tema'
import { Warna } from '../../css/Tema'
import './LoadingLaman.scss'

const LoadingLaman: FC<ILoadingLaman> = (p) => {
  return(
    <div className='laman-loading'>
      <div className='gambar-loading'>
        <ClimbingBoxLoader css={CssLoading} color={Warna.primary.main}/>
      </div>
      <div className='teks-loading'>
        Memuat{' '}{p.judulLaman}
      </div>
    </div>
  )
}

export default LoadingLaman

interface ILoadingLaman{
  judulLaman?: string;
}