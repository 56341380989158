import {IHasilUpload} from './useUploadGbrForum'
import firebase from "firebase"
import { useAuthState } from 'react-firebase-hooks/auth'
import {DataAnakForum, DataForum} from 'Data/DataForum'
import { FourK } from '@material-ui/icons'
import { toast } from 'react-toastify'
import { MutableRefObject, useRef, useState } from 'react'
import { waktuRealtime } from 'fungsi/fungsiWaktu'
import cekKataKotor from 'fungsi/cekKataKotor'

function useUploadData(handelUploadGbr: () => Promise<IHasilUpload>): 
[MutableRefObject<HTMLInputElement | null>, MutableRefObject<HTMLInputElement | null>, TUploadForum, boolean] {
  const refInputJudul = useRef<HTMLInputElement | null>(null)
  const refInputIsi = useRef<HTMLInputElement | null>(null)
  const [auth] = useAuthState(firebase.auth())
  const [loading, setLoading] = useState(false)

  const handelUploadForum = async (idForumOrtu: string | null, statusEditPengguna: boolean | null) => {
    const hasilUploadGbr = await handelUploadGbr()
    const [waktuCadangan] = (await waktuRealtime())
    const waktuUploadGbr = hasilUploadGbr.waktu || waktuCadangan
    const gbrBenar = hasilUploadGbr.success && hasilUploadGbr.data && waktuUploadGbr
    const refBenar = refInputJudul && refInputIsi && refInputJudul.current && refInputIsi.current
    const isiBenar = refBenar && refInputJudul.current!.value.length > 0
    const judulBenar = refBenar  && refInputJudul.current!.value.length > 0
    const varLuarBenar = idForumOrtu !== null && statusEditPengguna !== null
    const isiKataKasar = cekKataKotor(refInputIsi.current?.value, "Pos")
    const judulKataKasar = cekKataKotor(refInputJudul.current?.value, "Judul")
    if(auth && gbrBenar && refBenar && isiBenar && judulBenar && varLuarBenar && !isiKataKasar && !judulKataKasar){
      setLoading(true)
      const forum = new DataForum()
      const anakForum = new DataAnakForum()
      forum.idOrtuForum = idForumOrtu
      forum.idForum = auth.uid + waktuUploadGbr!.seconds
      forum.idPengguna = auth.uid
      forum.judulForum = refInputJudul.current!.value
      forum.isiForum = refInputIsi.current!.value
      forum.namaPengguna = auth.displayName
      forum.gambarForum = hasilUploadGbr.data!.urlDownloadGambarSedang || null
      forum.gambarKecilForum = hasilUploadGbr.data!.urlDownloadGambarKecil || null
      forum.tanggalBuatForum = waktuUploadGbr
      forum.tanggalEditForum = waktuUploadGbr
      forum.terakhirDibalas = waktuUploadGbr
      forum.jumlahBalasan = 0
      forum.statusEditPengguna = statusEditPengguna

      anakForum.idForum = forum.idForum
      anakForum.idAnakForum = forum.idForum
      anakForum.idPengguna = forum.idPengguna
      anakForum.namaPengguna = forum.namaPengguna
      anakForum.isiAnakForum = forum.judulForum + "\n\n" + forum.isiForum
      anakForum.balasanAnakForum = null
      anakForum.gambarAnakForum = forum.gambarForum
      anakForum.tanggalBuatAnakForum = forum.tanggalBuatForum
      anakForum.tanggalEditAnakForum = forum.tanggalEditForum
      anakForum.apakahBalasan = false

      const dbForum = firebase.firestore().doc('forumPengguna/' + auth.uid + waktuUploadGbr.seconds)
      const dbAnakForum = firebase.firestore().doc('koleksiAnakForum/' + auth.uid + waktuUploadGbr.seconds)
      try{
        await dbForum.set(Object.assign({}, forum))
        await dbAnakForum.set(Object.assign({}, anakForum))
        setLoading(false)
        return true
      }catch(e){
        toast.error("galat upload forum")
        setLoading(false)
        return false
      }
    }else if(gbrBenar && refBenar && isiBenar && judulBenar && !isiKataKasar && !judulKataKasar){
      toast.error("anda perlu login")
      return false
    }else if(auth && gbrBenar && refBenar && judulBenar && !isiKataKasar && !judulKataKasar){
      toast.error("isi forum tidak boleh kosong")
      return false
    }else if(auth && gbrBenar && refBenar && isiBenar && !isiKataKasar && !judulKataKasar){
      toast.error("judul forum tidak boleh kosong")
      return false
    }else{
      return false
    }
  }

  return [refInputIsi, refInputJudul, handelUploadForum, loading]
}

type TUploadForum = (idForumOrtu: string | null, statusEditPengguna: boolean | null) => Promise<boolean>

export default useUploadData