import './InputGambar.scss';
import {FC, memo, useState, useEffect, useRef, useCallback, ChangeEvent, Dispatch } from 'react';
import useDimensiLayar from '../../../fungsi/UseDimensiLayar';
import ReactCrop from 'react-image-crop';
import { Modal, SvgIconTypeMap } from '@material-ui/core';
import Backdrop from '@material-ui/core/Backdrop';
import 'react-image-crop/dist/ReactCrop.css';
import IkonPanorama from '@material-ui/icons/Panorama';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import firebase from 'firebase';
import Resizer from 'react-image-file-resizer';
import Tombol from '@material-ui/core/Button';
import prosesBlob, { IURLGambar } from './fungsi/prosesBlob'
import { useAuthState } from 'react-firebase-hooks/auth';
import { waktuRealtime } from 'fungsi/fungsiWaktu';

export async function uploadGambarFirebase(kanvas: HTMLCanvasElement | null, crop: ReactCrop.Crop | null, folderUpload: string
  , namaFile: string, setSelesaiUpload: (selesai: boolean) => void
  , setURLGambar?: (urlBesar: string | null, urlSedang: string | null, urlKecil: string | null) => void
  , kompres3Kali?: boolean, ekstensiFileKecil?: string, ekstensiFileSedang?: string, ekstensiFileBesar?: string,
  ){

  if(kanvas !== null && crop !== null){
    const efKecil = ekstensiFileKecil ?? 'Kecil'
    const efSedang = ekstensiFileSedang ?? 'Sedang'
    const efBesar = ekstensiFileBesar ?? 'Besar'
    const tinggi = kanvas.height
    const lebar = kanvas.width
    const rasioAspek = lebar/tinggi
    const resizeKecilTinggi = 100
    const resizeSedangTinggi = 300
    const resizeBesarTinggi = 550
    const resizeKecilLebar = 100 * rasioAspek
    const resizeSedangLebar = 300 * rasioAspek
    const resizeBesarLebar = 550 * rasioAspek

    const resizeFileBlob = (blob: Blob, lebar: number, tinggi: number, kompresi: number) =>
      new Promise<Blob>((resolve) => {
        Resizer.imageFileResizer(
          blob,
          lebar,
          tinggi,
          "JPEG",
          kompresi,
          0,
          (b) => {
            resolve(b as Blob);
          },
          "blob",
          lebar - 5,
          tinggi - 5,
        );
      });

    kanvas.toBlob(
      async (blob) => {
        if (blob != null) {
          try {
            if (kompres3Kali ?? false) {
              const gambarBesar: Blob = await resizeFileBlob(blob, resizeBesarLebar, resizeBesarTinggi, 70);
              const gambarSedang: Blob = await resizeFileBlob(blob, resizeSedangLebar, resizeSedangTinggi, 50);
              const gambarKecil: Blob = await resizeFileBlob(blob, resizeKecilLebar, resizeKecilTinggi, 30);

              const ssGambarBesar = await firebase.storage().ref().child(folderUpload + '/' + namaFile + efBesar).put(gambarBesar);
              const ssGambarSedang = await firebase.storage().ref().child(folderUpload + '/' + namaFile + efSedang).put(gambarSedang);
              const ssGambarKecil = await firebase.storage().ref().child(folderUpload + '/' + namaFile + efKecil).put(gambarKecil);

              const urlDownloadGambarBesar = await ssGambarBesar.ref.getDownloadURL();
              const urlDownloadGambarSedang = await ssGambarSedang.ref.getDownloadURL();
              const urlDownloadGambarKecil = await ssGambarKecil.ref.getDownloadURL();

              if (setURLGambar != null) {
                setURLGambar(urlDownloadGambarBesar, urlDownloadGambarSedang, urlDownloadGambarKecil);
              }
              setSelesaiUpload(true);
            } else {
              const gambarSedang: Blob = await resizeFileBlob(blob, resizeSedangLebar, resizeSedangTinggi, 50);
              const ssGambarSedang = await firebase.storage().ref().child(folderUpload + '/' + namaFile + efSedang).put(gambarSedang);
              const urlDownloadGambarSedang = await ssGambarSedang.ref.getDownloadURL();
              if (setURLGambar != null) {
                setURLGambar(null, urlDownloadGambarSedang, null);
              }
              setSelesaiUpload(true)
            }
          } catch (e) {
            setSelesaiUpload(true);
          }
        } else {
          setSelesaiUpload(true);
        }
        // firebase.storage().ref().child(refUpload + namaFile).put(blob)
        // untuk didownload
        // const previewUrl = window.URL.createObjectURL(blob);
        // const anchor = document.createElement('a');
        // anchor.download = '';
        // anchor.href = URL.createObjectURL(blob);
        // anchor.click();
        // window.URL.revokeObjectURL(previewUrl);
      },
      'image/png',
      1
    );
  }else{
    setSelesaiUpload(true)
  }

}

export async function uploadGambarFirebaseV2(kanvas: HTMLCanvasElement | null, crop: ReactCrop.Crop | null, 
  folderUpload: string
  , namaFile: string, kompres3Kali?: boolean, ekstensiFileKecil?: string, 
  ekstensiFileSedang?: string, ekstensiFileBesar?: string,
  ): Promise<{success: boolean, data: IURLGambar | null}>{

  if(kanvas !== null && crop !== null){
    const tinggi = kanvas.height
    const lebar = kanvas.width
    const rasioAspek = lebar/tinggi

    const resizeFileBlob = (blob: Blob, lebar: number, tinggi: number, kompresi: number) =>
      new Promise<Blob>((resolve) => {
        Resizer.imageFileResizer(
          blob,
          lebar,
          tinggi,
          "JPEG",
          kompresi,
          0,
          (b) => {
            resolve(b as Blob);
          },
          "blob",
          lebar - 5,
          tinggi - 5,
        );
      });

    const blob: Blob | null = await new Promise(resolve => kanvas.toBlob(resolve));

    try{
      const url = await prosesBlob(blob, kompres3Kali, resizeFileBlob, 
        rasioAspek, folderUpload, namaFile, ekstensiFileKecil, ekstensiFileSedang, ekstensiFileBesar)
      return url
    }catch(e){
      return {success: false, data: null}
    }
  }else{
    return {success: true, data: {
      urlDownloadGambarBesar: null,
      urlDownloadGambarKecil: null,
      urlDownloadGambarSedang: null
    }}
  }

}

const InputGambar: FC<IInputGambar> = memo((p) => {
  const [apakahEdit, setApakahEdit] = useState<boolean>(false)

  const [maksLebarInpGambar, setMaksLebarInpGamb] = useState<number | null>(null)
  const {lebarLayar, tinggiLayar} = useDimensiLayar()

  const [apakahAdaGambar, setApakahAdaGambar] = useState<boolean>(false)

  const [gbrTerpilih, setGbrTerpilih] = useState<string | ArrayBuffer | null>();
  const refGbr = useRef<HTMLImageElement | null>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const kontainerKanvasRef = useRef<HTMLDivElement | null> (null);
  const refInput = useRef<HTMLInputElement | null>(null)
  const [crop, setCrop] = useState<ReactCrop.Crop>({ unit: "px", aspect: p.rasioAspek});
  const [selesaiCrop, setSelesaiCrop] = useState<ReactCrop.Crop | null>(null);
  const [rasioCrop, setRasioCrop] = useState({lebar: 0, tinggi: 0})

  useEffect(() => {
    if (kontainerKanvasRef.current?.offsetWidth != null ) {
      setMaksLebarInpGamb(kontainerKanvasRef.current?.offsetWidth)
    }
  }, [lebarLayar]);

  useEffect(() => {
    if(p.setKanvas != null && previewCanvasRef.current != null){
      p.setKanvas(previewCanvasRef.current)
    }
  }, [previewCanvasRef.current])

  useEffect(() => {
    var rasioLebarCrop = (selesaiCrop?.width ?? 0)/(((selesaiCrop?.height ?? 0) + (selesaiCrop?.width ?? 1)) || 1)
    var rasioTinggiCrop = (selesaiCrop?.height ?? 0)/(((selesaiCrop?.height ?? 0) + (selesaiCrop?.width ?? 0)) || 1)
    if(rasioLebarCrop > rasioTinggiCrop){
      rasioTinggiCrop =  (rasioTinggiCrop/rasioLebarCrop)
      rasioLebarCrop = 1 
    }else if (rasioTinggiCrop >= rasioLebarCrop){
      rasioLebarCrop =  (rasioLebarCrop/rasioTinggiCrop)
      rasioTinggiCrop = 1
    }
    setRasioCrop({lebar: rasioLebarCrop, tinggi: rasioTinggiCrop})
    setApakahAdaGambar(true)
    if(!((selesaiCrop?.width || 0) > 0)){
      setApakahAdaGambar(false)
    }
    if(p.setCropSelesai != undefined){
      p.setCropSelesai(selesaiCrop)
    }
  }, [selesaiCrop])

  useEffect(() => {
    console.log('coba')
    setApakahAdaGambar(false)
  }, [p.logikaLuarHapusGbr])
  
  const handelGambarGanti = (even: ChangeEvent<HTMLInputElement>) => {
    if (even.target.files && even.target.files.length > 0) {
      onLoad(null)
      const reader = new FileReader();
      reader.addEventListener('load', () => setGbrTerpilih(reader.result));
      reader.readAsDataURL(even.target.files[0]);
    }
  };

  const handelEdit = () => {setApakahEdit(true)}
  const handelTutupEdit = () => {setApakahEdit(false)}
  const handelInput = () => {if(refInput != null && refInput.current != null){refInput.current.click()}}

  const refKontainerGambarEdit = useRef<HTMLDivElement | null>(null)

  const onLoad = useCallback((img: HTMLImageElement | null) => { 
    // if(refKontainerGambarEdit != null && refKontainerGambarEdit.current != null && img != null){
    //   const rasAspek = img.width/(img.height || 1)
    //   if(refKontainerGambarEdit.current.offsetHeight > refKontainerGambarEdit.current.offsetWidth){
    //     img.width = refKontainerGambarEdit.current.offsetWidth 
    //     img.height = refKontainerGambarEdit.current.offsetWidth * (1/rasAspek)
    //   }else{
    //     img.width = refKontainerGambarEdit.current.offsetHeight * rasAspek
    //     img.height = refKontainerGambarEdit.current.offsetHeight 
    //   }
    //   console.log('aspek: ' +rasAspek)
    // }
   
    refGbr.current = img;
  }, []);

  useEffect(() => {
    
    const kanvas = previewCanvasRef.current;
    const crHasil = selesaiCrop;
    const refGbrCurrent = refGbr.current;

    if (selesaiCrop != null && kanvas != null && crHasil != null && refGbrCurrent != null) {

      const image: HTMLImageElement = refGbrCurrent;

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const ctx = kanvas.getContext('2d');
      const pixelRatio = window.devicePixelRatio;

      kanvas.width = (crHasil.width || 0) * pixelRatio;
      kanvas.height = (crHasil.height || 0) * pixelRatio;

      if(ctx != null){
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
          image,
          (crHasil.x || 0 ) * scaleX,
          (crHasil.y || 0 ) * scaleY,
          (crHasil.width || 0) * scaleX,
          (crHasil.height || 0) * scaleY,
          0,
          0,
          crHasil.width || 0,
          crHasil.height || 0
        );
      }
    }
  }, [selesaiCrop]);

 

  return(
    <div className='input-gambar'>
      <input className='input-input-gambar' 
      type='file'
      accept='image/*'
      onChange={handelGambarGanti}
      onClick={handelEdit}
      ref={refInput}
      />
      <div
        className={p.gambarLingkaran ? 'kontainer-gambar-input-gambar-bundar': 'kontainer-gambar-input-gambar'}
        ref={kontainerKanvasRef}
        style={{
          height: Math.round(rasioCrop.tinggi * (maksLebarInpGambar || 0)) || (maksLebarInpGambar || 0) ,
        }}
        onClick={handelInput}
        >
        <canvas 
          className='gambar-input-gambar'
          ref={previewCanvasRef}
          style={{
            //karena rasio aspek lebar/tinggi maka yang dikalikan hanya tinggi
            width: Math.round((p.rasioAspek != null) ? ((maksLebarInpGambar ?? 0)) : (rasioCrop.lebar * (maksLebarInpGambar ?? 0))),
            height: Math.round((p.rasioAspek != null) ? ((maksLebarInpGambar ?? 0)*(1/p.rasioAspek)) : (rasioCrop.tinggi * (maksLebarInpGambar ?? 0))),
            display: (apakahAdaGambar ? 'inline' : 'none')
          }}
        />
        
        <div>
          {
            p.gbrPlaceHolder == null && p.sbrGambar == null ? 
            <IkonPanorama style={{height :'100%'
            , width: '100%'
            , display: (apakahAdaGambar ? 'none' : 'inline')
            }}/>
            :
            p.sbrGambar != null ?
            <img className="gbr-place-holder" src={p.sbrGambar} />
            :
            p.gbrPlaceHolder && 
            <p.gbrPlaceHolder style={{height :'100%'
            , width: '100%'
            , display: (apakahAdaGambar ? 'none' : 'inline')
            }}/>
            
          }
        </div>
      </div>
      <Modal
        className='modal-input-gambar-login'
        open={apakahEdit}
        closeAfterTransition
        onClose={handelTutupEdit}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <div className='kontainer-modal-crop-input-gambar'>
          <div className='kontainer-crop-input-gambar'
            ref={refKontainerGambarEdit}>
            <ReactCrop
              className='crop-input-gambar'
              src={gbrTerpilih as string}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={(c: ReactCrop.Crop, percCrop: ReactCrop.PercentCrop) => {setCrop(c)}}
              onComplete={((c: ReactCrop.Crop, percCrop: ReactCrop.PercentCrop) => {setSelesaiCrop(c)})}
            />
          </div>
          <div className='kontainer-tombol-crop-input-gambar'>
            <Tombol
              variant='contained' 
              color='primary' 
              onClick={handelTutupEdit}>potong gambar</Tombol>
          </div>
          <div className='petunjuk-crop-input-gambar'>
            klik dan drag gambar untuk memotong gambar
          </div>
        </div>
      </Modal>
    </div>
  )
}, (pSeb, pSet) => {
  if(pSeb.sbrGambar !== pSet.sbrGambar){
    return false
  }
  return true
})

export const InputGambarV2: FC<IInputGambar> = (p) => {
  const [apakahEdit, setApakahEdit] = useState<boolean>(false)

  const [maksLebarInpGambar, setMaksLebarInpGamb] = useState<number | null>(null)
  const {lebarLayar, tinggiLayar} = useDimensiLayar()

  const [apakahAdaGambar, setApakahAdaGambar] = useState<boolean>(false)

  const [gbrTerpilih, setGbrTerpilih] = useState<string | ArrayBuffer | null>();
  const refGbr = useRef<HTMLImageElement | null>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const kontainerKanvasRef = useRef<HTMLDivElement | null> (null);
  const refInput = useRef<HTMLInputElement | null>(null)
  const [crop, setCrop] = useState<ReactCrop.Crop>({ unit: "px", aspect: p.rasioAspek});
  const [selesaiCrop, setSelesaiCrop] = useState<ReactCrop.Crop | null>(null);
  const [rasioCrop, setRasioCrop] = useState({lebar: 0, tinggi: 0})

  useEffect(() => {
    if (kontainerKanvasRef.current?.offsetWidth != null ) {
      setMaksLebarInpGamb(kontainerKanvasRef.current?.offsetWidth)
    }
  }, [lebarLayar]);

  useEffect(() => {
    if(p.setKanvas != null && previewCanvasRef.current != null){
      p.setKanvas(previewCanvasRef.current)
    }
  }, [previewCanvasRef.current])

  useEffect(() => {
    var rasioLebarCrop = (selesaiCrop?.width ?? 0)/(((selesaiCrop?.height ?? 0) + (selesaiCrop?.width ?? 1)) || 1)
    var rasioTinggiCrop = (selesaiCrop?.height ?? 0)/(((selesaiCrop?.height ?? 0) + (selesaiCrop?.width ?? 0)) || 1)
    if(rasioLebarCrop > rasioTinggiCrop){
      rasioTinggiCrop =  (rasioTinggiCrop/rasioLebarCrop)
      rasioLebarCrop = 1 
    }else if (rasioTinggiCrop >= rasioLebarCrop){
      rasioLebarCrop =  (rasioLebarCrop/rasioTinggiCrop)
      rasioTinggiCrop = 1
    }
    setRasioCrop({lebar: rasioLebarCrop, tinggi: rasioTinggiCrop})
    setApakahAdaGambar(true)
    if(!((selesaiCrop?.width || 0) > 0)){
      setApakahAdaGambar(false)
    }
    if(p.setCropSelesai != undefined){
      p.setCropSelesai(selesaiCrop)
    }
  }, [selesaiCrop])

  useEffect(() => {
    console.log('coba')
    setApakahAdaGambar(false)
  }, [p.logikaLuarHapusGbr])
  
  const handelGambarGanti = (even: ChangeEvent<HTMLInputElement>) => {
    if (even.target.files && even.target.files.length > 0) {
      onLoad(null)
      const reader = new FileReader();
      reader.addEventListener('load', () => setGbrTerpilih(reader.result));
      reader.readAsDataURL(even.target.files[0]);
    }
  };

  const handelEdit = () => {setApakahEdit(true)}
  const handelTutupEdit = () => {setApakahEdit(false)}
  const handelSelesaiCrop = () => {
    p.onPotongGambar && p.onPotongGambar()
    setApakahEdit(false)
  }
  const handelInput = () => {if(refInput != null && refInput.current != null){refInput.current.click()}}

  const refKontainerGambarEdit = useRef<HTMLDivElement | null>(null)

  const onLoad = useCallback((img: HTMLImageElement | null) => { 
    // if(refKontainerGambarEdit != null && refKontainerGambarEdit.current != null && img != null){
    //   const rasAspek = img.width/(img.height || 1)
    //   if(refKontainerGambarEdit.current.offsetHeight > refKontainerGambarEdit.current.offsetWidth){
    //     img.width = refKontainerGambarEdit.current.offsetWidth 
    //     img.height = refKontainerGambarEdit.current.offsetWidth * (1/rasAspek)
    //   }else{
    //     img.width = refKontainerGambarEdit.current.offsetHeight * rasAspek
    //     img.height = refKontainerGambarEdit.current.offsetHeight 
    //   }
    //   console.log('aspek: ' +rasAspek)
    // }
   
    refGbr.current = img;
  }, []);

  useEffect(() => {
    
    const kanvas = previewCanvasRef.current;
    const crHasil = selesaiCrop;
    const refGbrCurrent = refGbr.current;

    if (selesaiCrop != null && kanvas != null && crHasil != null && refGbrCurrent != null) {

      const image: HTMLImageElement = refGbrCurrent;

      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      const ctx = kanvas.getContext('2d');
      const pixelRatio = window.devicePixelRatio;

      kanvas.width = (crHasil.width || 0) * pixelRatio;
      kanvas.height = (crHasil.height || 0) * pixelRatio;

      if(ctx != null){
        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
          image,
          (crHasil.x || 0 ) * scaleX,
          (crHasil.y || 0 ) * scaleY,
          (crHasil.width || 0) * scaleX,
          (crHasil.height || 0) * scaleY,
          0,
          0,
          crHasil.width || 0,
          crHasil.height || 0
        );
      }
    }
  }, [selesaiCrop]);

 

  return(
    <div className='input-gambar'>
      <input className='input-input-gambar' 
      type='file'
      accept='image/*'
      onChange={handelGambarGanti}
      onClick={handelEdit}
      ref={refInput}
      />
      <div
        className={p.gambarLingkaran ? 'kontainer-gambar-input-gambar-bundar': 'kontainer-gambar-input-gambar'}
        ref={kontainerKanvasRef}
        style={{
          height: Math.round(rasioCrop.tinggi * (maksLebarInpGambar || 0)) || (maksLebarInpGambar || 0) ,
        }}
        onClick={handelInput}
        >
        <canvas 
          className='gambar-input-gambar'
          ref={previewCanvasRef}
          style={{
            //karena rasio aspek lebar/tinggi maka yang dikalikan hanya tinggi
            width: Math.round((p.rasioAspek != null) ? ((maksLebarInpGambar ?? 0)) : (rasioCrop.lebar * (maksLebarInpGambar ?? 0))),
            height: Math.round((p.rasioAspek != null) ? ((maksLebarInpGambar ?? 0)*(1/p.rasioAspek)) : (rasioCrop.tinggi * (maksLebarInpGambar ?? 0))),
            display: (apakahAdaGambar ? 'inline' : 'none')
          }}
        />
        
        <div>
          {
            p.gbrPlaceHolder == null && p.sbrGambar == null ? 
            <IkonPanorama style={{height :'100%'
            , width: '100%'
            , display: (apakahAdaGambar ? 'none' : 'inline')
            }}/>
            :
            p.sbrGambar != null ?
            <img className="gbr-place-holder" src={p.sbrGambar} />
            :
            p.gbrPlaceHolder && 
            <p.gbrPlaceHolder style={{height :'100%'
            , width: '100%'
            , display: (apakahAdaGambar ? 'none' : 'inline')
            }}/>
            
          }
        </div>
      </div>
      <Modal
        className='modal-input-gambar-login'
        open={apakahEdit}
        closeAfterTransition
        onClose={handelTutupEdit}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        disableBackdropClick>
        <div className='kontainer-modal-crop-input-gambar'>
          <div className='kontainer-crop-input-gambar'
            ref={refKontainerGambarEdit}>
            <ReactCrop
              className='crop-input-gambar'
              src={gbrTerpilih as string}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={(c: ReactCrop.Crop, percCrop: ReactCrop.PercentCrop) => {setCrop(c)}}
              onComplete={((c: ReactCrop.Crop, percCrop: ReactCrop.PercentCrop) => {setSelesaiCrop(c)})}
            />
          </div>
          <div className='kontainer-tombol-crop-input-gambar'>
            <Tombol
              variant='contained' 
              color='primary' 
              onClick={handelSelesaiCrop}>potong gambar</Tombol>
          </div>
          <div className='petunjuk-crop-input-gambar'>
            klik dan drag gambar untuk memotong gambar
          </div>
        </div>
      </Modal>
    </div>
  )
}

export function useUploadGbr(): TUseUploadGbr {
  const [kanvas, setKanvas] = useState<HTMLCanvasElement | null>(null)
  const [crop, setCrop] = useState<ReactCrop.Crop | null>(null)
  const [loading, setLoading] = useState(false)
  const setGbrKosong = () => {
    setKanvas(null)
    setCrop(null)
  }

  const handelUploadGbr: TUploadGbr = async (namaFile: string, folderUpload: string, kali3Upload = false) => {
    setLoading(true)
    const [waktu] = await waktuRealtime()
    try{
      const hasilURL = await uploadGambarFirebaseV2(kanvas, crop, folderUpload, namaFile, kali3Upload, "Kecil", "Sedang")
      setLoading(false)
      if(hasilURL.success){
        return {
          success: true,
          waktu: waktu,
          data: hasilURL.data
        }
      }else{
        return {
          success: false,
          waktu: null,
          data: null
        }
      }
    }catch(e){
      setLoading(false)
      console.error("upload gbr gagal")
      return {
        success: false,
        waktu: null,
        data: null,
      }
    }
  }
  return [setKanvas, kanvas, crop, setCrop, handelUploadGbr, setGbrKosong, loading]
}

type TUseUploadGbr = [Dispatch<HTMLCanvasElement | null>, 
  HTMLCanvasElement | null, 
  ReactCrop.Crop | null,
  Dispatch<ReactCrop.Crop | null>,
  TUploadGbr,
  () => void,
  boolean
]

type TUploadGbr = (namaFile: string, folderUpload: string, kali3Upload?: boolean) => Promise<IHasilUpload>;

interface ICrop {
  unit?: string;
  width?: number;
  height?: number;
  x?: number;
  y?: number;
  aspect?: number;
}

export interface IHasilUpload{
  success: boolean,
  waktu: firebase.firestore.Timestamp | null,
  data: IURLGambar | null
}

/**
 * @param rasioAspek lebar/tinggi
 * @param gbrPlaceHolder
 */
interface IInputGambar{
  /** rasio aspek: lebar/tinggi */
  rasioAspek?: number;
  gbrPlaceHolder?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  sbrGambar?: string;
  onGantiSbrGambar?: (sbrGambar: string | null) => void;
  setApakahAdaGbr?: (ada: boolean) => void;
  /**kanvas untuk diupload */
  kanvas?: HTMLCanvasElement | null;
  setKanvas: (kanvas: HTMLCanvasElement | null) => void;
  setCropSelesai: (cropSelesai: ReactCrop.Crop | null) => void;
  logikaLuarHapusGbr?: boolean;
  /**bukaEdit jika true, maka akan menginvoke useEffect yang ada didalam untuk mengedit */
  bukaEdit?: boolean;
  setAdaGambar?: Dispatch<boolean>;
  gambarLingkaran?: boolean;
  onPotongGambar?: () => void
}

export default InputGambar