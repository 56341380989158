import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import firebase from 'firebase';
import { DataIkutDiikuti } from 'Data/Pengguna';
import { useDocument, useDocumentData } from 'react-firebase-hooks/firestore';

const useFetchIkutDiikuti: tipe  = (idPengguna: string | null | undefined) => {
  const [auth] = useAuthState(firebase.auth())
  const [profilSendiri, setProfilSendiri] = useState(false)
  const [mengikuti, setMengikuti] = useState(false)
  const [db, setDb] = useState<firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | null>(null)

  const idPenggunaLogin = auth?.uid || ""
  const [dokIkutDiikuti, loading, error]  = useDocumentData(db)

  useEffect(() => {
    setDb(firebase.firestore().doc(`ikutDiikuti/${idPenggunaLogin + idPengguna}`))
    if(auth && (auth.uid !== idPengguna)){
      if(dokIkutDiikuti){
        setMengikuti(true)
      }else{
        setMengikuti(false)
      }
      setProfilSendiri(false)
    }else{
      setProfilSendiri(true)
    }
  }, [auth, idPengguna, dokIkutDiikuti])

  return [mengikuti, profilSendiri, loading]
}

type tipe = (idPengguna: string | null | undefined) => [boolean, boolean, boolean]

export default useFetchIkutDiikuti