import {Dispatch, FC, RefObject, useEffect} from 'react'
import {IDataAnime} from '../../../../../Data/Anime'
import './TabMusiman.scss'
import IsiMusiman from "../../IsiMusiman"

export const TabMusiman: FC<ITabMusiman> = (p) => {
  return(
    <ul className='tab-musim-ini-musiman'>
      {
        p.animeMusiman.map((nilai, indeks) => {
          return <IsiMusiman anime={nilai} 
            indeks={indeks}
            animeTerakhirLoad={p.animeTerakhirLoad}
            refAnimeTerakhir={p.refAnimeTerakhir}
            cacheLaman={p.cacheLaman}
            bukaDgnModal={p.bukaDgnModal}
            setIdAnimeModal={p.setIdAnimeModal}
          />
        })
      }
    </ul>
  )
}

interface ITabMusiman {
  animeMusiman: Array<IDataAnime>;
  animeTerakhirLoad?: number;
  refAnimeTerakhir?: RefObject<HTMLLIElement>;
  cacheLaman?: () => void;
  bukaDgnModal?: boolean;
  setIdAnimeModal?: Dispatch<string | null>;
}