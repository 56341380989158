export default function DapatkanMusim(tanggal: Date): string{
  const bulan = tanggal.getMonth()
  var tahun = tanggal.getFullYear()

  const musimDinginDesember = bulan === 11
  const musimDingin = bulan === 0 || bulan === 1
  const musimSemi = bulan >= 2 && bulan < 5
  const musimPanas = bulan >= 5 && bulan < 8
  const musimGugur = bulan >= 8 && bulan < 11

  var musimString = "DINGIN"

  switch(true){
    case musimDinginDesember:
      musimString = "DINGIN";
      tahun = tahun + 1;
      break;
    case musimDingin:
      musimString = "DINGIN"
      break;
    case musimSemi:
      musimString = "SEMI"
      break;
    case musimPanas:
      musimString = "PANAS"
      break;
    case musimGugur:
      musimString = "GUGUR"
      break;
  }

  const tahunMusim = tahun + musimString
  return tahunMusim 
}