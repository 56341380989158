import { TypeAksi } from "store/reducers"
import { IDataCache } from "store/reducers/cacheLaman"
import { storePengguna, storePemberitahuan } from "./pengguna"

const cacheLaman = (laman: string, payload: IDataCache, 
  scroll: number, 
  ) =>{
  const tipe: TypeAksi = 'CACHE_LAMAN'
  return {
    type: tipe,
    payload: {
      [laman]: {
      jenisLaman: laman,
      data: payload, 
      scroll: scroll,
      takValid: false
    }},
  }
}

const cacheData = (namaData: string, payload: any) => {
  const tipe: TypeAksi = 'CACHE_DATA'
  return {
    type: tipe,
    payload: {
      [namaData]: payload
    }
  }
}

const hapusForum = (laman: string) => {
  const tipe: TypeAksi = 'FORUM_HAPUS'
  return{
    type: tipe,
    payload: {
      jenisLaman: laman
    }
  }
}

export {
  hapusForum,
  cacheLaman, 
  cacheData,
  storePengguna,
  storePemberitahuan
}