import { FC } from "react"
import "./AktivitasPengguna.scss"
import useFetchFirestore from "fungsi/useFetchFirestore"
import firebase from "firebase"
import { DataAktivitasPengguna, IPengguna } from "Data/Pengguna"
import { RouteComponentProps } from "react-router-dom"
import { ItemIsiAktivitas } from "laman/profilPengguna/komponenProfil/isiAktivitas/ItemIsiAktivitas"
import { useDocumentDataOnce } from "react-firebase-hooks/firestore"
import LoadingLaman from "laman/loadingLaman/LoadingLaman"
import { terjemahkanWaktu, terjemahkanAktivitas } from "laman/profilPengguna/komponenProfil/isiAktivitas/FungsiIsiAktivitas"

const AktivitasPengguna: FC<IAktivitasPengguna> = (p) => {
  const [aktivitasPengguna, refAktivitasTerakhir, indeksAktivitasTerakhir, loadingAkt, dataTerakhirDalamLayar]
  = useFetchFirestore<DataAktivitasPengguna>(
    10, 
    (firebase.firestore().collection('aktivitasPengguna')
      .where('idPengguna', "==", p.match.params.idPengguna)
      .orderBy('waktuAktivitas', 'desc')
      .limit(6)),
    'aktivitasPengguna' + p.match.params.idPengguna,
    undefined,
    15,
    50,
  );
  const [dataPengguna] = useDocumentDataOnce<IPengguna>(
    firebase.firestore().doc(`pengguna/${p.match.params.idPengguna}`)
  )
  return(
    <div className="aktivitas-pengguna-apl-animemo">
      <h6 className="judul-aktivitas">
        Aktivitas {dataPengguna?.namaPengguna}
      </h6>
      {aktivitasPengguna.length > 0 ? 
        <ul className="kontainer-item-aktivitas">
          {aktivitasPengguna.map((nilai, indeks) => 
            <ItemIsiAktivitas 
              indeks={indeks} 
              indeksTerakhir={indeksAktivitasTerakhir}
              aktivitas={nilai}
              teksAktivitas={terjemahkanAktivitas(nilai)}
              teksWaktuAktivitas={terjemahkanWaktu(nilai)}
              refAktivitasTerakhir={refAktivitasTerakhir}
              />) 
          }
        </ul>
      :
      !loadingAkt ?
        <div>
          Aktivitas kosong
        </div>
      :
          <></>
      }
      {loadingAkt && <LoadingLaman />}
    </div>
  )
}

interface IAktivitasPengguna extends RouteComponentProps<{idPengguna: string}>{}

export default AktivitasPengguna