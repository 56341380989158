export const dataKataKotor = ["jancok"
  ,"jiancok"
  ,"juancok"
  ,"hancok"
  ," cok "
  ,"bangsat"
  ,"fuck"
  ,"shit"
  ," cuk "
  ,"jancuk"
  ,"bullshit"
  ,"dildo"
  ,"kontol"
  ,"memek"
  ,"brengsek"
  ,"toket"
  ,"bajingan"
  ,"vibrator"
  ,"jembut"
  ,"lonte"
  ,"ngentod"
  ,"ngentot"
  ,"bgst"
]