import { useEffect, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import firebase from "firebase"
import { DataAktivitasPengguna } from "Data/Pengguna"

const useFetchAktivitas: () => [DataAktivitasPengguna[], boolean] = () => {
  const [auth] = useAuthState(firebase.auth())
  const [aktivitas, setAktivitas] = useState<DataAktivitasPengguna[]>([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const fetch = async () => {
      if(auth){
        try{
          setLoading(true)
          const aktivitasDiikuti = (await firebase.firestore()
          .collection('aktivitasPenggunaDiikuti')
          .where('listPenggunaMengikuti', 'array-contains', auth?.uid || "")
          .orderBy('waktuAktivitas', 'desc')
          .limit(10)
          .get())
      
          const dataAktivitas: DataAktivitasPengguna[] = []
          const promiseAkt: Promise<DataAktivitasPengguna>[] = []
          
          aktivitasDiikuti.forEach((doc) => {
            promiseAkt.push(new Promise((res, rej) => {
              const data: DataAktivitasPengguna = doc.data() as any
              dataAktivitas.push(data)
              res(data)
            }))
          })
      
          await Promise.all(promiseAkt)
          setAktivitas(dataAktivitas)
          setLoading(false)
        }catch(e){
          const er = e as Error 
          console.log(er.message)
        }
      }
    }
    fetch()
  }, [auth])
  return [aktivitas, loading]
}

export default useFetchAktivitas