import { DataForum } from "Data/DataForum";
import { FC } from "react";
import { Link } from "react-router-dom";
import './IsiForum.scss'

const IsiForum: FC<IIsiForum> = (p) => {
  return(
    <li >
      <Link style={{textDecoration:"none"}} to={`/h/forum-balasan/${p.isi.idForum}/terlama`}>
        <div className='isi-forum-apl-animemo'>
          {p.isi.gambarKecilForum && <img className='gbr-forum' src={p.isi.gambarKecilForum}/>}
          <div className='isi-forum'>
            <div className='teks-forum'>
              <h4 className='judul-forum'>
                {p.isi.judulForum}
              </h4>
              <h6 className='isi-forum'>
                {p.isi.isiForum}
              </h6>
            </div>
            <h6 className='tgl-forum'>
              {p.isi.tanggalBuatForum.toDate().toString}
            </h6>
          </div>
        </div>
      </Link>
    </li>
  )
}


export default IsiForum

interface IIsiForum{
  indeks: number
  isi: DataForum
}