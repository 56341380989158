import {FC, useEffect, useState} from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import firebase from "firebase";
import {IDataAnime} from '../../Data/Anime';
import Chip from '@material-ui/core/Chip';
import SkorAnime from './komponenAnime/skorAnime/SkorAnime'
import './Anime.scss';
import ShowMoreText from "react-show-more-text";
import IkonTambah from '@material-ui/icons/Add'
import { Fab } from '@material-ui/core';
import ReviewAnime from './komponenAnime/ReviewAnime';
import {scrollIntoViewOffset} from "fungsi/scrollIntoViewOffset"
import LoadingLaman from 'laman/loadingLaman/LoadingLaman';

export const Anime: FC<ILamanAnime> = (prop) =>  {

  useEffect(() => {
    fetchAnime();
    window.scrollTo({top: 0});
  }, [prop.match.params.idAnime]);

  const[anime, setAnime] = useState<IDataAnime | null>(null);
  const [loadingAnime, setLoadingAnime] = useState(false)
  const fetchAnime = async () => {
    setLoadingAnime(true)
    const animeDapat = await dapatkanAnime(
      (decodeURIComponent(prop.match.params.idAnime.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')))
    )
    setLoadingAnime(false)
    setAnime(animeDapat)
  };

  const [tanggalTayangString, setTanggalTayangString] = useState('--/--/----')

  useEffect(() => {
    if(anime != null){
      if(anime.tanggalMulaiTayang != null){
        const t = new Date(anime.tanggalMulaiTayang.seconds * 1000)
        setTanggalTayangString(t.getDate() + '/' + (t.getMonth() + 1) + '/' + t.getFullYear())
      }else{
        setTanggalTayangString('Belum tayang')
      }
    }
  }, [anime])
  

  return (
    <section className="konten-anime-utama-apl-animemo">
      {
        loadingAnime ? 
          <LoadingLaman />
        :
          <InfoAnime anime={anime} tanggalTayangString={tanggalTayangString}/>
      }
    </section>
  );
}

const InfoAnime: FC<IInfoAnime> = (p) => {

  const jumlahEpisode = (p.anime?.episodeAnime != null && p.anime.episodeAnime >= 0) ? `${p.anime.episodeAnime}` : "?"
  return(
    <>
      <h2>
        {p.anime?.judulAnime || '-'}
      </h2>
      <img src={p.anime?.urlFotoAnime || undefined} className="gambar-anime" alt="Foto Anime" />
      <div className='kontainer-seluruh-skor'>
        <SkorAnime teks='Skor AniMemo' skorAnime={p.anime?.skor}/>
        <SkorAnime teks='Skor MAL' skorAnime={p.anime?.skorMal}/>
      </div>
      <ul className='genre-anime'>
        {p.anime?.genre?.map((nilai, kunci) => {
          return <Chip key={kunci} style={{marginLeft: 2, marginRight: 2, marginBottom: 5}} label={nilai}/>
        })}
      </ul>
      <div className='teks-header-kecil'>
        {(p.anime?.jenisAcara ? p.anime.jenisAcara : '?' ) + ', ' +
        jumlahEpisode + ' episode' + ', '
      }
      </div>
      <div className='kontainer-teks-sinopsis'>
        <ShowMoreText
          lines={2}
          more="Lebih banyak"
          less="Lebih sedikit"
          expanded={false}
        >
          {p.anime?.sinopsis}
        </ShowMoreText>
      </div>
      <div className='teks-header-kecil'>
        info lebih
      </div>
      <table className='isi-tabel-anime'>
        <TabelAnime
        judul='Judul alternatif'
        dataTabel={'-' + p.anime?.judulAlternatif?.join('\n-')}
        />
        <TabelAnime
        judul='Judul jepang'
        dataTabel={p.anime?.judulJepangAnime}
        />
        <TabelAnime
        judul='AniMemo'
        dataTabel={`${p.anime?.jumlahReview}`} 
        />
        <TabelAnime
        judul='Member MAL'
        dataTabel={`${p.anime?.memberMal}`}
        />
        <TabelAnime
        judul='Tanggal mulai tayang'
        dataTabel={p.tanggalTayangString}
        />
        {//TODO selesai tayang
        }
        <TabelAnime
        judul='Produser'
        dataTabel={'-' + p.anime?.produser?.join('\n-')}
        />
        <TabelAnime
        judul='Lisensor'
        dataTabel={'-' + p.anime?.lisensor?.join('\n-')}
        />
        <TabelAnime
        judul='Studio'
        dataTabel={'-' + p.anime?.studio?.join('\n-')}
        />
        <TabelAnime
        judul='Sumber cerita'
        dataTabel={p.anime?.sumberCerita}
        />
        <TabelAnime
        judul='Rating Anime'
        dataTabel={p.anime?.ratingAnime + ' | ' + p.anime?.alasanRating  }
        />
        <TabelAnime
        judul='Musik opening'
        dataTabel={'-' + p.anime?.musikOpening?.join('\n\n-')}
        />
        <TabelAnime
        judul='Musik ending'
        dataTabel={'-' + p.anime?.musikEnding?.join('\n\n-')}
        />
      </table>
      
      <br/>
      <br/>
      <ReviewAnime anime={p.anime || undefined} />
      <div className='fab-tambah-anime'>
        <Link to={'/h/tambahkan-anime/' + encodeURIComponent(p.anime?.idAnime || "") }>
          <Fab color='primary' >
            <IkonTambah />
          </Fab>
        </Link>
      </div>
    </>
  )
}

interface IInfoAnime {
  anime: IDataAnime | null
  tanggalTayangString: string
}

const TabelAnime: FC<ITabelAnime> = (p) => {
  return(
    <tr>
      <td className='bagian-kiri-tabel-anime'>{p.judul}</td>
      <td className='bagian-kanan-tabel-anime'>{p.dataTabel}</td>
    </tr>
  )
}

async function dapatkanAnime(idAnime?: string){
  const db = firebase.firestore()
  const kolRefAnime = db.collection('databaseAnime')
  const dokAnime: any = (await kolRefAnime.doc(idAnime || '').get()).data()
  const anime: IDataAnime = dokAnime
  return anime
}


interface ILamanAnime extends RouteComponentProps<{idAnime: string}>{
}

interface ITabelAnime {
  judul?: string,
  dataTabel?: string | null,
}