import "./DaftarAnimePengguna.scss"
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { FC, useEffect, useRef, useState } from "react";
import useDimensiLayar from "fungsi/UseDimensiLayar";
import { Box, Typography } from "@material-ui/core";
import LoadingLaman from "laman/loadingLaman/LoadingLaman";
import TabAnimePengguna from "../Komponen/TabAnimePengguna";
import { useTheme } from '@material-ui/core/styles';
import { IKoleksiPengguna } from "Data/KoleksiPengguna";
import firebase from "firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { RouteComponentProps, useHistory } from "react-router";
import useFetchFirestore from "fungsi/useFetchFirestore";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import {IPengguna} from 'Data/Pengguna'

export const indeksStatusNonton = {
  NONTON: 0,
  SELESAI: 1,
  RENCANA: 2,
  TAK_JADI:3,
}

export type TSlugNonton =  'nonton' | 'selesai' | 'rencana' | 'tak-jadi';


const DaftarAnimePengguna: FC<IDaftarAnimePengguna> = (p) => {

  const [auth, _loadingAuth, _erorAuth] = useAuthState(firebase.auth())
  console.log(p.match.params.idPengguna)
  const idDapat = p.match.params.idPengguna ? p.match.params.idPengguna : auth?.uid

  const tema = useTheme();
  const sejarah = useHistory();

  const [lokasiTab, setLokasiTab] = useState(indeksStatusNonton.NONTON)
  const handelBerubah = (_event: React.ChangeEvent<{}>, newValue: number) => {
    setLokasiTab(newValue);
    let urlDaftar: TSlugNonton = 'nonton'
    switch(newValue){
      case indeksStatusNonton.NONTON:
        urlDaftar = 'nonton';
        break;
      case indeksStatusNonton.SELESAI:
        urlDaftar = 'selesai';
        break;
      case indeksStatusNonton.RENCANA:
        urlDaftar = 'rencana';
        break;
      case indeksStatusNonton.TAK_JADI:
        urlDaftar = 'tak-jadi';
        break;
      default:
        urlDaftar = 'nonton'
    }
    if(p.match.params.idPengguna){
      sejarah.push('/h/daftar/' + urlDaftar + '/' + p.match.params.idPengguna)
    }else{
      sejarah.push('/h/daftar/' + urlDaftar)
    }
  };


  const [arNonton, refNontonTerakhir, indeksNontonTerakhir, loadingNonton, dokNontonTerakhirDlmLyr, cacheNonton] =
  useFetchFirestore<IKoleksiPengguna>(
    10, 
    (firebase.firestore().collection("koleksiAnimekuPengguna").where("idPengguna", "==", idDapat || "")
      .where("statusNonton", "==", indeksStatusNonton.NONTON)
      .orderBy("skorPengguna", "desc")), 
    'daftarNonton' + idDapat,
    lokasiTab,
    undefined,
    500,
    indeksStatusNonton.NONTON,
    [idDapat]
    )

  const [arSelesai, refSelesaiTerakhir, indeksSelesaiTerakhir, loadingSelesai, dokSelesaiTerakhirDlmLyr, cacheSelesai] =
  useFetchFirestore<IKoleksiPengguna>(
    10, 
    (firebase.firestore().collection("koleksiAnimekuPengguna").where("idPengguna", "==", idDapat || "")
      .where("statusNonton", "==", indeksStatusNonton.SELESAI)
      .orderBy("skorPengguna", "desc")), 
    'daftarSelesai' + idDapat,
    lokasiTab,
    undefined,
    500,
    indeksStatusNonton.SELESAI,
    [idDapat]
    )
  
  const [arRencana, refRencanaTerakhir, indeksRencanaTerakhir, loadingRencana, dokRencanaTerakhirDlmLyr, cacheRencana] =
  useFetchFirestore<IKoleksiPengguna>(
    10, 
    (firebase.firestore().collection("koleksiAnimekuPengguna").where("idPengguna", "==", idDapat || "")
      .where("statusNonton", "==", indeksStatusNonton.RENCANA)
      .orderBy("skorPengguna", "desc")), 
    'daftarRencana' + idDapat,
    lokasiTab,
    undefined,
    500,
    indeksStatusNonton.RENCANA,
    [idDapat]
    )
  
  const [arTakJadi, refTakJadiTerakhir, indeksTakJadiTerakhir, loadingTakJadi, dokTakJadiTerakhirDlmLyr, cacheTakJadi] =
  useFetchFirestore<IKoleksiPengguna>(
    10, 
    (firebase.firestore().collection("koleksiAnimekuPengguna").where("idPengguna", "==", idDapat || "")
      .where("statusNonton", "==", indeksStatusNonton.TAK_JADI)
      .orderBy("skorPengguna", "desc")), 
    'daftarTakJadi' + idDapat,
    lokasiTab,
    undefined,
    500,
    indeksStatusNonton.TAK_JADI,
    [idDapat]
    )
  

  const {lebarLayar, tinggiLayar} = useDimensiLayar()

  useEffect(() => {
    switch(p.match.params.idDaftar){
      case 'nonton':
        setLokasiTab(indeksStatusNonton.NONTON);
        break;
      case 'rencana':
        setLokasiTab(indeksStatusNonton.RENCANA);
        break;
      case 'selesai':
        setLokasiTab(indeksStatusNonton.SELESAI);
        break;
      case 'tak-jadi':
        setLokasiTab(indeksStatusNonton.TAK_JADI);
        break;
      default:
        setLokasiTab(indeksStatusNonton.NONTON)
    }
  }, [p.match.params.idDaftar])

  const [pengguna] = useDocumentDataOnce<IPengguna>(firebase.firestore().doc(`pengguna/${p.match.params.idPengguna}`))
  const jmlNonton = (((pengguna?.jumlahNontonMenit || -1) >= 0) ? ((pengguna?.jumlahNontonMenit || 0)/(60*24)) : null)
  return(
    <section className='konten-daftar-pengguna'>
      {!!p.match.params.idPengguna &&
      <h6>
        Koleksi Anime {pengguna?.namaPengguna} yang menonton anime selama {jmlNonton ? jmlNonton?.toFixed() : 0} hari
      </h6>}
      <AppBar position="static" color="default">
        <Tabs
          value={lokasiTab}
          onChange={handelBerubah}
          indicatorColor="primary"
          style={{backgroundColor: 'var(--bg)'}}
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label="Nonton" 
            textColor='var(--warna-teks)' 
            style={{fontSize: lebarLayar > 300 ? "14px" : "10px", 
              backgroundColor: "var(--bg)"}}/>
          <Tab label="Selesai" style={{fontSize: lebarLayar > 300 ? "14px" : "10px", backgroundColor: "var(--bg)"}} />
          <Tab label="Rencana" style={{fontSize: lebarLayar > 300 ? "14px" : "10px", backgroundColor: "var(--bg)"}}/>
          <Tab label="Tak Jadi" style={{fontSize: lebarLayar > 300 ? "14px" : "10px", backgroundColor: "var(--bg)"}}/>
        </Tabs>
      </AppBar>
      <TabPanel value={lokasiTab} index={0} dir={tema.direction} >
        <div className='tab-tab-daftar'>
          { (idDapat !== null && idDapat !== undefined) ? 
          <>
            <TabAnimePengguna 
              cacheAnime={cacheNonton}
              animePengguna={arNonton} 
              animeTerakhirLoad={indeksNontonTerakhir}
              refAnimeTerakhir={refNontonTerakhir}
            />
            <div className="loading">
              {loadingNonton && <LoadingLaman />}
            </div>
          </> :
          auth ?
            <h6>
              id pengguna tidak ditemukan
            </h6>
          :
            <h6>
              Anda perlu login untuk menggunakan fitur ini
            </h6>
          }
        </div>
      </TabPanel>
      <TabPanel value={lokasiTab} index={indeksStatusNonton.SELESAI} dir={tema.direction}>
        <div className='tab-tab-daftar'>
          { (idDapat !== null && idDapat !== undefined) ? 
              <>
                <TabAnimePengguna 
                  cacheAnime={cacheSelesai}
                  animePengguna={arSelesai}
                  animeTerakhirLoad={indeksSelesaiTerakhir}
                  refAnimeTerakhir={refSelesaiTerakhir}
                />
                <div className="loading">
                  {loadingSelesai && <LoadingLaman />}
                </div>
              </> 
            :
            auth ?
              <h6>
                id pengguna tidak ditemukan
              </h6>
            :
              <h6>
                Anda perlu login untuk menggunakan fitur ini
              </h6>
            
          }
        </div>
      </TabPanel>
      <TabPanel value={lokasiTab} index={indeksStatusNonton.RENCANA} dir={tema.direction}>
        <div className='tab-tab-daftar'>
          { (idDapat !== null && idDapat !== undefined) ? 
              <>  
                <TabAnimePengguna 
                  cacheAnime={cacheRencana}
                  animePengguna={arRencana}
                  animeTerakhirLoad={indeksRencanaTerakhir}
                  refAnimeTerakhir={refRencanaTerakhir}
                />
                <div className="loading">
                  {loadingRencana && <LoadingLaman />}
                </div>
              </>
            :
            auth ?
              <h6>
                id pengguna tidak ditemukan
              </h6>
            :
              <h6>
                Anda perlu login untuk menggunakan fitur ini
              </h6>
          }
        </div>
      </TabPanel>
      <TabPanel value={lokasiTab} index={indeksStatusNonton.TAK_JADI} dir={tema.direction}>
        <div className='tab-tab-daftar'>
          {(idDapat !== null && idDapat !== undefined) ? 
            <> 
              <TabAnimePengguna 
                cacheAnime={cacheTakJadi}
                animePengguna={arTakJadi}
                animeTerakhirLoad={indeksTakJadiTerakhir}
                refAnimeTerakhir={refTakJadiTerakhir}
              />
              <div className="loading">
                {loadingTakJadi && <LoadingLaman />}
              </div>
            </>
          :
          auth ?
            <h6>
              id pengguna tidak ditemukan
            </h6>
          :
            <h6>
              Anda perlu login untuk menggunakan fitur ini
            </h6>
          }
        </div>
      </TabPanel>  
    </section>
  )
}

const TabPanel: FC<ITabPanel> = (p) => {
  const { children, value, index } = p;
  const { lebarLayar, tinggiLayar } = useDimensiLayar()
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
    >
      {value === index && (
        <Box p={3} style={lebarLayar < 600 ? {padding: 0, paddingTop: "5px"} : {padding: "20px"}}>
          <Typography className="tab-panel">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default DaftarAnimePengguna

interface IDaftarAnimePengguna extends RouteComponentProps<{
  idDaftar: TSlugNonton, 
  idPengguna: string | undefined}>{

}

interface ITabPanel {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}