import './ItemStatCepat.scss'
import {FC} from 'react';

const StatCepat: FC<IStatCepat> = (prop) =>  {
  return(
    <div className="item-stat-cepat">
      <div className="angka-stat-cepat">{(prop.angka != null) ? prop.angka.toFixed(prop.angkaBelakangKoma || 0) : '?'}</div>
      <div className="keterangan-stat-cepat">{prop.keterangan}</div>
    </div>
  )
}

interface IStatCepat{
  angka?: number | null,
  angkaBelakangKoma?: number | null,
  keterangan?: string,
}

export default StatCepat