import { FC} from 'react';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core';
import './InputUmum.scss';

const InputUmum: FC<IInputNama> = (prop) => {
  return(
    <div className='input-umum-login'>
      {prop.gambar != null &&
        <div className='gambar-input-umum-login'>
          <prop.gambar/>
        </div>
      }
      <div className='isian-input-umum-login'
        style={(prop.gambar === null || prop.gambar === undefined) ? {marginLeft: "10px"} : {}}>
        {prop.elemenInput}
      </div>
      {((prop.apakahEror || false) && ((prop.tampilkanEror !== null && prop.tampilkanEror !== undefined)
      ? prop.tampilkanEror : true)) &&
        <div className='eror-input-umum-login'>
          {prop.teksEror || ''}
        </div>
      }
    </div>
  )
}

export default InputUmum

interface IInputNama {
  gambar?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  elemenInput: JSX.Element; 
  apakahEror?: boolean;
  teksEror?: string;
  tampilkanEror?: boolean;
}