import { MutableRefObject, useEffect } from "react";

//saat klik diluar ref, maka akan menginvokasi fungsi
// ref didapatkan dari const ref = useRef(null)

export default function useKlikLuar(ref: MutableRefObject<any>, fungsiModal: () => void) {
  useEffect(() => {
    function handelKlikDiLuar(ev: MouseEvent) {

      if (ref && ref.current && !ref.current.contains(ev.target)) {
        console.log("coba")
        fungsiModal()
      }
    }
    document.addEventListener("mousedown", handelKlikDiLuar);
    console.log()
    return () => { document.removeEventListener("mousedown", handelKlikDiLuar) }
  }, [ref])
}
