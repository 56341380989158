import firebase from 'firebase';
import { useState, useEffect, RefObject } from 'react';
import { IDataAnime } from "Data/Anime";

const mode = {
  SKOR: 0, 
  TERPOPULER: 1,
  TAHUNRILIS: 2,
  GENRE: 3,
  JENISACARA: 4,
}

const urutBerdasarOpsi = {
  TERPOPULER: 0,
  SKOR: 1,
}

function dapatkanAnimeMusiman(
  tahunMusim: string,
  animePerLaman: number,
  animeMusiman: IDataAnime[],
  setAnimeMusiman: (animeMusiman: IDataAnime[]) => void,
  setLogikaDummy: (logika: boolean) => void,
  logikaDummy: boolean,
  setAnimeTerakhirLoad: (terakhir: number) => void,
  setSsAnimeTerakhir: (anime: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) => void,
  setAkhirData: (akhirData: boolean) => void,
  akhirData: boolean,
  loading: boolean,
  setLoading: (loading: boolean) => void,
  mulaiDariDokumen?: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | null,
){
  const db = firebase.firestore()
  if(!akhirData){
    setLoading(true)
    if(mulaiDariDokumen === null || mulaiDariDokumen === undefined){
      db.collection("databaseAnime").where("musimAnime", "==", tahunMusim)
      .orderBy("jumlahReview", "desc")
      .orderBy("memberMal", "desc")
      .limit(animePerLaman)
      .get()
      .then((ss) => {
        if(ss.empty){
          setAkhirData(true)
        }else{
          const terakhirTerlihat = ss.docs[ss.docs.length - 1]
          ss.forEach((doc) => {
            const ssAnime: any = doc.data()
            const anime: IDataAnime = ssAnime
            animeMusiman.push(anime)
            setAnimeMusiman(animeMusiman)
          })
          setAnimeTerakhirLoad(animeMusiman.length - 1)
          setSsAnimeTerakhir(terakhirTerlihat)
        }
      }).catch((e) => {
        setLogikaDummy(!logikaDummy)
      }).finally(() => {
        setLogikaDummy(!logikaDummy)
        setLoading(false)
      })
    }else{
      db.collection("databaseAnime").where("musimAnime", "==", tahunMusim)
      .orderBy("jumlahReview", "desc")
      .orderBy("memberMal", "desc")
      .startAfter(mulaiDariDokumen)
      .limit(animePerLaman)
      .get()
      .then((ss) => {
        if(ss.empty){
          setAkhirData(true)
        }else{
          const terakhirTerlihat = ss.docs[ss.docs.length - 1]
          ss.forEach((doc) => {
            const ssAnime: any = doc.data()
            const anime: IDataAnime = ssAnime
            animeMusiman.push(anime)
            setAnimeMusiman(animeMusiman)
          })
          setAnimeTerakhirLoad(animeMusiman.length - 1)
          if(setSsAnimeTerakhir){setSsAnimeTerakhir(terakhirTerlihat)}
        }
      }).catch((e) => {
        console.log(e)
        setLogikaDummy(!logikaDummy)
      }).finally(() => {
        setLoading(false)
        setLogikaDummy(!logikaDummy)
      })
    }
  }
}

function dapatkanAnimeTop(modeTerpilih: number | null
  , genreTerpilih: string | null
  , urutBerdasar: number | null
  , musim: string | null
  , tahun: number | null
  , jenis: string | null
  , animePerLaman: number
  , setAnimeTerakhirLoad: (terakhir: number) => void
  , animeDidapat: IDataAnime[]
  , setAnimeDidapat: (anime: IDataAnime[]) => void
  , setAkhirData: (akhir: boolean) => void
  , akhirData: boolean
  , setLoading: (loading: boolean) => void
  , setMulaiDariDokumen: (anime: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | null) => void
  , mulaiDariDokumen?: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | null
  , dataMaks?: number 
  ){
  const db = firebase.firestore();
  setLoading(true)
  //TODO: saat ganti mode, akhir data harus dihapus
  const kurangDariDataMaks = (dataMaks !== undefined && animeDidapat.length < dataMaks) || dataMaks === undefined
  if(!akhirData && kurangDariDataMaks){
    const dapatkanData = (ss: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>, nambah? : boolean) => {
      if(ss.empty || animeDidapat.length > 600){
        setAkhirData(true)
      }else{
        const animeArKos: Array<IDataAnime> = []
        if(nambah){
          animeDidapat.forEach((dapat) => {animeArKos.push(dapat); console.log("cuy")})
        }
        const terakhirTerlihat = ss.docs[ss.docs.length - 1]
        ss.forEach((doc) => {
          const ssAnime: any = doc.data()
          const anime: IDataAnime = ssAnime
          animeArKos.push(anime)
        })
        setAnimeDidapat(animeArKos)
        setAnimeTerakhirLoad(animeArKos.length - 1)
        setMulaiDariDokumen && setMulaiDariDokumen(terakhirTerlihat)
      }
    }
    console.log("cuyyy")
    console.log(mulaiDariDokumen)
    console.log(mulaiDariDokumen === undefined || mulaiDariDokumen === null)
    if(mulaiDariDokumen === undefined || mulaiDariDokumen === null){
      setAnimeDidapat([])
      if(modeTerpilih === mode.SKOR || modeTerpilih === mode.TERPOPULER){
        db.collection("databaseAnime")
        .orderBy(modeTerpilih === mode.SKOR ? "skor" : "jumlahReview", "desc")
        .orderBy(modeTerpilih === mode.SKOR ? 'skorMal' : 'memberMal', 'desc')
        .limit(animePerLaman)
        .get()
        .then((ss) => {dapatkanData(ss)})
      }else if(mode.TAHUNRILIS === modeTerpilih || mode.JENISACARA === modeTerpilih){
        const tahunMusim = mode.TAHUNRILIS === modeTerpilih;
        db.collection("databaseAnime").where(tahunMusim ? 'musimAnime' : 'jenisAcara', '=='
        , tahunMusim ? ((tahun ?? '') + (musim ?? '').toUpperCase()) : jenis ?? '' )
        .orderBy('jumlahReview', 'desc')
        .orderBy('memberMal', 'desc')
        .limit(animePerLaman)
        .get()
        .then((ss) => {console.log('coba', ss.docs); dapatkanData(ss)})
        .catch((er) => {console.log(er)})
        .finally(() => {setLoading(false)})
      }else{
        db.collection('databaseAnime').where('genre', 'array-contains', genreTerpilih)
        .orderBy(urutBerdasar === urutBerdasarOpsi.SKOR ? 'skor' : 'jumlahReview', 'desc')
        .orderBy(urutBerdasar === urutBerdasarOpsi.SKOR ? 'skorMal' : 'memberMal', 'desc')
        .limit(animePerLaman)
        .get()
        .then((ss) => {dapatkanData(ss)})
        .catch((er) => {console.log(er)})
        .finally(() => {setLoading(false)})
      }
    }else{
      if(modeTerpilih === mode.SKOR || modeTerpilih === mode.TERPOPULER){
        db.collection("databaseAnime")
        .orderBy(modeTerpilih === mode.SKOR ? "skor" : "jumlahReview", "desc")
        .orderBy(modeTerpilih === mode.SKOR ? 'skorMal' : 'memberMal', 'desc')
        .startAfter(mulaiDariDokumen)
        .limit(animePerLaman)
        .get()
        .then((ss) => {
          dapatkanData(ss, true)
        })
        .finally(() => {
          setLoading(false)
        })
      }else if(modeTerpilih === mode.TAHUNRILIS || modeTerpilih === mode.JENISACARA){
        const tahunMusim = mode.TAHUNRILIS === modeTerpilih;
        db.collection("databaseAnime").where(tahunMusim ? 'musimAnime' : 'jenisAcara', '=='
        , tahunMusim ? ((tahun ?? '') + (musim ?? '').toUpperCase()) : jenis ?? '' )
        .orderBy('jumlahReview', 'desc')
        .orderBy('memberMal', 'desc')
        .startAfter(mulaiDariDokumen)
        .limit(animePerLaman)
        .get()
        .then((ss) => {
          dapatkanData(ss, true)
        })
        .finally(() => {
          setLoading(false)
        })
      }else{
        db.collection('databaseAnime').where('genre', 'array-contains', genreTerpilih)
        .orderBy(urutBerdasar === urutBerdasarOpsi.SKOR ? 'skor' : 'jumlahReview', 'desc')
        .orderBy(urutBerdasar === urutBerdasarOpsi.SKOR ? 'skorMal' : 'memberMal', 'desc')
        .startAfter(mulaiDariDokumen)
        .limit(animePerLaman)
        .get()
        .then((ss) => {
          dapatkanData(ss, true)
        })
        .finally(() => {
          setLoading(false)
        })
      }
    }
  }
}

function useDalamLayarTab(ref: RefObject<HTMLElement>, tabBenar: boolean) {

  const [adaInterseksi, setAdaInterrseksi] = useState(false)

  const observer = new IntersectionObserver(
    ([entry]) => setAdaInterrseksi(entry.isIntersecting)
  )

  useEffect(() => {
    if(ref.current !== null){
      observer.observe(ref.current)
      return () => { observer.disconnect() }
    }
  }, [ref.current, tabBenar])

  return adaInterseksi
}

export {
  dapatkanAnimeMusiman,
  useDalamLayarTab,
  dapatkanAnimeTop as dapatkanAnime
}

export default {
  dapatkanAnimeMusiman,
  useDalamLayarTab,
  dapatkanAnime: dapatkanAnimeTop
}