import { useEffect, useState } from "react"
import { DataForum } from 'Data/DataForum';
import firebase from 'firebase'

const useFetchForum: () => [DataForum[], boolean] = () => {
  const [forum, setForum] = useState<DataForum[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetch = async () => {
      try{
        const forum = (await firebase.firestore()
        .collection('forumPengguna')
        .orderBy('terakhirDibalas', 'desc')
        .limit(5)
        .get())

        const dataForumDapat: DataForum[] = []
        const promiseFor: Promise<DataForum>[] = []

        forum.forEach((doc) => {
          promiseFor.push(new Promise((res, rej) => {
            const data: DataForum = doc.data() as any
            dataForumDapat.push(data)
            res(data)
          }))
        })

        await Promise.all(promiseFor)
        setForum(dataForumDapat)
      }catch(e){
        const er = e as Error
        console.log(er.message)
      }
    }
    fetch()
  }, [])

  return [forum, loading]
}

export default useFetchForum