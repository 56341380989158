import { IAksi } from ".";
import firebase from "firebase";


export interface ICacheLaman{
  data: IDataCache,
  jenisLaman: string,
  scroll: number,
  refDataTerakhir: HTMLLIElement | null,
  perluRefresh: boolean
}

export interface IDataCache{
  dokumenDapat: any[],
  ssDokumenTerakhir: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | null,
  akhirDokumen: boolean,
  indeksDataTerakhir: number,
  perluRefresh: boolean,
}

export function cacheLamanReducer(state: any = null, action: IAksi){
  switch(action.type){
    case 'CACHE_LAMAN':
      return {
        ...state,
        ...action.payload
      }
    case 'FORUM_HAPUS':
      if(state){
        const forumBaru = state[action.payload.jenisLaman]
        if(forumBaru && forumBaru.data){
          forumBaru.data.perluRefresh = true
          return {
            ...state,
            [action.payload.jenisLaman]: forumBaru
          }
        }else{
          return state
        }
      }else{
        return state
      }
    default: // need this for default case
      return state 
  }
}