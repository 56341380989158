import { Carousel } from 'react-responsive-carousel';
import logo from '../../logo/logoAniMemo.svg';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import './Beranda.scss';
import { ChangeEvent, useEffect, useState } from 'react';
import firebase from 'firebase';
import {Berita} from 'Data/Berita'
import {  IDataAnime } from 'Data/Anime';
import DapatkanMusim from 'fungsi/DapatkanMusim';
import { Link, useHistory } from 'react-router-dom';
import { DataForum, IFeeds } from 'Data/DataForum';
import IsiForum from './Komponen/IsiForum';
import IsiAktivitas, { ItemIsiAktivitas } from 'laman/profilPengguna/komponenProfil/isiAktivitas/ItemIsiAktivitas';
import useFetchAktivitas from './fungsi/useFetchAktivitas';
import LoadingLaman from 'laman/loadingLaman/LoadingLaman';
import useFetchForum from './fungsi/useFetchForum';
import { useAuthState } from 'react-firebase-hooks/auth';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { storeModeGelap } from 'store/actions/ui';
import { IRootRedux } from 'store/reducers';
import { useCacheScroll } from 'fungsi/fungsiDOM';
import FeedsCard from 'laman/ForumOrtu/Komponen/Feeds/FeedsCard';


export function Beranda(){
  const modeGelap = useSelector((state: IRootRedux) => state?.ui?.modeGelap || 0)
  const [dataBerita, setDataBerita] = useState<Berita[]>([])
  const [dataAnime, setDataAnime] = useState<IDataAnime[]>([])
  const [dataFeeds, setDataFeeds] = useState<IFeeds[]>([])

  const [aktivitas, loadingAkt] = useFetchAktivitas()
  const [forum, loadingForum] = useFetchForum()

  const [auth] = useAuthState(firebase.auth())

  useEffect(() => {
    const fetchFirebase = async () => {
      try{
        const beritaFirestore = (await firebase.firestore()
        .collection('databaseBerita')
        .orderBy('tanggalDitambahkan', 'desc')
        .limit(5)
        .get())


        const dataBeritaDapat: Berita[] = []
        const promiseAr: Promise<Berita>[] = [] 
        beritaFirestore.forEach((doc) => {
          promiseAr.push(new Promise((res, rej) => {
            const data: Berita = doc.data() as any
            dataBeritaDapat.push(data)
            res(data)
          }))
        })

        await Promise.all(promiseAr)
        setDataBerita(dataBeritaDapat)

      }catch(e){
        const er = e as Error 
        console.log(er.message)
      }
      try{
        
        const tanggalMusimIni = new Date()
        const tahunMusimIni = DapatkanMusim(tanggalMusimIni)

        const animeMusiman = (await firebase.firestore()
        .collection('databaseAnime')
        .where('musimAnime', '==', tahunMusimIni)
        .orderBy('jumlahReview', 'desc')
        .orderBy('memberMal', 'desc')
        .limit(6)
        .get())

        const dataAnimeDapat: IDataAnime[] = []
        const promiseAn: Promise<IDataAnime>[] = []

        animeMusiman.forEach((doc) => {
          promiseAn.push(new Promise((res, rej) => {
            const data: IDataAnime = doc.data() as any
            dataAnimeDapat.push(data)
            res(data)
          }))
        })

        await Promise.all(promiseAn)
        setDataAnime(dataAnimeDapat)
      }catch(e){
        const er = e as Error 
        console.log(er.message)
      }
      try{
        const feedsDapat = (await firebase.firestore()
        .collection('feeds')
        .orderBy('tanggalUpload', 'desc')
        .limit(5)
        .get())

        const dataFeedsDapat: IFeeds[] = []
        const promiseAr: Promise<IFeeds>[] = [] 
        feedsDapat.forEach((doc) => {
          promiseAr.push(new Promise((res, rej) => {
            const data: IFeeds = doc.data() as any
            dataFeedsDapat.push(data)
            res(data)
          }))
        })

        await Promise.all(promiseAr)
        setDataFeeds(dataFeedsDapat)

      }catch(e){
        const er = e as Error 
        console.log(er.message)
      }
    }
    fetchFirebase()
  }, [])

  const sejarah = useHistory()

  const dispatch = useDispatch()

  const handelPerubahan = (event: ChangeEvent<HTMLInputElement>) => {
    const coba = Number(event.target.value);
    dispatch(storeModeGelap(coba))
  };

  useCacheScroll("beranda")

  return (
    <div className="konten-beranda-apl-animemo">

      <div className='kontainer-carousel-berita'>
        {(dataBerita.length > 2) && <Carousel autoPlay 
          showArrows 
          interval={6000} 
          width={"100%"} 
          infiniteLoop 
          emulateTouch 
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          stopOnHover={false}
        >
          {dataBerita.map((nilai, indeks) => {
            return (
            <a href={nilai.linkBerita || undefined}>
              <div className="isi-carousel-berita">
                <img src={nilai.linkGambar || undefined} className="gbr-carousel" alt="logo" />
                <div className='kontainer-teks-berita'>
                  <h6 className='teks-berita'>
                    {nilai.headlineBerita}
                  </h6>
                </div>
              </div>
            </a>)
          })}
        </Carousel>}
      </div>
      {dataAnime.length >= 1 && <h5 className='nama-anime-musim-ini-apl-animemo'>
        Anime Musim Ini
      </h5>}
      <ul className='kontainer-anime-apl-animemo'>
        {dataAnime.length >= 1 && dataAnime.map((nilai, indeks) => {
          return (
          <li className='list-anime'>
            <Link to={'/a/' + encodeURIComponent(nilai.idAnime)}>
              <div className='kontainer-isi-anime'>
                <img className='gbr' src={nilai.urlFotoAnimeSedang || undefined} />
                <div className='kontainer-teks'>
                  <h6 className='teks'>
                    {nilai.judulAnime}
                  </h6>
                </div>
              </div>
            </Link>
          </li>
          )
        })}
      </ul>
      <h5 className='nama-anime-musim-ini-apl-animemo'>
        Forum terbaru
      </h5>
      {loadingForum ? <LoadingLaman /> 
      :
      <ul className='kontainer-list-forum-apl-animemo'>
        {forum.map((nilai, indeks) => <IsiForum key={indeks} isi={nilai} indeks={indeks}/>)}
      </ul>}
      <h5 className='nama-anime-musim-ini-apl-animemo'>
        Aktivitas pengguna diikuti
      </h5>
      {!loadingAkt ?
        <IsiAktivitas itemAktivitas={aktivitas} perluGbr/>
        :
        <LoadingLaman />
      }
      {aktivitas.length <= 0 && <ItemIsiAktivitas 
        teksAktivitas='Teman anda mulai menonton Anime Re;Zero. Aktivitas teman yang anda ikuti akan muncul di sini'
        teksWaktuAktivitas='2 hari yang lalu'
        perluGbr={false}
        />}
      {auth && <button 
        className='tbl-lihat-aktivitas'
        onClick={() => {sejarah.push(`/h/aktivitas/${auth.uid}`)}}
        >
        Aktivitas saya
      </button>}
      <h5 className='nama-anime-musim-ini-apl-animemo'>
        Feeds terbaru
      </h5>
      {
        dataFeeds.map((nilai, indeks) => 
          <FeedsCard feeds={nilai} beranda indeks={indeks} indeksTerakhir={dataFeeds.length - 1}/>
        )
      }
      <button 
        className='tbl-lihat-aktivitas'
        onClick={() => {sejarah.push(`/h/forum-ortu`)}}
        >
        Lihat lebih banyak feeds
      </button>
      <FormControl>
        <RadioGroup
            name="modeGelap"
            value={modeGelap}
            onChange={handelPerubahan}
          >
          <FormControlLabel value={0} key={"Ikuti sistem"} control={<Radio />} label={"Ikuti sistem"} />
          <FormControlLabel value={1} key={"Mode Gelap"} control={<Radio />} label={"Mode Gelap"} />
          <FormControlLabel value={2} key={"Mode terang"} control={<Radio />} label={"Mode Terang"} />
          {/* {p.isiPilihan.map((pilihan, kunci) => (
          ))} */}
        </RadioGroup>
      </FormControl>
    
      <img src={logo} className="App-logo" alt="logo" />
      <h1>
        catat dan skor anime/komik kamu, instal <a
        className="App-link"
        href="/download"
        target="_blank"
        rel="noopener noreferrer"
      >
        aplikasi AniMemo
      </a>
        {' sekarang juga (Bukan aplikasi nonton)'}
      </h1>

      <h6>
        {`${process.env.REACT_APP_NAME} versi ${process.env.REACT_APP_VERSION}`}
      </h6>

    </div>
  )
}
