import { FC, useCallback, useEffect } from "react";
import "./IsiPemberitahuan.scss";
import { DataPemberitahuan, JenisPemberitahuan } from "Data/Pengguna";
import { Button } from "@material-ui/core";
import firebase from "firebase";
import { toast } from "react-toastify";
import hitungWaktu from "fungsi/hitungWaktu";
import { useHistory } from "react-router-dom";

const IsiPemberitahuan: FC<IIsiPemberitahuan> = (p) => {
  const matikanPemberitahuan = useFungsiPemberitahuan(p.pemberitahuan)
  const sejarah = useHistory()
  return(
    <li className="isi-pemberitahuan-apl-animemo" key={p.indeks} 
      onClick={() => {sejarah.push(`/h/forum-balasan/${p.pemberitahuan.idForum}/terbaru`)}}>
      <h5 className="judul">
        {p.pemberitahuan.judul}
      </h5>
      <h6 className="waktu">
        {hitungWaktu(p.pemberitahuan.waktu?.seconds || null)}
      </h6>
      <h6 className="isi">
        {p.pemberitahuan.isi}
      </h6>
      {(p.pemberitahuan.jenisPemberitahuan === JenisPemberitahuan.JENIS.balasanForumOP) &&
        <Button onClick={(e) => {
          e.stopPropagation()
          matikanPemberitahuan()
        }}>
          Matikan pemberitahuan 
        </Button>
      }
    </li>
  )
}

const useFungsiPemberitahuan = (pemberitahuan: DataPemberitahuan) => {

  const matikanPemberitahuan = useCallback(async () => {
    if(pemberitahuan && pemberitahuan.idKe && pemberitahuan.waktu && !pemberitahuan.matikanPemberitahuan){
      try{
        await firebase.firestore()
        .doc(`forumPengguna/${pemberitahuan.idForum}`)
        .update({
          matikanBalasan: true
        })
        await firebase.firestore()
        .doc(`pemberitahuan/${pemberitahuan.idPemberitahuan}`)
        .update({
          matikanPemberitahuan: true
        })
      }catch(e){
        console.error(e)
      }
      toast.success("Pemberitahuan telah dimatikan");
    }
  }, [pemberitahuan])

  const terbaca = async () => {
    if(pemberitahuan && pemberitahuan.idKe && pemberitahuan.waktu && !pemberitahuan.dibaca){
      await firebase.firestore()
      .doc(`pemberitahuan/${pemberitahuan.idPemberitahuan}`)
      .update({
        dibaca: true
      })
      console.log("terbaca selesai")
    }
  }
  useEffect(() => {terbaca()}, [pemberitahuan])
  return matikanPemberitahuan
}

interface IIsiPemberitahuan{
  pemberitahuan: DataPemberitahuan
  indeks: number
}

export default IsiPemberitahuan