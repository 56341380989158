import {FC, memo} from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {ItemNavigasi} from '../../navigasi/navigasiAtas/NavigasiAtas';
import BarCari from './BarCari'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: '#800000',
      width: '70%',
      alignItems: 'center',
      // border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: '5px',
    },
  }),
);

const TransisiModal: FC<ITransisiModal> = memo((p) => {
  const classes = useStyles();
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={p.apakahCari}
        onClose={p.handelCariTutup}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={p.apakahCari}>
          <div className={classes.paper}>
            <BarCari teksPlaceH="Cari Anime..." 
            handelTutup={p.handelCariTutup}/>
          </div>
        </Fade>
      </Modal>
    </div>
  );
},(pSeb, pSet) =>{
  if(pSeb.apakahCari !== pSet.apakahCari){
    return false
  }
  return true
})

interface ITransisiModal{
  handelCari: () => void; 
  handelCariTutup: () => void;
  apakahCari: boolean;
}

export default TransisiModal