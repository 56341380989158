import { createTheme, Theme } from "@material-ui/core";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IRootRedux } from "store/reducers";
import { IStyles } from "./Tema";


function useCekTema(){
  const modeGelap = useSelector((state: IRootRedux) => state?.ui?.modeGelap || 0)
  const [temaDapat, setTemaDapat] = useState(createTheme())

  useEffect(() => {
    const handelEvent = (event: MediaQueryListEvent) => {
      if(modeGelap !== 0) return
      const skemaWarna = event.matches ? "gelap" : "terang";
      setTemaDapat(dapatkanTema(skemaWarna))
      if(skemaWarna === "gelap") {
        ubahKeModeGelap(setTemaDapat)
        return
      }
      ubahKeModeTerang(setTemaDapat)
    }
    
    const removeListener = () => {
      window.matchMedia && 
        window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', handelEvent);
    }

    removeListener()
    if(modeGelap === 1) {
      ubahKeModeGelap(setTemaDapat)
      return removeListener
    }
    if(modeGelap === 2){
      ubahKeModeTerang(setTemaDapat);
      return removeListener
    }
    cekModeGelapSistem(setTemaDapat)
    window.matchMedia && 
      window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', handelEvent);
    return removeListener
  }, [modeGelap])

  return [modeGelap, temaDapat] as [number, Theme]
}

const cekModeGelapSistem = (setTemaDapat: Dispatch<SetStateAction<Theme>>) => {
  if(!window.matchMedia) return
  if(window.matchMedia('(prefers-color-scheme: dark)').matches){
    ubahKeModeGelap(setTemaDapat);
    return
  }
  ubahKeModeTerang(setTemaDapat);
}

export const getDark = (styles: CSSProperties, modeGelap: boolean) => {
  return modeGelap ? styles : {} as CSSProperties
}

const ubahKeModeTerang = (setTemaDapat: Dispatch<SetStateAction<Theme>>) => {
  document.documentElement.style.setProperty('--bg', '#ffffff');
  document.documentElement.style.setProperty('--bg-lebih-gelap', '#ececec');
  document.documentElement.style.setProperty('--bg-gelap', '#e2e2e2');
  document.documentElement.style.setProperty('--bg-terang', '#cacaca'); 
  document.documentElement.style.setProperty('--bg-terang-terang', '#bebebe'); 
  document.documentElement.style.setProperty('--bg-aksen', '#adadad');
  document.documentElement.style.setProperty('--bg-abu-abu', '#9e9e9e');
  document.documentElement.style.setProperty('--bg-transparan-80', '#FFFFFFCC');
  document.documentElement.style.setProperty('--bg-transparan-70', '#FFFFFFB3');
  document.documentElement.style.setProperty('--bg-transparan-60', '#FFFFFF99');
  document.documentElement.style.setProperty('--bg-transparan-full', '#FFFFFF00');
  document.documentElement.style.setProperty('--teks-abu-abu', '#5e5e5e');
  document.documentElement.style.setProperty('--warna-teks', '#000000');
  document.documentElement.style.setProperty('--teks-tak-menyala-terang', 'rgba(0, 0, 0, 0.7)');
  document.documentElement.style.setProperty('--teks-tak-menyala', 'rgba(0, 0, 0, 0.5)');
  document.documentElement.style.setProperty('--warna-tema-primary-terang', '#ff7961');
  document.documentElement.style.setProperty('--warna-tema-primary-gelap', '#ba000d');
  document.documentElement.style.setProperty('--warna-tema-primary', '#f44336');
  setTemaDapat(dapatkanTema('terang'))
}


const ubahKeModeGelap = (setTemaDapat: Dispatch<SetStateAction<Theme>>) => {
  document.documentElement.style.setProperty('--bg', '#000000');
  document.documentElement.style.setProperty('--bg-lebih-gelap', '#0a0a0a');
  document.documentElement.style.setProperty('--bg-gelap', '#121212');
  document.documentElement.style.setProperty('--bg-terang', '#171717'); 
  document.documentElement.style.setProperty('--bg-terang-terang', '#1b1b1b'); 
  document.documentElement.style.setProperty('--bg-aksen', '#2c2c2c');
  document.documentElement.style.setProperty('--bg-abu-abu', '#484a4d');
  document.documentElement.style.setProperty('--bg-transparan-80', '#000000CC');
  document.documentElement.style.setProperty('--bg-transparan-70', '#000000B3');
  document.documentElement.style.setProperty('--bg-transparan-60', '#00000099');
  document.documentElement.style.setProperty('--bg-transparan-full', '#00000000');
  document.documentElement.style.setProperty('--teks-abu-abu', '#A8A8A8');
  document.documentElement.style.setProperty('--warna-teks', '#ffffff');
  document.documentElement.style.setProperty('--teks-tak-menyala-terang', 'rgba(255, 255, 255, 0.7)');
  document.documentElement.style.setProperty('--teks-tak-menyala', 'rgba(255, 255, 255, 0.5)');
  document.documentElement.style.setProperty('--warna-tema-primary-terang', '#ff7961');
  document.documentElement.style.setProperty('--warna-tema-primary-gelap', '#ba000d');
  document.documentElement.style.setProperty('--warna-tema-primary', '#f44336');
  setTemaDapat(dapatkanTema('gelap'))
}

const dapatkanTema = (mode: 'gelap' | 'terang') => createTheme({
  palette: {
    primary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
    },
    secondary:{
      main: '#0288d1',
      light: '#5eb8ff',
      dark: '#005b9f',
    },
    type: mode === 'gelap'? 'dark' :  'light',
  }
})

export default useCekTema