import {FC, RefObject, useEffect, useState} from 'react';
import './IsiAnime.scss';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import {Link} from 'react-router-dom'
import useDimensiLayar from 'fungsi/UseDimensiLayar';
import {IKoleksiPengguna} from 'Data/KoleksiPengguna'
import firebase from 'firebase';

const IsiAnime: FC<IIsiMusiman> = (p) => {
  const storage = firebase.storage()
  const [urlGbr, setUrlGbr] = useState<string | null>(null)
  const [urlGbrKecil, setUrlGbrKecil] = useState<string | null>(null)

  useEffect(() => {
    const idAnime = p.koleksiAnime?.idAnime
    if(idAnime){
      try{
        storage.ref('posterAnime/anime' + idAnime + 'sedang').getDownloadURL().then((url) => {
          setUrlGbr(url)
        })
        storage.ref('posterAnime/anime' + idAnime + 'kecil').getDownloadURL().then((url) => {
          setUrlGbrKecil(url)
        })
      }catch(e){
        console.log("fetch url eror")
        console.log(idAnime)
        if(e instanceof Error){
          console.log(e.message)
        }
      }
    }
  }, [p.koleksiAnime?.idAnime])

  const {lebarLayar, tinggiLayar} = useDimensiLayar()
  return(
    <li 
      ref={p.indeks === p.animeTerakhirLoad ? p.refAnimeTerakhir : undefined} 
      key={p.indeks}>
      <Link 
        style={{textDecoration:"none"}} to={'/a/' + encodeURIComponent(p.koleksiAnime?.idAnime || "")}
        onClick={() => {p.cacheAnime && p.cacheAnime()}}
      >
        <div className='list-anime-pengguna' >
          <img 
            className='gbr-anime' 
            src={(lebarLayar < 500 ? urlGbr : urlGbrKecil) || undefined}  
          />
          <div className='kontainer-koleksi'>
            <h6 className='judul-anime'>
              {p.koleksiAnime?.judulAnime}
            </h6>
            <div className='rating-anime'>
              <div className='rating'>
                <StarBorderIcon/>
                <h6 className='nilai'>
                  {p.koleksiAnime?.skorPengguna}
                </h6>
              </div>
              <h6 className='episode-nonton'>
                {"Episode: " + p.koleksiAnime?.episodeProgress}
              </h6>
            </div>
          </div>
        </div>
      </Link>
    </li>
  )
}

export default IsiAnime

interface IIsiMusiman{
  koleksiAnime?: IKoleksiPengguna;
  indeks: number;
  animeTerakhirLoad?: number;
  refAnimeTerakhir?: RefObject<HTMLLIElement>;
  cacheAnime: () => void;
}