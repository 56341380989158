import { TypeAksi } from ".";


export interface IAksiPengguna {
  type: TypeAksi,
  payload: {[x: string]: any | number},
}


export function pengguna(state: any = null, action: IAksiPengguna){
  switch(action.type){
    case 'STORE_PEMBERITAHUAN':
      return {
        ...state,
        ...action.payload
      }
    case 'STORE_PENGGUNA':
      return{
        ...state,
        ...action.payload
      }
    default:
      return state 
  }
}