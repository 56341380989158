import { useEffect, useState } from "react"
import { DataLaporkanPos } from "Data/LaporanPengguna"
import firebase from 'firebase'

const useIsiLaporkan = (): [DataLaporkanPos, boolean] => {
  const [isiLaporkanPos, setIsiLaporkanPos] = useState(isiLaporkanCadangan)
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    const dapatkanFirestore = async () => {
      try{
        setLoading(true)
        const dokLaporPos: any = (await firebase.firestore().doc('nilaiServer/laporkanPos').get()).data()
        const laporkanPosDapat: DataLaporkanPos = dokLaporPos
        setIsiLaporkanPos(laporkanPosDapat)
        setLoading(false)
      }catch(e){
        setIsiLaporkanPos(isiLaporkanCadangan)
        setLoading(false)
      }
    }
    dapatkanFirestore()
  }, [])

  return [isiLaporkanPos, loading]
}

const isiLaporkanCadangan = {
  forum: [
    "Pornografi atau vulgar yang berlebihan",
    "Kekerasan atau kekejaman belebihan",
    "Mengandung unsur SARA atau rasisme",
    "Pembullyan atau kata kasar berlebihan",
    "Obat-obatan terlarang atau aktivitas ilegal lainnya",
    "Membahayakan anak",
    "Spam atau menyesatkan"
  ],
  anakForum: [
    "Pornografi atau vulgar yang berlebihan",
    "Kekerasan atau kekejaman belebihan",
    "Mengandung unsur SARA atau rasisme",
    "Pembullyan atau kata kasar berlebihan",
    "Obat-obatan terlarang atau aktivitas ilegal lainnya",
    "Membaahayakan anak",
    "Spam atau menyesatkan"
  ],
  pengguna: [
    "Mengunggak pornografi/konten vulgar berlebihan",
    "Profil ini berpura-pura menjadi orang lain",
    "Ancaman kekerasan",
    "Spam atau menyesatkan",
    "Ujaran kebencian terhadap pihak tertentu",
    "Bullying atau pelecehan",
    "Membahayakan anak"
  ]
} as DataLaporkanPos

export default useIsiLaporkan