import { DataOrtuForum } from "Data/DataForum";
import { usePenggunaId } from "fungsi/usePengguna";
import { FC, MutableRefObject } from "react";
import { Link } from "react-router-dom";
import './IsiOrtuForum.scss'

const IsiOrtuForum: FC<IIsiOrtuForum> = (p) => {
  return(
    <li className="isi-ortu-forum-apl-animemo-list" ref={p.indeks === p.indeksTerakhir ? p.refArTerakhir : undefined}>
      <Link style={{textDecoration:"none"}} to={`/h/forum/${p.isi.idOrtuForum}/balasan-baru`}>
        <div  className='isi-ortu-forum-apl-animemo'>
          <img className='gbr-ortu-forum' src={p.isi.fotoOrtuForum || undefined}/>
          <div className='teks-ortu-forum'>
            <h5 className='judul'>
              {p.isi.judulOrtuForum}
            </h5>
            <h6 className='isi'>
              {p.isi.deskripsiOrtuForum}
            </h6>
          </div>
        </div>
      </Link>
    </li>
  )
}

export default IsiOrtuForum

interface IIsiOrtuForum{
  indeks: number
  indeksTerakhir: number
  refArTerakhir: MutableRefObject<HTMLLIElement | null>
  isi: DataOrtuForum
}