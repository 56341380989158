import firebase from 'firebase/app';
import 'firebase/firestore'
import {IWaktuPerMenit} from '../Data/NilaiServer'


export async function waktuRealtime(): Promise<[waktu: firebase.firestore.Timestamp, benar: boolean]> {
  const dbWaktu = firebase.firestore().doc('nilaiServer/waktuPerMenit')
  const ssWaktu = (await dbWaktu.get()).data()
  const dokWaktu: any = ssWaktu
  const dataWaktuServer: IWaktuPerMenit | undefined | null = dokWaktu
  const waktuSaatIni = firebase.firestore.Timestamp.now()
  let waktuDipakai = waktuSaatIni

  const selisihWaktu = (dataWaktuServer?.waktuPerDuaMenit.seconds ?? waktuSaatIni.seconds) -  waktuSaatIni.seconds
  const bedaWaktuSalah = selisihWaktu > 0 || selisihWaktu < (-180)

  if(dataWaktuServer != null){
    if(bedaWaktuSalah){ waktuDipakai = dataWaktuServer.waktuPerDuaMenit}
    return [waktuDipakai, true]
  }else{
    return [waktuDipakai, false]
  }
}

export const terjemahkanWaktuNonton = (waktuMenit: number | undefined | null) => {
  const waktuDapat = waktuMenit || 0
  return (waktuDapat < 60*24) ? (waktuDapat/60).toFixed(1) + ` jam` : (waktuDapat/(60*24)).toFixed() + ` hari`
}