import { FC, memo, MutableRefObject, Ref, RefCallback} from 'react';
import { TextField } from '@material-ui/core';
import { SetStateAction } from 'react';

const InputTeks: FC<IInputTeks> = memo((prop) => {
  
  const handelTeks = (even: React.ChangeEvent<HTMLInputElement>) => {
    prop.setTeksIsi && prop.setTeksIsi(even.target.value)
    cekKurangDariMaks(prop.teksIsi)
  }
  const cekKurangDariMaks = async (teks: string | null | undefined) => {
    if(prop.setKurangDariMaks && prop.kataMaksimal){ 
      prop.setKurangDariMaks((teks || '').length < prop.kataMaksimal)
    }
  }

  return(
    <>
      <TextField id='tf-teks-input-nama' label={prop.teksLabel}
        color='secondary'
        size='medium'
        inputRef={prop.inputRef}
        onChange={handelTeks}
        error={!prop.kurangDariMaks}
        defaultValue={prop.teksIsi ?? prop.isiAwal}
        multiline={prop.multiGaris}
        maxRows={prop.maksGaris}
        fullWidth={prop.lebarMaksimal}
      />
    </>
  )
},
  (pSeb, pSet) => {
    if(pSeb.teksIsi !== pSet.teksIsi){return false}
    return true
  }
)

export default InputTeks

interface IInputTeks {
  teksIsi?: string | null;
  setTeksIsi?: (teks: SetStateAction<string | null>) => void;

  // harus satu paket{
  kataMaksimal?: number;
  setKurangDariMaks?: (kurangDariMaks: SetStateAction<boolean>) => void;
  kurangDariMaks?: boolean;

  inputRef?: MutableRefObject<HTMLInputElement | null>;
  teksLabel?: string;
  isiAwal?: string | null;
  multiGaris?: boolean;
  maksGaris?: number;
  lebarMaksimal?: boolean
}