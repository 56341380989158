import {FC, ReactElement} from 'react';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core/SvgIcon';
import './ItemInfoCepat.scss';

const ItemInfoCepat: FC<IItemInfoCepat> = (prop) => {
  return (
    <div className='item-info-cepat-profil'>
      <div className='gambar-info-cepat-profil'>
        {<prop.gambar/>}
      </div>
      <div className='isi-info-cepat-profil'>
        {prop.isiInfo}
      </div>
    </div>
  )
}

export default ItemInfoCepat

interface IItemInfoCepat {
  gambar: OverridableComponent<SvgIconTypeMap<{}, "svg">>  ;
  isiInfo: string;
}