import {DataPemberitahuan, IPengguna} from "Data/Pengguna";
import { TypeAksi } from "store/reducers";

export const storePemberitahuan = (payload: DataPemberitahuan[]) => {
  const tipe: TypeAksi = "STORE_PEMBERITAHUAN"
  return {
    type: tipe,
    payload: {
      pemberitahuan: payload
    },
  }
}


export const storePengguna = (payload: IPengguna | null) => {
  const tipe: TypeAksi = 'STORE_PENGGUNA';
  return {
    type: tipe,
    payload: {
      pengguna: payload
    }
  }
}