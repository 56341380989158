import {FC, useRef, useEffect, useState, ChangeEvent, memo, CSSProperties} from 'react';
import { Dialog, DialogTitle, DialogContent, RadioGroup, Button, DialogActions, FormControlLabel, Radio} from '@material-ui/core';
import './InputRadio.scss'

const InputRadio: FC<IInputRadio> = memo((prop) => {
  const [buka, setBuka] = useState(false)
  const [teksTerpilih, setTeksTerpilih] = useState(prop.pilihanKosongString || 'pilih')

  const handelKlikItem = () => {setBuka(true)}

  const onTutup = (nilaiTerpilih: number | null) => {
    prop.onTerpilih(nilaiTerpilih)
    if(nilaiTerpilih != null){
      setTeksTerpilih(prop.isiPilihan[nilaiTerpilih])
    }else{
      setTeksTerpilih(prop.pilihanKosongString || 'pilih')
    }
    console.log((prop.judulPilihan || '')  + nilaiTerpilih)
    setBuka(false)
  }

  useEffect(() => {
    if(prop.pilihanTerpilih !== null && prop.pilihanTerpilih !== undefined){
      setTeksTerpilih(prop.isiPilihan[prop.pilihanTerpilih])
    }else{
      setTeksTerpilih(prop.pilihanKosongString || 'pilih')
    }
  }, [prop.isiPilihan, prop.pilihanTerpilih])
  

  return(
    <div>
      <div className='kontainer-teks-input-radio'>
        {prop.pilihanTerpilih != null ? 
          <h6 className='teks-input-radio-terpilih' onClick={handelKlikItem}
          style={prop.styleTeks}>{teksTerpilih}</h6>
        :
          <h6 className='teks-input-radio' onClick={handelKlikItem}
          style={prop.styleTeks}>{teksTerpilih}</h6>
        }
      </div>
      <DialogInputRadio
        judulPilihan={prop.judulPilihan || ''}
        isiPilihan={prop.isiPilihan}
        pilihanTerpilih={prop.pilihanTerpilih}
        buka={buka}
        onTutup={onTutup}
      />
    </div>
  )
}, (pSeb, pSet) =>{
  if(pSeb.pilihanTerpilih !== pSet.pilihanTerpilih 
    || pSeb.isiPilihan !== pSet.isiPilihan 
    || pSeb.onTerpilih !== pSet.onTerpilih 
    || pSeb.judulPilihan !== pSet.judulPilihan
    || pSeb.pilihanKosongString !== pSet.pilihanKosongString){
    return false
  }
  return true
})


const DialogInputRadio: FC<IDialogInputRadio> = (p) => {

  const refRadioGrup = useRef<HTMLElement>(null);
  const [terpilih, setTerpilih] = useState(p.pilihanTerpilih)

  useEffect(() => {
    if (!p.buka) {
      setTerpilih(p.pilihanTerpilih);
    }
  }, [p.pilihanTerpilih, p.buka]);

  const handelMasukNilai = () => {
    if (refRadioGrup.current != null) {
      refRadioGrup.current.focus()
    }
  }

  const handelOk = () => {p.onTutup(terpilih)}
  const handelBatal = () => {p.onTutup(p.pilihanTerpilih)}

  const handelPerubahan = (event: ChangeEvent<HTMLInputElement>) => {
    setTerpilih(Number((event.target as HTMLInputElement).value));
  };

  return(
    <Dialog
      maxWidth="xs"
      onEntering={handelMasukNilai}
      aria-labelledby="confirmation-dialog-title"
      open={p.buka}
      keepMounted
    >
      <DialogTitle id="confirmation-dialog-title">{p.judulPilihan}</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={refRadioGrup}
          name="gender"
          value={terpilih}
          onChange={handelPerubahan}
        >
          {p.isiPilihan.map((pilihan, kunci) => (
            <FormControlLabel value={kunci} key={pilihan} control={<Radio />} label={pilihan} />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handelBatal} color="primary">
          Batal
        </Button>
        <Button onClick={handelOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

interface IInputRadio{
  judulPilihan?: string;
  isiPilihan: Array<string>;
  pilihanTerpilih: number | null;
  pilihanKosongString?: string;
  styleTeks?: CSSProperties;
  onTerpilih: (nilaiTerpilih: number | null) => void;
}

interface IDialogInputRadio{
  judulPilihan?: string;
  isiPilihan: Array<string>;
  pilihanTerpilih: number | null;
  buka: boolean;
  onTutup: (nilaiTerpilih: number | null) => void;
}

export default InputRadio
export {DialogInputRadio}