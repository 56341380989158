import React, { Dispatch, FC, useState } from "react";
import {uploadGambarFirebaseV2} from 'komponen/input/inputGambar/InputGambar'
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase"
import { waktuRealtime } from "fungsi/fungsiWaktu";
import { IURLGambar } from "komponen/input/inputGambar/fungsi/prosesBlob";
import ReactCrop from "react-image-crop";

function useUploadGbrForum(): TUseUploadGbrForum {
  const [kanvas, setKanvas] = useState<HTMLCanvasElement | null>(null)
  const [crop, setCrop] = useState<ReactCrop.Crop | null>(null)
  const [auth] = useAuthState(firebase.auth())
  const [loading, setLoading] = useState(false)
  const setGbrKosong = () => {
    setKanvas(null)
    setCrop(null)
  }

  const handelUploadGbr: TuploadGbr = async () => {
    if(auth){
      setLoading(true)
      const [waktu] = await waktuRealtime()
      const namaFile = "Forum" + auth.uid + waktu.seconds
      try{
        const hasilURL = await uploadGambarFirebaseV2(kanvas, crop, "fotoForum", namaFile, true, "Kecil", "Sedang")
        setLoading(false)
        if(hasilURL.success){
          return {
            success: true,
            waktu: waktu,
            data: hasilURL.data
          }
        }else{
          return {
            success: false,
            waktu: null,
            data: null
          }
        }
      }catch(e){
        setLoading(false)
        console.error("upload gbr gagal")
        return {
          success: false,
          waktu: null,
          data: null,
        }
      }
    }else{
      return {
        success: false,
        waktu: null,
        data: null,
      }
    }
  }
  return [setKanvas, kanvas, crop, setCrop, handelUploadGbr, setGbrKosong, loading]
}

type TUseUploadGbrForum = [Dispatch<HTMLCanvasElement | null>, 
  HTMLCanvasElement | null, 
  ReactCrop.Crop | null,
  Dispatch<ReactCrop.Crop | null>,
  () => Promise<IHasilUpload>,
  () => void,
  boolean
]

type TuploadGbr = () => Promise<IHasilUpload>;

export interface IHasilUpload{
  success: boolean,
  waktu: firebase.firestore.Timestamp | null,
  data: IURLGambar | null
}

export default useUploadGbrForum