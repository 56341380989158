import {DataAktivitasPengguna, JenisAktivitas} from '../../../../Data/Pengguna'

export function terjemahkanAktivitas(aktivitas: DataAktivitasPengguna): string{
  var teks = ''
  switch(aktivitas.jenisAktivitas){
    case JenisAktivitas.tambahAnime:
      teks = tambahAnime(aktivitas);
      break;
    case 1:
      teks = editAnime(aktivitas);
      break;
    case 2:
      teks = tambahKomik(aktivitas);
      break;
    case 3:
      teks = editKomik(aktivitas);
      break;
    case 4:
      teks = tambahAnakForum(aktivitas);
      break;
    case 5: 
      teks = tambahForum(aktivitas);
      break;
    default:
      teks = aktivitasUmum(aktivitas)
  }
  return teks
}

export function aktivitasUmum(aktivitas: DataAktivitasPengguna): string{
  return aktivitas.isiAktivitas || ""
}

export function terjemahkanWaktu(aktivitas: DataAktivitasPengguna): string{
  const perbedaanWaktu =  (Date.now()/1000) - (aktivitas.waktuAktivitas?.seconds || 0)
  const perbedaanWaktuMenit = (((perbedaanWaktu/60) <= 0) ? 1.0 : (perbedaanWaktu/60)).toFixed(0)
  const perbedaanWaktuJam = ((perbedaanWaktu/(60*60) <= 0) ? 1.0 : (perbedaanWaktu/(60*60))).toFixed(0)
  const perbedaanWaktuHari = ((perbedaanWaktu/(24*60*60)<= 0) ? 1.0 : (perbedaanWaktu/(24*60*60))).toFixed(0)
  const perbedaanWaktuMinggu = ((perbedaanWaktu/(7*24*60*60)<= 0) ? 1.0 : (perbedaanWaktu/(7*24*60*60))).toFixed(0)
  const perbedaanWaktuBulan = ((perbedaanWaktu/(4*7*24*60*60)<= 0) ? 1.0 : (perbedaanWaktu/(4*7*24*60*60))).toFixed(0)
  const perbedaanWaktuTahun = ((perbedaanWaktu/(12*4*7* 24*60*60)<= 0) ? 1.0 : (perbedaanWaktu/(12*4*7* 24*60*60))).toFixed(0)
  var teksWaktu = ''
  switch(true){
    case perbedaanWaktu <= 60:
      teksWaktu = `${perbedaanWaktu.toFixed()} detik yang lalu`;
      break;
    case (perbedaanWaktu > 60 && perbedaanWaktu <= 3600):
      teksWaktu = `${perbedaanWaktuMenit} menit yang lalu`;
      break;
    case (perbedaanWaktu > 3600 && perbedaanWaktu <= 86400):
      teksWaktu = `${perbedaanWaktuJam} jam yang lalu`;
      break;
    case (perbedaanWaktu > 86400 && perbedaanWaktu <= 604800):
      teksWaktu = `${perbedaanWaktuHari} hari yang lalu`;
      break;
    case (perbedaanWaktu > 604800 && perbedaanWaktu <= 2419200):
      teksWaktu = `${perbedaanWaktuMinggu} minggu yang lalu`;
      break;
    case (perbedaanWaktu > 2419200 && perbedaanWaktu <= 31536000):
      teksWaktu = `${perbedaanWaktuBulan} bulan yang lalu`;
      break;
    default :
      teksWaktu = `${perbedaanWaktuTahun} tahun yang lalu`;
  }
  return teksWaktu
}

function tambahAnakForum(aktivitas: DataAktivitasPengguna): string{
  return ((aktivitas.namaPengguna || '') + ' memberi balasan ' 
  + (`'${aktivitas.isiPos}'` || '') + ' terhadap forum ' + `'${aktivitas.judulPos}'` + '.')
}

function tambahForum(aktivitas: DataAktivitasPengguna): string{
  return ((aktivitas.namaPengguna || '') + ' menambahkan forum baru ' + (`'${aktivitas.judulPos}'`) + '.')
}

function editKomik(aktivitas: DataAktivitasPengguna): string{
  var teks = aktivitas.namaPengguna || '' + ' mengubah koleksi komik' + (aktivitas.judulPos || '') + '-nya.'
  const ditemukanReview =  cekAdaKata('REVIEW', aktivitas.jenisPerubahan)
  const ditemukanSkor =  cekAdaKata('SKOR', aktivitas.jenisPerubahan)
  const ditemukanStatus =  cekAdaKata('STATUS', aktivitas.jenisPerubahan)
  const ditemukanChapter =  cekAdaKata('CHAPTER', aktivitas.jenisPerubahan)
  const ditemukanVolume =  cekAdaKata('VOLUME', aktivitas.jenisPerubahan)

  if(ditemukanChapter){teks += (' dari chapter ' + aktivitas.chapterSebelum?.toString() 
  + ' ke ' + aktivitas.chapter?.toString() + '. ')};

  if(ditemukanVolume){teks += (' dari volume ' + aktivitas.volumeSebelum?.toString() 
  + ' ke ' + aktivitas.volume?.toString() + '. ')};

  if(ditemukanStatus){teks += (' dari ' + status(aktivitas.statusSebelum, 0) 
  + ' ke ' + status(aktivitas.status, 0)+ '.')};

  if(ditemukanSkor){teks += (' dari skor '+ (aktivitas.skorSebelum || '?') + ' ke ' + (aktivitas.skor || '?'))
  };

  if(ditemukanReview){teks += 'dan mengubah ulasannya'};

  return teks
}

function tambahKomik(aktivitas: DataAktivitasPengguna): string{
  return ((aktivitas.namaPengguna || '') + ' menambahkan komik ' + (aktivitas.judulPos || '') 
  + ' dalam koleksinya dengan nilai ' + aktivitas.skor?.toString() + ' dengan status ' 
  + status(aktivitas.status, aktivitas.jenisAktivitas) + ', sampai chapter ke ' 
  + (aktivitas.chapter?.toString())) + ', volume ke ' + (aktivitas.volume?.toString())  
}

function editAnime(aktivitas: DataAktivitasPengguna): string{
  var teks = (aktivitas.namaPengguna || '') + ' mengubah koleksi anime ' + (aktivitas.judulPos || '') + '-nya.' 
  const ditemukanReview = cekAdaKata('REVIEW', aktivitas.jenisPerubahan)
  const ditemukanSkor = cekAdaKata('SKOR', aktivitas.jenisPerubahan)
  const ditemukanStatus = cekAdaKata('STATUS', aktivitas.jenisPerubahan)
  const ditemukanEpisode = cekAdaKata('EPISODE', aktivitas.jenisPerubahan)

  if(ditemukanEpisode && aktivitas.episodeSebelum){teks += (' dari episode ' + aktivitas.episodeSebelum?.toString() 
  + ' ke ' + aktivitas.episode?.toString() + '. ')};

  if(ditemukanStatus){teks += (' dari ' + status(aktivitas.statusSebelum, 0) 
  + ' ke ' + status(aktivitas.status, 0)+ '.')};

  if(ditemukanSkor){teks += (' dari skor '+ (aktivitas.skorSebelum || '?') + ' ke ' + (aktivitas.skor || '?'))
  };

  if(ditemukanReview){teks += 'dan mengubah ulasannya'};

  return teks
}

function cekAdaKata(kataDicari: string, kalimat: string | undefined | null){
  var ditemukan = true
  if(kalimat != null){
    ditemukan = kalimat.search(kataDicari) >= 0 
  }
  return ditemukan
}

function tambahAnime(aktivitas: DataAktivitasPengguna): string{
  if(aktivitas.status === 0){
    return ((aktivitas.namaPengguna || '') + ' mulai menonton ' + (aktivitas.judulPos || '') 
    + ', dengan nilai ' + aktivitas.skor?.toString() 
    + ', sampai episode ke ' + (aktivitas.episode?.toString()))
  }else if(aktivitas.status === 1){
    return ((aktivitas.namaPengguna || '') + ' selesai menonton ' + (aktivitas.judulPos || '') 
    + ' dengan nilai ' + aktivitas.skor?.toString())
    
  }else if(aktivitas.status === 2){
    return ((aktivitas.namaPengguna || '') + ' berencana menonton ' + (aktivitas.judulPos || ''))
  }{
    return ((aktivitas.namaPengguna || '') + ' tak jadi menonton ' + (aktivitas.judulPos || '') 
    + ' dalam koleksinya dengan nilai ' + aktivitas.skor?.toString() 
    + ', sampai episode ke ' + (aktivitas.episode?.toString()))
  }
}

function status(status: number | undefined, jenis: number | undefined): string{
  let statusNonBaca = ''
  if(jenis === 0){
    switch(status){
      case 0:
        statusNonBaca = 'ditonton';
        break;
      case 1:
        statusNonBaca = 'selesai';
        break;
      case 2: 
        statusNonBaca = 'rencana';
        break;
      default:
        statusNonBaca = 'tak selesai';
    }
  }else{ 
    switch(status){
      case 0:
        statusNonBaca = 'dibaca';
        break;
      case 1:
        statusNonBaca = 'selesai';
        break;
      case 2:
        statusNonBaca = 'rencana';
        break;
      default:
        statusNonBaca = 'tak selesai';
    }
  }
  return statusNonBaca
}