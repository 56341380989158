export default class DataGenre{
  arGenre: Array<string>;
  constructor(){
    this.arGenre = [
      "Aksi",
      "Drama",
      "Fantasi",
      "Komedi",
      "Mecha",
      "Romance",
      "Shoujo",
      "Shounen",
      "Slice of Life",
      "Anak-anak",
      "Bela diri",
      "Demensia",
      "Ecchi",
      "Game",
      "Harem",
      "Hentai",
      "Horor",
      "Iblis",
      "Josei",
      "Kekuatan Super",
      "Luar Angkasa",
      "Militer",
      "Misteri",
      "Mobil",
      "Musik",
      "Olahraga",
      "Parodi",
      "Petualangan",
      "Polisi",
      "Psikologis",
      "Samurai",
      "Sci-Fi",
      "Seinen",
      "Sejarah",
      "Sekolah",
      "Shoujo Ai",
      "Shounen Ai",
      "Sihir",
      "Supernatural",
      "Thriler",
      "Vampir",
      "Yaoi",
      "Yuri"
    ]
  }
}