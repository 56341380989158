import { TypeAksi } from "store/reducers";

export const storeModeGelap = (payload: number) => {
  const tipe: TypeAksi = "MODE_GELAP"
  const isiPayload = {
    modeGelap: payload
  }
  localStorage.setItem("modeGelap", JSON.stringify(isiPayload))

  return {
    type: tipe,
    payload: isiPayload
  }
}