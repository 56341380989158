import { IDataAnime } from "Data/Anime";
import LoadingLaman from "laman/loadingLaman/LoadingLaman";
import { FC, useRef } from "react";
import IsiReviewAnime from "../Komponen/IsiReviewAnime";
import useFetchReviewAnime from "./useFetchReviewAnime";
import "./ReviewAnime.scss"


const ReviewAnime: FC<IReviewAnime> = (p) => {

  const [review, loading] = useFetchReviewAnime(p.anime)

  if(!loading){
    return(
      <div className="kontainer-review-anime-apl-animemo">
        <h4 className="judul-review-apl">
          Review Pengguna
        </h4>
        {review.length > 0 ? <ul className="review-anime">
          {review.map((nilai) => <IsiReviewAnime review={nilai} />)}
        </ul> : <h6>Review masih kosong</h6>}
      </div>
    )
  }else{
    return(
      <LoadingLaman />
    )
  }
}

interface IReviewAnime{
  anime?: IDataAnime
}

export default ReviewAnime