import { FC, useEffect, useRef, useState } from "react";
import IsiOrtuForum from "../Komponen/IsiOrtuForum";
import {DataOrtuForum, IFeeds} from "Data/DataForum"
import useFetchFirestore from "fungsi/useFetchFirestore";
import firebase from 'firebase';
import './ForumOrtu.scss'
import LoadingLaman from "laman/loadingLaman/LoadingLaman";
import { Link } from "react-router-dom";
import IkonTambah from '@material-ui/icons/Add'
import { Fab } from "@material-ui/core";
import FeedsCard from "../Komponen/Feeds/FeedsCard";
import DialogHapusUmum from "laman/ForumAnak/Komponen/DialogHapusUmum";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { IRootRedux } from "store/reducers";

const ForumOrtu: FC<IOrtuForum> = () => {

  const [bukaHapus, setBukaHapus] = useState(false)
  const idFeedsHapus = useRef<string | null>(null)

  const db = firebase.firestore()
  const [arOrtuForum, refOrtuForumTerakhir, indeksOrtuForumTerakhir, loading, dokTerakhirDalamLayar] 
  = useFetchFirestore<DataOrtuForum>(10, db.collection('ortuForumPengguna').orderBy('prioritasOrtuForum', 'asc'),
    'forum-ortu')
  
  const [arFeeds, refFeedsTerakhir, indeksFeedsTerakhir, loadingFeeds, dokFeedsTerakhirDalamLayar, cacheLaman, refreshFeeds ] 
  = useFetchFirestore<IFeeds>(10, db.collection('feeds').orderBy('tanggalUpload', 'desc'),
    'forum-ortu-feeds')

  const feedTakValid = useSelector((state: IRootRedux) => state?.cacheData?.cacheFeedTakValid === true)

  const handelHapusFeeds = async () => {
    const idDapat = idFeedsHapus.current
    console.log(idDapat)
    if(idDapat === null) return
    try{
      await db.doc(`feeds/${idDapat}`).delete()
      setBukaHapus(false)
      refreshFeeds()
    }catch(e){
      toast.error("Tidak dapat menghapus feeds")
      console.error(e)
    }
  }

  useEffect(() => {
    if(feedTakValid){
      refreshFeeds()
    }
  }, [feedTakValid])
  
  return(
    <div className='ortu-forum-apl-animemo'>
      <ul className='list-ortu-forum'>
        {
          arOrtuForum.map((nilai, indeks) =>{
            if(indeks > 2) return <></>
            return <IsiOrtuForum 
              indeks={indeks} 
              indeksTerakhir={indeksOrtuForumTerakhir}
              refArTerakhir={refOrtuForumTerakhir}
              isi={nilai}
            />
        })}
        {
          arFeeds.map((nilai, indeks) => {
            if(indeks > 1) return <></>
            return <FeedsCard indeks={indeks} cacheLaman={cacheLaman} indeksTerakhir={indeksFeedsTerakhir} refArTerakhir={refFeedsTerakhir} 
            feeds={nilai} 
            hapusFeeds={(id) => {
              setBukaHapus(true)
              idFeedsHapus.current = id
            }}/>
          })
        }
        {
          arOrtuForum.map((nilai, indeks) =>{
            if(indeks <= 2) return <></>
            return <IsiOrtuForum 
              indeks={indeks} 
              indeksTerakhir={indeksOrtuForumTerakhir}
              refArTerakhir={refOrtuForumTerakhir}
              isi={nilai}
            />
        })}
        {
          arFeeds.map((nilai, indeks) => {
            if(indeks <= 1) return <></>
            return <FeedsCard feeds={nilai} indeks={indeks} cacheLaman={cacheLaman} indeksTerakhir={indeksFeedsTerakhir} 
            refArTerakhir={refFeedsTerakhir} 
            hapusFeeds={(id) => {
              setBukaHapus(true)
              idFeedsHapus.current = id
              console.log(idFeedsHapus.current)
            }}/>
          })
        }
      </ul>
      {loading && <LoadingLaman/>}
      <div className='fab-tambah-feeds'>
        <Link to={'/h/tambahkan-feeds' } onClick={() => cacheLaman()}>
          <Fab color='primary' >
            <IkonTambah />
          </Fab>
        </Link>
      </div>
      <DialogHapusUmum teks="anda yakin ingin menghapus feeds?"
      buka={bukaHapus} 
      handelBatal={() => setBukaHapus(false)} 
      handelOK={() => {handelHapusFeeds()}}
      />
    </div>
  )
}

export default ForumOrtu

interface IOrtuForum{}