export default class DataProvinsi{
  arrayProvinsi: string[];
  constructor(){
    this.arrayProvinsi = [
      "Aceh",
      "Sumatera Utara",
      "Sumatera Barat",
      "Riau",
      "Kepulauan Riau",
      "Jambi",
      "Sumatera Selatan",
      "Kepulauan Bangka Belitung",
      "Bengkulu",
      "Lampung",
      "DKI Jakarta",
      "Banten",
      "Jawa Barat",
      "Jawa Tengah",
      "DI Yogyakarta",
      "Jawa Timur",
      "Bali",
      "Nusa Tenggara Barat",
      "Nusa Tenggara Timur",
      "Kalimantan Barat",
      "Kalimantan Tengah",
      "Kalimantan Selatan",
      "Kalimantan Timur",
      "Kalimantan Utara",
      "Sulawesi Utara",
      "Gorontalo",
      "Sulawesi Tengah",
      "Sulawesi Barat",
      "Sulawesi Selatan",
      "Sulawesi Tenggara",
      "Maluku",
      "Maluku Utara",
      "Papua Barat",
      "Papua",
    ]
  }
}