import {FC, MutableRefObject, useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import {DataAktivitasPengguna, JenisAktivitas} from '../../../../Data/Pengguna';
import {terjemahkanAktivitas, terjemahkanWaktu} from './FungsiIsiAktivitas';
import './ItemIsiAktivitas.scss'
import firebase from 'firebase';

export const ItemIsiAktivitas: FC<IItemIsiAktivitas> = (prop) => {
  const storage = firebase.storage()
  const sejarah = useHistory()
  const handelKlik = () => {
    if(prop.aktivitas?.jenisAktivitas === JenisAktivitas.tambahAnime 
      || prop.aktivitas?.jenisAktivitas === JenisAktivitas.editAnime
      ){
      sejarah.push(`/a/${prop.aktivitas.idAnKom}`)
    }else if(prop.aktivitas?.jenisAktivitas === JenisAktivitas.tambakKomik 
      || prop.aktivitas?.jenisAktivitas === JenisAktivitas.editKomik){
      sejarah.push(`/k/${prop.aktivitas.idAnKom}`)
    }else if(prop.aktivitas?.jenisAktivitas === JenisAktivitas.forum){
      sejarah.push(`/h/forum-balasan/${prop.aktivitas.idForum}/terlama`)
    }else if(prop.aktivitas?.jenisAktivitas === JenisAktivitas.anakForum){
      sejarah.push(`/h/forum-balasan/${prop.aktivitas.idForum}/terlama`)
    }
  }
  const handelKlikGbr = (e: React.MouseEvent<HTMLImageElement, MouseEvent>) => {
    e.stopPropagation()
    sejarah.push(`/p/${prop.aktivitas?.idPengguna}`)
  }
  const [urlGbr, setUrlGbr] = useState<string | undefined>(undefined)
  useEffect(() => {
    const idPengguna = prop.aktivitas?.idPengguna
    const dapatkanFoto = async () => {
      if(idPengguna){
        try{
          const urlGbrDapat = await storage.ref('fotoProfil/fp' + idPengguna + 'Sedang').getDownloadURL()
          setUrlGbr(urlGbrDapat)
        }catch(e){
          if(e instanceof Error){
            console.log(e.message)
          }
        }
      }
    }
    prop.perluGbr && dapatkanFoto()
  }, [prop.aktivitas?.idPengguna])
  return(
    <li className='item-isi-aktivitas-profil' 
      onClick={handelKlik} 
      style={!(prop.perluGbr && urlGbr) ? {justifyContent: "center"} : {}}
      ref={(prop.indeks !== undefined && (prop.indeksTerakhir  === prop.indeks)) ? 
        prop.refAktivitasTerakhir : undefined}
    >
      {(prop.perluGbr && urlGbr) && <img src={urlGbr} className='gbr' onClick={handelKlikGbr}/>}
      <div className='kontainer-teks' >
        <h6 className='teks-isi-aktivitas-profil'>
          {prop.teksAktivitas}
        </h6>
        <div className='waktu-isi-aktivitas-profil'>
          {prop.teksWaktuAktivitas}
        </div>
      </div>
    </li>
  )
}

const IsiAktivitas: FC<IIsiAktivitas> = (prop) => {
  return(
    <ul className='isi-aktivitas-profil'>
      {prop.itemAktivitas?.map((nilai, kunci) => 
        {
          return (
            <ItemIsiAktivitas 
            perluGbr={prop.perluGbr === undefined ? false : prop.perluGbr}
            teksAktivitas={terjemahkanAktivitas(nilai)}
            teksWaktuAktivitas={terjemahkanWaktu(nilai)}
            aktivitas={nilai}
            key={kunci}/>
          )
        }
      )}
    </ul>
  )
}

export default IsiAktivitas

interface IIsiAktivitas {
  itemAktivitas?: Array<DataAktivitasPengguna>
  perluGbr?: boolean
}

interface IItemIsiAktivitas{
  teksAktivitas?: string;
  teksWaktuAktivitas?: string;
  aktivitas?: DataAktivitasPengguna;
  kunci?: number;
  perluGbr?: boolean;
  refAktivitasTerakhir?: MutableRefObject<HTMLLIElement | null>;
  indeksTerakhir?: number;
  indeks?: number;
}