import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cacheData } from "store/actions";
import { IRootRedux } from "store/reducers";



export function useCacheScroll(namaLaman: string){
    const scrollDapat = useSelector((state: IRootRedux) => state?.cacheData?.[`scroll_${namaLaman}`])
    const dispatch = useDispatch()
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo(0, scrollDapat ?? 0)
        }, 250)
        const handleScroll =  () => dispatch(cacheData(`scroll_${namaLaman}`, window.scrollY))
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [namaLaman])
}