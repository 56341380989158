import './Website.scss';
import '../navigasi/navigasiAtas/NavigasiAtas.scss';
import firebase from 'firebase';
import {useState, useEffect, useCallback, FC, Dispatch, SetStateAction} from 'react';
import {Navbar} from '../navigasi/navigasiAtas/NavigasiAtas';
import NavigasiKanan from '../navigasi/navigasiKanan/NavigasiKanan';
import {Switch, Route, Redirect, useLocation} from 'react-router-dom';
import {useAuthState} from 'react-firebase-hooks/auth';
import {Beranda} from './beranda/Beranda'
import {Anime} from './anime/Anime'
import {Komik} from './komik/Komik'
import {KomponenPengguna} from './profilPengguna/Pengguna'
import {AniRedirect} from './Redirect'
import TransisiModal from '../komponen/cari/ModalCari';
import LoginPengguna from './loginPengguna/LoginPengguna';
import {ThemeProvider} from '@material-ui/core';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {IPengguna} from '../Data/Pengguna';
import useDimensiLayar, {TOrienLayar} from '../fungsi/UseDimensiLayar'
import LamanMusiman from './musiman/Musiman'
import NavigasiBawah from 'navigasi/NavigasiBawah'
import DaftarAnimePengguna from './DaftarAnimePengguna/src/DaftarAnimePengguna';
import TambahkanAnime from './TambahkanAnime';
import Forum from './Forum';
import ForumOrtu from './ForumOrtu';
import ForumAnak from './ForumAnak';
import EditAkun from './EditAkun/EditAkun';
import useToastOnline from 'fungsi/useToastOnline'
import TambahkanForum from './TambahkanForum/src/TambahkanForum';
import AktivitasPengguna from './AktivitasPengguna';
import Pemberitahuan from './Pemberitahuan';
import useCekTema from 'css/useCekTema';
import TambahkanFeeds from './TambahkanFeeds';
import 'react-loading-skeleton/dist/skeleton.css'
import { SkeletonTheme } from 'react-loading-skeleton';
import KomentarFeeds from './KomentarFeeds/KomentarFeeds';
import useAuthAniMemo from './fungsi';

const Website: FC<IWebsite> = (p)  =>  {
  const {pengguna, dbPenggunaKosong, setDBPenggunaKosong, telahLogin} = useAuthAniMemo()
  useToastOnline()
  const [cari, setCari] = useState(false);
  const lokasiWeb = useLocation();
  const [_, temaDapat] = useCekTema()
  if(telahLogin && dbPenggunaKosong && !(lokasiWeb.pathname === '/h/login' || lokasiWeb.pathname === '/h/login/') ){
    return <Redirect to='/h/login'/>
  }else{
    return (
      <ThemeProvider theme={temaDapat}>
        <SkeletonTheme baseColor="#202020" highlightColor="#444">
          <div className="App">
            <Switch>
              <Route path="/survey" render={() => <div></div>}/>
              <Route path="*" render={() => <LamanUtama pengguna={pengguna} setCari={setCari} cari={cari} setDBPenggunaKosong={setDBPenggunaKosong}/>} />
            </Switch>
          </div>
          <ToastContainer 
            position="bottom-center"
            autoClose={3500}
            hideProgressBar={false}
            closeOnClick={true}
            pauseOnHover={true}
            draggable={true}
          />
        </SkeletonTheme>
      </ThemeProvider>
    );
  }
}

const LamanUtama: FC<ILamanUtama> = ({pengguna, setCari, cari, setDBPenggunaKosong}) => {
  const {lebarLayar} = useDimensiLayar()
  return(
    <>
      <div className="App-header">
        <Navbar 
          pengguna={pengguna}
          handelCari={() => setCari(true)}
          lebarLayar={lebarLayar}
        />
      </div>
      {lebarLayar > 1000 ?
        <NavigasiKanan pengguna={pengguna}/>
        :
        <NavigasiBawah/>
      }
      <div className="web-utama-animemo">
        <section className="konten-website-animemo">
          <TransisiModal
            handelCari={() => setCari(true)}
            handelCariTutup={() => setCari(false)}
            apakahCari={cari}
          />
          <Switch>
            <Route path="/" exact component = {Beranda} />
            <Route path="/instagram" exact render={(_p) => <AniRedirect lokasi='instagram'/>}/>
            <Route path="/download" exact render={(_p) => <AniRedirect lokasi='download'/>}/>
            <Route path="/iklanFB" exact render={(_p) => <AniRedirect lokasi='iklanFB'/>}/>
            <Route path="/facebook" exact render={(_p) => <AniRedirect lokasi='facebook'/>}/>
            <Route path="/a/:idAnime" render={(p) => <Anime {...p}/>} />
            <Route path="/k/:idKomik" component = {Komik} />
            <Route path="/p/:idPengguna" component = {KomponenPengguna} />
            <Route path="/h/login" exact render={(_p) => {return <LoginPengguna 
              setDBPenggunaKosong={setDBPenggunaKosong}/>}}
            />
            <Route path="/h/musiman/:idMusiman" component={LamanMusiman}/>
            <Route path="/h/daftar/:idDaftar" exact  component={DaftarAnimePengguna} />
            <Route path="/h/daftar/:idDaftar/:idPengguna" exact component={DaftarAnimePengguna} />
            <Route path="/h/tambahkan-anime/:idAnime" component={TambahkanAnime} />
            <Route path="/h/forum/:idOrtuForum/:slugTab" component={Forum} />
            <Route path="/h/forum-ortu" component={ForumOrtu} />
            <Route path="/h/forum-balasan/:idForum/:slugTab" component={ForumAnak} />
            <Route path="/h/edit-akun" component={EditAkun} />
            <Route path="/h/tambahkan-forum/:idOrtuForum" component={TambahkanForum} />
            <Route path="/h/aktivitas/:idPengguna" component={AktivitasPengguna} />
            <Route path="/h/pemberitahuan" component={Pemberitahuan} />
            <Route path="/h/tambahkan-feeds" component={TambahkanFeeds} />
            <Route path="/h/komentar-feeds/:idFeeds" component={KomentarFeeds} />
          </Switch>
        </section>
      </div>
    </>
  )
}

interface ILamanUtama{
  pengguna: IPengguna | null,
  setCari: Dispatch<SetStateAction<boolean>>,
  cari: boolean ,
  setDBPenggunaKosong: Dispatch<SetStateAction<boolean>>,

}

export default Website



interface IWebsite {}