import { MutableRefObject, RefObject, useCallback, useEffect, useRef, useState } from "react"
import {IDataAnime} from "Data/Anime"
import firebase from "firebase"
import store from "store"
import { cacheLaman } from "store/actions"
import { useSelector } from "react-redux"
import { IRootRedux } from "store/reducers"
import ar from "date-fns/esm/locale/ar/index.js"

/**
 * 
 * @param dokumenPerLaman 
 * @param query 
 * @param namaLaman nama laman untuk disimpan dalam cache
 * @param tab untuk laman dengan beberapa tab, dimonitor oleh useEffect, agar ref selalu update
 * @param tabKomponen tab komponen saat ini
 * @returns [dokumenDapat, refDataTerakhir, indeksDataTerakhir, loading, dataTerakhirDalamLayar, cacheLamanDapat, refreshData]
 */
function useFetchFirestore<IData>(
  dokumenPerLaman: number,
  query: firebase.firestore.Query,
  namaLaman?: string,
  tab?: number,
  dokumenPerLamanPertama?: number, 
  dataMaks?: number,
  tabKomponen?: number,
  dependency?: any[]
): [IData[], MutableRefObject<HTMLLIElement | null>, number, boolean, boolean, () => void, () => void]{
  const [dokumenDapat, setDokumenDapat] = useState<IData[]>([])
  const [loading, setLoading] = useState(false)
  const [ssDokumenTerakhir, setSsDokumenTerakhir] 
  = useState<firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData> | null>(null)
  const [akhirDokumen, setAkhirDokumen] = useState(false)
  const [indeksDataTerakhir, setIndeksDataTerakhir] = useState(-1)
  const refDataTerakhir = useRef<HTMLLIElement | null>(null)
  const dataTerakhirDalamLayar = useDalamLayarTab(refDataTerakhir, tab || 0, tabKomponen, dependency)

  const dapatkanCache = useSelector((state: IRootRedux) => state.cacheLaman?.[namaLaman || "kosong"])

  const cacheLamanDapat = useCallback(() => { 
    if(dokumenDapat && ssDokumenTerakhir && (indeksDataTerakhir !== null || indeksDataTerakhir !== undefined)){
      const cacheLamanData = {
        dokumenDapat: dokumenDapat,
        ssDokumenTerakhir: ssDokumenTerakhir,
        akhirDokumen: akhirDokumen,
        indeksDataTerakhir: indeksDataTerakhir,
        perluRefresh: false
      }
      
      if(namaLaman){
        store.dispatch(cacheLaman(namaLaman, cacheLamanData, window.scrollY))
      }
    }
  }, [dokumenDapat, ssDokumenTerakhir, akhirDokumen, indeksDataTerakhir, refDataTerakhir])

  const dapatkanData = async (paksa?: boolean) => {
    const kurangDariDataMaks = (dataMaks !== undefined && dokumenDapat.length < dataMaks) || dataMaks === undefined
    const cacheTidakKosong = dapatkanCache !== null && dapatkanCache !== undefined 
    if((!akhirDokumen && kurangDariDataMaks) || paksa){
      setLoading(true)
      const tabSudahBenar = (tabKomponen === undefined) || (tabKomponen === tab)
      if((ssDokumenTerakhir === null && tabSudahBenar && !cacheTidakKosong) || paksa){
        try{
          const ss = await (dokumenPerLamanPertama ? query.limit(dokumenPerLamanPertama).get() : query.limit(dokumenPerLaman).get())
          if(ss.empty){
            setAkhirDokumen(true)
          }else{
            const terakhirTerlihat = ss.docs[ss.docs.length - 1]
            const arDokumen: IData[] = []
            if(paksa){
              setDokumenDapat([])
            }

            for(const doc of ss.docs){
              const ssDok: any = doc.data()
              const dokumen: IData = ssDok
              arDokumen.push(dokumen)
            }
            setDokumenDapat(arDokumen)
            setIndeksDataTerakhir(arDokumen.length - 1)
            setSsDokumenTerakhir(terakhirTerlihat)
          }
        }catch(e){
          const er = e as Error
          console.log(er)
        }
      }else if(ssDokumenTerakhir === null 
        && tabSudahBenar 
        && dapatkanCache !== null 
        && dapatkanCache !== undefined 
        && (dapatkanCache.jenisLaman === namaLaman)){
        const dataCache: IData[] = dapatkanCache.data.dokumenDapat
        const scrollDapat = dapatkanCache.scroll
        const dokTerakhirCache = dapatkanCache.data.ssDokumenTerakhir
        const indeksTerakhirDapatCache = dapatkanCache.data.indeksDataTerakhir
        setDokumenDapat(dataCache)
        setSsDokumenTerakhir(dokTerakhirCache)
        setIndeksDataTerakhir(indeksTerakhirDapatCache)
        setAkhirDokumen(dapatkanCache.data.akhirDokumen)
        setTimeout(() => {
          window.scrollTo(0, scrollDapat)
        }, 300)
      }else if(tabSudahBenar){
        const ss = await query.limit(dokumenPerLaman).startAfter(ssDokumenTerakhir).get()
        if(ss.empty){
          setAkhirDokumen(true)
        }else{
          const terakhirTerlihat = ss.docs[ss.docs.length - 1]
          const arDokumen: IData[] = dokumenDapat
          for(const doc of ss.docs){
            const ssDok: any = doc.data()
            const dokumen: IData = ssDok
            arDokumen.push(dokumen)
          }
          setDokumenDapat(arDokumen)
          setIndeksDataTerakhir(arDokumen.length - 1)
          setSsDokumenTerakhir(terakhirTerlihat)
        }
      }
      setLoading(false)
    }
  }

  const dapatkanDataSelanjutnya = useCallback(async () => {
    await dapatkanData()
  }, [dataTerakhirDalamLayar])

  const refreshData = useCallback(async () => {
    dapatkanData(true)
  }, [])

  useEffect(() => {
    cacheLamanDapat()
  }, [cacheLamanDapat])

  useEffect(() => {
    if(dapatkanCache?.data.perluRefresh){
      dapatkanData(true)
    }
  }, [dapatkanCache?.data.perluRefresh])


  useEffect(() => {
    //agar value dari dependency tidak kosong/undefined
    const cek = (ar: any[]) => ar.every(v => (v !== undefined && v !== null))
    dependency && cek(dependency) && dapatkanData()
    const dependencyKosong = dependency === null || dependency === undefined 
    dependencyKosong && dapatkanData()
  }, (dependency?.push(tab) ? dependency : [tab]))

  //dapatkan data selanjutnya setelah
  useEffect(() => {
    if(dataTerakhirDalamLayar){
      dapatkanDataSelanjutnya()
    }
  }, [dapatkanDataSelanjutnya])

  return [dokumenDapat, refDataTerakhir, indeksDataTerakhir, loading, dataTerakhirDalamLayar
    , cacheLamanDapat
    , refreshData]
}

function useDalamLayarTab(ref: RefObject<HTMLElement>, tabBenar: number, tabSaatIni?: number, dependency?: any[]) {

  const [adaInterseksi, setAdaInterrseksi] = useState(false)

  const observer = new IntersectionObserver(
    ([entry]) => setAdaInterrseksi(entry.isIntersecting)
  )

  useEffect(() => {
    const tabSudahBenar = (tabSaatIni === undefined) || (tabSaatIni === tabBenar)
    if(ref.current !== null && ref.current !== undefined && tabSudahBenar){
      observer.observe(ref.current)
      return () => { observer.disconnect() }
    }
  }, [ref.current, tabBenar, tabSaatIni])

  return adaInterseksi
}



export default useFetchFirestore