import React, {memo} from 'react';
import {IIndeksList, IIndeks, IIndeksSatuan} from '../../Data/IndeksAnime';
import './BarCari.scss';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import * as fHooks from 'react-firebase-hooks/firestore';
import firebase from 'firebase';
import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader';
import { css } from '@emotion/react'
import {Link} from 'react-router-dom';
import {detect} from 'detect-browser'
import { useStopwatch } from 'react-timer-hook';

const BarCari: React.FC<IBarCari> = (prop) => {
  const browser = detect();

  const indeksList: IIndeksList = {dataSatuan: []}

  const [arrayLoading, setArrayLoading] = React.useState<Array<boolean>>([])
  const [apakahLoading, setApakahLoading] = React.useState<boolean>(false)
  const [indeksTerfilter, setIndeksTerfilter] = React.useState<Array<IIndeksSatuan>>([])
  const [teksCariDapat, setTeksCariDapat] = React.useState<string>('')
  const {
    seconds,
  } = useStopwatch({ autoStart: true });

  React.useEffect(() => {
    cekLoad();
  }, [JSON.stringify(arrayLoading)] );
  
  const cekLoad = () => {
    setApakahLoading(!(arrayLoading.every(v => v==false)))
  }

  ambilIndeks(arrayLoading, indeksList)

  const handelFilter = async (event: React.FormEvent<HTMLInputElement>) => {
    setTeksCariDapat(event.currentTarget.value)
    const teksCari = event.currentTarget.value.toLowerCase();
    var filterBaru = indeksList.dataSatuan.filter((indeks)=>{
      return indeks.seluruhJudul.toLowerCase().includes(teksCari)
    }) || [];
    if(teksCari.length == 0){filterBaru = []}
    filterBaru = filterBaru.slice(0, 20);
    setIndeksTerfilter(filterBaru);
  }

  const hapusInput = () => {
    setIndeksTerfilter([])
    setTeksCariDapat('')
  }

  //komponen
  const inputCari = (
    <div className="inputCari">
      <input type="text" 
      placeholder="Cari anime" 
      value = {teksCariDapat}
      onChange={handelFilter}
      />
      <div className="ikonCari">
        {teksCariDapat.length === 0 ? <SearchIcon/> : 
        <CloseIcon id="tblBersihkan" onClick={hapusInput}/>}
      </div>
    </div>
  )

  return(
    <div className="cari">
      {(true) ? ((!apakahLoading) ? (inputCari) :
      (<div className="loadingCari">
          <ClimbingBoxLoader css={cssLoading}/>
          <div className="teksLoadingCari">
            {
            (browser?.name === 'firefox') ?
            <p>tunggu sebentar {seconds.toString()}</p>
            :
            <p>tunggu sebentar</p>
            }
          </div>
        </div>
        ))
      : 
       (inputCari)
      }
      {((indeksTerfilter.length != 0) && (teksCariDapat.length != 0)) && (
        <HasilCari
        indeksTerfilter={indeksTerfilter}
        handelTutup={prop.handelTutup}
        />
      )}
    </div>
  )
}

const HasilCari: React.FC<IHasilCari> = (p) => {
  return(
    <div className="hasilIndeks">
      {
      p.indeksTerfilter.map((nilai, kunci) => {
        return(
          <Link to={'/a/' + encodeURIComponent(nilai.idAnimeCari)
          }
          style={{ textDecoration: 'none' }}
          key={kunci}>
            <div className="itemIndeks"
            onClick={p.handelTutup}>   
              <p>{nilai.judulAnime}</p>
            </div>
          </Link>
        )
      })}
    </div>
  )
}

export default BarCari

async function ambilIndeks(arrayLoading: Array<boolean>, indeksList: IIndeksList){
  for (let i=0; i<=15; i++){
    if(i === 0){indeksList.dataSatuan = []
      arrayLoading[0] = false
    }else{downloadIndeks(i,arrayLoading, indeksList)}
  }
  indeksList.dataSatuan.sort(bandingkanIndeks)
}

async function downloadIndeks(i: number, arrayLoading: Array<boolean>, indeksList: IIndeksList){
  const db = firebase.firestore()
  const [ss, loading, er] = fHooks.useDocument(
    db.collection('nilaiServer').doc('indeksAnimeCari' + i.toString())
  );
  const dokSs: any = ss?.data()
  
  const indeks: IIndeks = dokSs
  if (indeks != null || indeks != undefined){
    for (let x in indeks.idAnimeCari){
      if (Number(x) !== 0){
        const indeksSatuan ={
          idAnimeCari: indeks.idAnimeCari[x],
          seluruhJudul: indeks.seluruhJudul[x],
          memberMal: indeks.memberMal[x],
          jumlahReview: indeks.jumlahReview[x],
          judulAnime: indeks.judulAnime[x],
        } as IIndeksSatuan
        indeksList.dataSatuan.push(indeksSatuan)
      }
    }
  }
  arrayLoading[i] = loading
}

const cssLoading = css`
  display: block;
  margin: 0 auto;
  border-color: red;
  display:grid;

`;

function bandingkanIndeks(a: IIndeksSatuan,b: IIndeksSatuan){
  const selisihJR = b.jumlahReview - a.jumlahReview
  const selisihMal = b.memberMal - a.memberMal
  if(selisihJR === 0){
    
    return selisihMal
  }else{
    return selisihJR
  }
}

interface IBarCari{
  teksPlaceH?: string;
  dataDicari?: Array<IIndeksSatuan>;
  handelTutup?: () => void;
}

interface IHasilCari{
  indeksTerfilter: IIndeksSatuan[],
  teksCariDidapat?: string,
  handelTutup?: () => void;
}