import { useCallback } from "react";
import {DataForum} from "Data/DataForum"
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from 'firebase';
import { toast } from "react-toastify";

const useHapusForum = () => {
  const [auth] = useAuthState(firebase.auth())
  const hapusAnakForum = useCallback(async (forum: DataForum | null) => {
    if(auth && forum && auth.uid === forum.idPengguna){
      toast.dark("menghapus forum", {position: "top-center", autoClose: 200})
      try{
        const refKecil = firebase.storage().ref().child(`fotoForum/Forum${forum.idForum}Kecil`)
        const refBesar = firebase.storage().ref().child(`fotoForum/Forum${forum.idForum}Sedang`)
        await refKecil.delete()
        await refBesar.delete()
      }catch(e){

      }
      try{
        await firebase.firestore().doc(`forumPengguna/${forum.idForum}`).delete()
        toast.success("forum telah dihapus silahkan refresh", {position: "top-center"})
        return true
      }catch(e){
        const er = e as Error
        toast.error("forum tidak dapat dihapus", {position: "top-center"})
        return false
      }
    }else{
      return false
    }
  }, [auth])
  return hapusAnakForum
}

export default useHapusForum