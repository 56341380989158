import {FC, useState, useRef, useEffect, ChangeEvent, memo} from 'react'
import { Dialog, DialogTitle, DialogContent, Checkbox
  , FormGroup, Button, DialogActions, FormControlLabel} from '@material-ui/core';
import './InputCekBoks.scss'

const InputCekBoks: FC<IInputCekBoks> = (p) => {
  const [buka, setBuka] = useState(false)
  const [teksTerpilih, setTeksTerpilih] = useState(p.pilihanKosongString || 'pilih')

  const handelBuka = () => {setBuka(true)}

  const onTutup = (arTerpilih: Array<boolean>) => {
    p.onTerpilih(arTerpilih);
    if(!(arTerpilih).every((v: boolean) => v === false)){
      let arTerpilihString: Array<string> = []
      for (let i = 0; i < p.arTerpilih.length; i++){
        if(arTerpilih[i]){
          arTerpilihString.push(p.isiPilihan[i])
        }
      }
      const stringPilihan = arTerpilihString.join(', ')
      setTeksTerpilih(stringPilihan)
      if(p.setStringTerpilih != null){
        p.setStringTerpilih(arTerpilihString)
      } 
      p.setErTeksTerpilih(false)
    }else{
      setTeksTerpilih(p.pilihanKosongString || 'pilih')
      p.setErTeksTerpilih(true)
    }
    setBuka(false)
  }

  const dependency: any[] = p.arTerpilih

  useEffect(() => {
    const genreTerpilihStringAr: string[] = []
    for(let i = 0; i < p.arTerpilih.length; i++){
      console.log("coba5")
      console.log(p.arTerpilih[i])
      if(p.arTerpilih[i]){
        genreTerpilihStringAr.push(p.isiPilihan[i])
      }
    }
    
    p.setStringTerpilih && p.setStringTerpilih(genreTerpilihStringAr)
    const stringPilihan = genreTerpilihStringAr.length > 0 ? genreTerpilihStringAr.join(', ') : (p.pilihanKosongString || "pilih")
    setTeksTerpilih(stringPilihan)
  }, dependency.concat(p.isiPilihan))

  return(
    <div>
      <div className='kontainer-teks-input-cek-boks'>
        {!(p.arTerpilih).every((v: boolean) => v == false) ? 
          <div className='teks-input-cek-boks-terisi' onClick={handelBuka}>{teksTerpilih}</div>
        :
          <div className='teks-input-cek-boks' onClick={handelBuka}>{teksTerpilih}</div>
        }
      </div>
      <DialogInputCekBoks
        judulPilihan={p.judulPilihan || ''}
        isiPilihan={p.isiPilihan}
        arTerpilih={p.arTerpilih}
        buka={buka}
        onTutup={onTutup}
      />
    </div>
  )
}
const DialogInputCekBoks: FC<IDialogInputCekBoks> = (p) => {

  const refRadioGrup = useRef<HTMLElement>(null);
  const [terpilih, setTerpilih] = useState(p.arTerpilih)

  useEffect(() => {
    if (!p.buka) {
      setTerpilih(p.arTerpilih);
    }
  }, [p.arTerpilih, p.buka]);

  const handelMasukNilai = () => {
    if (refRadioGrup.current != null) {
      refRadioGrup.current.focus()
    }
  }

  const handelOk = () => {
    p.onTutup(terpilih)
  }
  const handelBatal = () => {p.onTutup(p.arTerpilih)}

  const handelPerubahan = (event: ChangeEvent<HTMLInputElement>) => {
    const terpilihGanti = () => {
      const terpilihGanti: Array<boolean> = []
      for(let i = 0; i < p.arTerpilih.length; i++){
        terpilihGanti.push(terpilih[i])
      }
      return terpilihGanti
    }
    const terpilihFinal = terpilihGanti()
    terpilihFinal[Number(event.target.name)] = event.target.checked
    setTerpilih(terpilihFinal);
  };

  return(
    <Dialog
      maxWidth="xs"
      onEntering={handelMasukNilai}
      aria-labelledby="confirmation-dialog-title"
      open={p.buka}
      keepMounted
    >
      <DialogTitle id="confirmation-dialog-title">{p.judulPilihan}</DialogTitle>
      <DialogContent dividers>
        <FormGroup>
          {p.isiPilihan.map((pilihan, kunci) => (
            <FormControlLabel 
            control={<Checkbox 
              key={kunci}
              checked={terpilih[kunci]}
              onChange={handelPerubahan}
              name={kunci.toString()}
            />} 
            label={pilihan} />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handelBatal} color="primary">
          Batal
        </Button>
        <Button onClick={handelOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

interface IInputCekBoks{
  judulPilihan?: string;
  isiPilihan: Array<string>;
  arTerpilih: Array<boolean>;
  pilihanKosongString?: string;
  onTerpilih: (arTerpilih: Array<boolean>) => void;
  setStringTerpilih?: (strTerpilih: Array<string>) => void;
  setErTeksTerpilih: (eror: boolean) => void
}

interface IDialogInputCekBoks{
  judulPilihan: string;
  isiPilihan: Array<string>;
  arTerpilih: Array<boolean>;
  buka: boolean;
  onTutup: (arTerpilih: Array<boolean>) => void;
}

export default InputCekBoks