import { IDataAnime } from 'Data/Anime';
import {Dispatch, FC, MutableRefObject, RefObject} from 'react';
import './IsiMusiman.scss';
import PeopleIcon from '@material-ui/icons/People';
import StarIcon from '@material-ui/icons/Star';
import { useLocation } from 'react-router';

import {Link} from 'react-router-dom'
import useDimensiLayar from 'fungsi/UseDimensiLayar';

const IsiMusiman: FC<IIsiMusiman> = (p) => {
  const {lebarLayar, tinggiLayar} = useDimensiLayar()
  const bukaTanpaModal = p.bukaDgnModal === undefined ? true : !p.bukaDgnModal 

  const isiList = (        
    <div className='list-anime-musiman' onClick={() => {
      p.cacheLaman && p.cacheLaman()
      if(!bukaTanpaModal){
        p.setIdAnimeModal && p.setIdAnimeModal(p.anime?.idAnime || null)
      }
    }}>
      <div className='kontainer-gbr'>
        <img 
          className='gbr' 
          src={(lebarLayar > 500 ? p.anime?.urlFotoAnime : p.anime?.urlFotoAnimeSedang) || undefined}  
        />
        <div className='statistik-cepat'>
          <div className='gbr'>
            < StarIcon />
          </div>
          <h6 className='isi'>
            {(p.anime?.skor ?? -1) > 0 ? p.anime?.skor?.toFixed(2) : "?"}
          </h6>
          <div className='gbr'>
            < PeopleIcon />
          </div>
          <h6 className='isi'>
            {p.anime?.jumlahReview ? p.anime?.jumlahReview : 0}
          </h6>
        </div>
      </div>
      <h4 className='judul-komponen-musiman'>
        {p.anime?.judulAnime}
      </h4>
      <h6 className='genre-komponen-musiman'>
        {p.anime?.genre?.join(", ")}
      </h6>
    </div>
  )
  return(
    <li 
      ref={p.indeks === p.animeTerakhirLoad ? p.refAnimeTerakhir : undefined} 
      key={p.indeks}>
      {bukaTanpaModal ? 
      <Link style={{textDecoration:"none"}} to={'/a/' + encodeURIComponent(p.anime?.idAnime || "")}
      >
        {isiList}
      </Link>
      :
        isiList
      }
    </li>
  )
}

export default IsiMusiman

interface IIsiMusiman{
  anime?: IDataAnime;
  indeks: number;
  animeTerakhirLoad?: number;
  refAnimeTerakhir?: RefObject<HTMLLIElement>;
  cacheLaman?: () => void
  bukaDgnModal?: boolean
  setIdAnimeModal?: Dispatch<string | null>
}