import useCekOnline from "./useCekOnline"
import {toast} from 'react-toastify';
import { useEffect } from "react";

const useToastOnline = () => {
  const [online, pertama] = useCekOnline()
  useEffect(() => {if(!pertama){
    online ? 
      toast.success("online, silahkan refresh") 
    : 
      toast.error("anda offline")
  }}, [online])
}

export default useToastOnline