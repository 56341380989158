import firebase from "firebase";
import { IAksi } from ".";


export function cacheDataReducer(state: any = null, action: IAksi){
  switch(action.type){
    case 'CACHE_DATA':
      return {
        ...state,
        ...action.payload
      }
    default:
      return state 
  }
}