import './Pengguna.scss';

import {FC, useEffect, useRef, useState} from 'react';
import firebase from "firebase";
import {IPengguna} from '../../Data/Pengguna';
import ItemStatCepat from './komponenProfil/statistikCepat/ItemStatCepat'
import ItemInfoCepat from './komponenProfil/infoCepat/ItemInfoCepat'
import WcIcon from '@material-ui/icons/Wc';
import CakeIcon from '@material-ui/icons/Cake';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import DataProvinsi from '../../Data/DataProvinsi'
import DataGender from '../../Data/DataGender'
import useDimensiLayar from '../../fungsi/UseDimensiLayar';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import {DataAktivitasPengguna} from '../../Data/Pengguna';
import IsiAktivitas from './komponenProfil/isiAktivitas/ItemIsiAktivitas';
import { RouteComponentProps } from 'react-router-dom';
import Tombol from '@material-ui/core/Button';
import {useAuthState} from 'react-firebase-hooks/auth';
import IkonFotoProfil from '@material-ui/icons/AccountCircle';
import { useHistory } from 'react-router';
import KeKoleksiPengguna from './komponenProfil/KeKoleksiPengguna'
import useFetchIkutDiikuti from './fungsi/useFetchIkutDiikuti';
import useIkutDiikuti from './fungsi/useIkutDiikuti'
import LoadingLaman from 'laman/loadingLaman/LoadingLaman';
import CircleLoader from "react-spinners/CircleLoader"

export const KomponenPengguna: FC<IKomponenPengguna> = (prop) =>  {
  const refDivKosong = useRef<HTMLDivElement | null>(null)
  const db = firebase.firestore()
  const queryAktivitas = db.collection('aktivitasPengguna')
  .where('idPengguna', '==', prop.match.params.idPengguna)
  .orderBy('waktuAktivitas', 'desc')
  .limit(8)
  const sejarah = useHistory()

  const [auth, loadingAuth, erorAuth] = useAuthState(firebase.auth())
  const [telahLogin, setTelahLogin] = useState(false)
  const [pemilikProfil, setPemilikProfil] = useState(false)

  const [apakahHorizontal, setApakahHorizontal] = useState(true)
  const {lebarLayar, tinggiLayar} = useDimensiLayar()
  const [aktPengguna, loading, eror] = useCollectionData<DataAktivitasPengguna>(queryAktivitas)

  useEffect(() =>{
    if(lebarLayar>tinggiLayar){
      setApakahHorizontal(true)
    }else{
      setApakahHorizontal(false)
    }
  }, [lebarLayar, tinggiLayar])

  useEffect(() => {
    fetchPengguna();
  }, [prop.match.params.idPengguna]);

  const[pengguna, setPengguna] = useState<IPengguna | undefined>(undefined);
  const fetchPengguna = async () => {
    const penggunaDapat = await dapatkanPengguna(prop.match.params.idPengguna)
    setPengguna(penggunaDapat)
  };

  useEffect(() => {
    if(auth != null  && pengguna != null){
      if(auth.uid == pengguna.uid){
        setPemilikProfil(true)
      }else{
        setPemilikProfil(false)
      }
    }
    if(auth != null){setTelahLogin(true)}else{setTelahLogin(false)}
  }, [auth, pengguna])

  const handleLogout = () => {
    firebase.auth().signOut()
  }

  const handelEditAkun = () => {
    sejarah.push('/h/edit-akun')
  }

  const bulan = ['Jan','Feb','Mar','Apr','Mei','Jun','Jul','Agu','Sep','Okt','Nov','Des'];
  useEffect(() => {refDivKosong.current?.scrollIntoView(); console.log("gak bisa")}, [pengguna, aktPengguna])
  const [mengikuti, profilSendiri, loadingMengikuti] = useFetchIkutDiikuti(prop.match.params.idPengguna)
  const [handelIkutDiikuti, tblAktif] = useIkutDiikuti(mengikuti, prop.match.params.idPengguna, pengguna?.namaPengguna)

  return (
    <div className="konten-profil">
      <div ref={refDivKosong}/>
      <div className="header-profil">
        <div className='kontainer-gambar-profil'>
          { pengguna?.urlFotoProfilPengguna === undefined || pengguna?.urlFotoProfilPengguna === null ? 
            <IkonFotoProfil style={{height:'100%', width: '100%'}}/>
          :
            (<img src={pengguna?.urlFotoProfilPengguna || undefined} 
              className={apakahHorizontal ? 'gambar-profil' : 'gambar-profil-vertikal'}
              alt="Foto Pengguna" />)
          }     
        </div>
        <div className="isi-profil">
          <div className='nama-pengguna-profil'>
            <h2>
              {pengguna?.namaPengguna || '-'}
            </h2>
          </div>
          <div className='info-cepat-profil'>
            <ItemInfoCepat
              gambar={WcIcon}
              isiInfo={(new DataGender).arrayGender[pengguna?.genderPengguna || 0]}
            />
            <ItemInfoCepat
              gambar={CakeIcon}
              isiInfo={(new Date((pengguna?.tanggalLahirPengguna?.seconds || 0)*1000)).getDate() + ' ' 
              + bulan[(new Date((pengguna?.tanggalLahirPengguna?.seconds || 0)*1000)).getMonth()]}
            />
            <ItemInfoCepat
              gambar={LocationOnIcon}
              isiInfo={(new DataProvinsi).arrayProvinsi[pengguna?.lokasiAsal || 0]}
            />
          </div>
        </div>
      </div>
      <h6 className='tentang-pengguna-profil'>
        {'tentang ' + (pengguna?.namaPengguna || '-') + ': "' + (pengguna?.tentangPengguna || '-') + '"'}
      </h6>
      <div className='stat-cepat'>
        <ItemStatCepat 
          keterangan='Hari Nonton' 
          angka={(((pengguna?.jumlahNontonMenit || -1) >= 0) ? ((pengguna?.jumlahNontonMenit || 0)/(60*24)) : null)} 
          angkaBelakangKoma={2}/>
          
        <ItemStatCepat 
          keterangan='Pengikut' 
          angka={(((pengguna?.pengikut || 0) >= 0) ? pengguna?.pengikut : null)}
          />
        <ItemStatCepat 
          keterangan='Mengikuti' 
          angka={(((pengguna?.mengikuti || 0) >= 0) ? pengguna?.mengikuti : null)}
          />
      </div>
      {(telahLogin && pemilikProfil) && 
      <>
        <div className='kontainer-tombol-logout'
          >
          <Tombol 
          color='primary'
          variant='contained' onClick={handleLogout}>
            Logout
          </Tombol>
        </div>
        <div className='kontainer-tombol-logout'
          >
          <Tombol 
          color='primary'
          variant='contained' onClick={handelEditAkun}>
            Edit Akun
          </Tombol>
        </div>
      </>
      }
      {!profilSendiri && <div className='kontainer-tombol-logout'
          >
          <Tombol 
          color={tblAktif ? 'primary' : 'secondary'}
          variant='contained' onClick={tblAktif ? handelIkutDiikuti : undefined}>
            {tblAktif ? (mengikuti ? "Mengikuti" : "Ikuti") : <CircleLoader color='red'/>}
          </Tombol>
        </div>}
      <KeKoleksiPengguna teks='Lihat koleksi anime pengguna' href={'/h/daftar/nonton/' + prop.match.params.idPengguna}/>
      <IsiAktivitas
        itemAktivitas={aktPengguna}
      />
      <button 
        className='tbl-lihat-aktivitas'
        onClick={() => {sejarah.push(`/h/aktivitas/${prop.match.params.idPengguna}`)}}
        >
        Aktivitas saya
      </button>
      <div className='prompt-download-animemo-profil'>
        <h3>
          berapa banyak anime yang sudah kamu tonton? cari tahu di <a
          className="App-link"
          href="https://play.google.com/store/apps/details?id=com.animemodev.animemo"
          target="_blank"
          rel="noopener noreferrer"
        >
          aplikasi AniMemo
        </a>
        </h3>
      </div>
    </div>
  );
}

async function dapatkanPengguna(idPengguna?: string){
  const db = firebase.firestore()
  const kolRefPengguna = db.collection('pengguna')
  const dokPengguna: any = (await kolRefPengguna.doc(idPengguna || '').get()).data()
  const pengguna: IPengguna = dokPengguna
  return pengguna
}

interface IKomponenPengguna extends RouteComponentProps<{idPengguna: string}>{}

