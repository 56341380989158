import { dataKataKotor } from "../Data/DataKataKotor"
import {toast} from "react-toastify"


export default function cekKataKotor(paragrafDidapat?: string, editText?: string): Boolean{

  let paragraf = paragrafDidapat
  const vokal = ["a", "i", "u", "e", "o"]
  paragraf = paragraf?.replace("."," ")
  paragraf = paragraf?.replace(","," ")
  paragraf = paragraf?.replace("1","i")
  paragraf = paragraf?.replace("13","b")
  paragraf = paragraf?.replace("|","i")
  paragraf = paragraf?.replace("!","i")
  paragraf = paragraf?.replace("3","e")
  paragraf = paragraf?.replace("4","a")
  paragraf = paragraf?.replace("@","a")
  paragraf = paragraf?.replace("5","s")
  paragraf = paragraf?.replace("$","s")
  paragraf = paragraf?.replace("7","t")
  paragraf = paragraf?.replace("0","o")
  paragraf = paragraf?.replace("9","g")
  paragraf = paragraf?.replace(/(\w)\1+/g, (_, match) => match[0])


  for (let i = 0; i < dataKataKotor.length; i++) {
    if (paragraf && paragraf.includes(dataKataKotor[i])) {
      var kataKotorSensor: string = dataKataKotor[i]
      for (let j = 0; j < vokal.length; j++) {
        kataKotorSensor = kataKotorSensor.replace(vokal[j], "*")
      }
      toast.dark(`mohon maaf ${editText || "kata-kata"} anda mengandung kata kasar: ${kataKotorSensor}`
      , {position: "top-center"})
      return true
    }
  }
  return false
}