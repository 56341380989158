import { FC } from "react"
import {IKoleksiPengguna} from "Data/KoleksiPengguna"
import "./IsiReviewAnime.scss"
import firebase from "firebase"
import hitungWaktu from "fungsi/hitungWaktu"
import { useDocumentDataOnce } from "react-firebase-hooks/firestore"
import { IPengguna } from "Data/Pengguna"
import { useHistory } from "react-router-dom"

const IsiReviewAnime: FC<IIsiReviewAnime> = (p) => {
  const [pengguna] 
    = useDocumentDataOnce<IPengguna>(firebase.firestore().doc(`pengguna/${p.review.idPengguna}`))
  const sejarah = useHistory()

  const handelKeProfil = () => {sejarah.push(`/p/${p.review.idPengguna}`)}
  return(
    <li className="review-pengguna-apl-animemo">
      <div className="foto-nama">
        <img src={pengguna?.urlFotoProfilKecilPengguna || undefined} onClick={handelKeProfil}/>
        <div className="nama-waktu">
          <h6 className="nama">
            {pengguna?.namaPengguna}
          </h6>
          <h6 className="waktu">
            {hitungWaktu(p.review.tanggalReview.seconds || null)}
          </h6>
        </div>
      </div>
      <h6 className="review">
        {p.review.reviewPengguna}
      </h6>
      <div className="skor-pengguna">
        Skor: {p.review.skorPengguna}/10
      </div>
      {p.review.membantu != null && <div className="ulasan-membantu">
        {p.review.membantu} orang menganggap ini membantu
      </div>}
    </li>
  )
}

interface IIsiReviewAnime{
  review: IKoleksiPengguna
}

export default IsiReviewAnime