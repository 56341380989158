import {FC} from 'react'
import './SkorAnime.scss'

const SkorAnime: FC<ISkorAnime> = (p) => {
  return(
    <div className='kontainer-skor-anime'>
      <div className='skor-anime'>
        {(p.skorAnime ?? 0) >= 1 ? p.skorAnime?.toFixed(2) : '?'}
      </div>
      <div className='teks-skor-anime'>
        {p.teks || ''}
      </div>
    </div>
  )
}

export default SkorAnime

interface ISkorAnime{
  skorAnime?: number | null;
  teks?: string 
}