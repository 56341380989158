import { ChangeEvent, Dispatch, FC, memo, SetStateAction, useEffect, useState } from "react";
import './TambahkanAnime.scss';
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import firebase from "firebase";
import { IDataAnime } from "Data/Anime";
import IkonPlus from '@material-ui/icons/ControlPoint';
import { Chip, IconButton, TextField } from "@material-ui/core";
import InputTanggal from "komponen/input/inputTanggal/InputTanggal";
import { indeksStatusNonton } from "laman/DaftarAnimePengguna/src/DaftarAnimePengguna";
import cekNumerik from 'fungsi/cekNumerik'
import { waktuRealtime } from "fungsi/fungsiWaktu";
import { useAuthState } from "react-firebase-hooks/auth";
import { RouteComponentProps, useHistory } from "react-router";
import LoadingLaman from 'laman/loadingLaman/LoadingLaman';
import IkonMinus from '@material-ui/icons/IndeterminateCheckBox';
import InputTeks from "komponen/input/inputTeks/InputTeks";
import InputUmum from "komponen/input/inputUmum/InputUmum";
import { IKoleksiPengguna, inisialisasiIKoleksiPengguna } from "Data/KoleksiPengguna";
import Tombol from '@material-ui/core/Button';
import IkonTutup from '@material-ui/icons/Close';
import { toast } from "react-toastify";
import cekKataKotor from 'fungsi/cekKataKotor'

const TambahkanAnime: FC<ITambahkanAnime> = (p) => {  
  const [loadingLain, setLoadingLain] = useState(false)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [auth, loadingAuth, _erorAuth] = useAuthState(firebase.auth())
  const sejarah = useHistory()

  const [anime, loading, _eror] = useDocumentDataOnce<IDataAnime>(
    firebase.firestore().doc('databaseAnime/' + 
      (decodeURIComponent(p.match.params.idAnime.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25'))
    )
  ))
  const [koleksiPengguna, setKoleksiPengguna] = useState<IKoleksiPengguna | null>(null)

  useEffect(() => {
    const downloadKoleksi = async () => {
      if((auth !== null && auth !== undefined) && (anime !== null && anime !== undefined)){
        try{
          setLoadingLain(true)
          const ssKoleksi: any = (await firebase.firestore().doc('koleksiAnimekuPengguna/' + auth.uid + anime.idAnime).get()).data()
          if(ssKoleksi !== undefined){
            const dataKoleksi: IKoleksiPengguna = ssKoleksi
            setKoleksiPengguna(dataKoleksi)
          }
          setLoadingLain(false)
          console.log(auth.uid + anime.idAnime)
        }catch(e){
          const er = e as Error
          console.log(er.message)
        }
      }
    }
    downloadKoleksi()
  }, [auth, anime])

  const [episodePengguna, setEpisodePengguna] = useState(0)
  const [nilaiPengguna, setNilaiPengguna] = useState(5)

  const [tanggalMulaiNonton, setTanggalMulaiNonton] = useState<Date | null>(null)
  const [tanggalSelesaiNonton, setTanggalSelesaiNonton] = useState<Date | null>(null)



  const [statusNonton, setStatusNonton] = useState(indeksStatusNonton.RENCANA)

  const [review, setReview] = useState<string | null>('')
  const [kurangDariMaks, setKurangDariMaks] = useState(true)

  const [waktuDapat, setWaktuDapat] = useState(firebase.firestore.Timestamp.now())
  const [waktuRilis, setWaktuRilis] = useState(firebase.firestore.Timestamp.now())

  useEffect(() => {
    if(episodePengguna === anime?.episodeAnime){
      setStatusNonton(indeksStatusNonton.SELESAI)
    }else if(episodePengguna === 0){
      setStatusNonton(indeksStatusNonton.RENCANA)
    }else if(episodePengguna > 0){
      setStatusNonton(indeksStatusNonton.NONTON)
    } 
  }, [episodePengguna])

  useEffect(() => {
    if(anime !== undefined && anime !== null && statusNonton === indeksStatusNonton.SELESAI 
      && anime.episodeAnime !== null && anime.episodeAnime >= 0){
      setEpisodePengguna(anime.episodeAnime)
    }else if(anime !== undefined && anime !== null && statusNonton === indeksStatusNonton.RENCANA){
      setEpisodePengguna(0)
    }
  }, [statusNonton])

  useEffect(() => {
    if(koleksiPengguna !== null && koleksiPengguna !== undefined){
      setEpisodePengguna(koleksiPengguna.episodeProgress || 0)
      setNilaiPengguna(koleksiPengguna.skorPengguna || 5 )
      setStatusNonton(koleksiPengguna.statusNonton || indeksStatusNonton.NONTON)
      setReview(koleksiPengguna.reviewPengguna || null)

      if(koleksiPengguna.mulaiNonton !== null && koleksiPengguna.mulaiNonton !== undefined){
        const mulaiNontonPengguna = new Date(koleksiPengguna.mulaiNonton.toMillis())
        setTanggalMulaiNonton(mulaiNontonPengguna)
      }

      if(koleksiPengguna.selesaiNonton !== null && koleksiPengguna !== undefined){
        const selesaiNontonPengguna = new Date(koleksiPengguna.selesaiNonton.toMillis())
        setTanggalSelesaiNonton(selesaiNontonPengguna)
      }
    } 
  }, [koleksiPengguna])

  const handelTambahkanKoleksi = async () => {
    if(!kurangDariMaks){
      toast.dark("Teks lebih dari maksimal karakter (1000)")
    }else{
      const [waktu, _waktuBenar] = await(waktuRealtime())
      const adaKataKotor = cekKataKotor(review || undefined, "review")
      
      if(anime !== null && anime !== undefined && auth !== null && auth !== undefined && !adaKataKotor){
        setLoadingUpload(true)
        const idReview = auth.uid + anime.idAnime
        const koleksiDapat = koleksiPengguna || inisialisasiIKoleksiPengguna(anime.idAnime, auth.uid
          , idReview
        )
        let sekonMulaiNonton: firebase.firestore.Timestamp | null = null
        let sekonSelesaiNonton: firebase.firestore.Timestamp | null = null
        if(tanggalMulaiNonton !== null ){
          sekonMulaiNonton = firebase.firestore.Timestamp.fromDate(tanggalMulaiNonton)
        }
        if(tanggalSelesaiNonton !== null){
          sekonSelesaiNonton = firebase.firestore.Timestamp.fromDate(tanggalSelesaiNonton)
        }

        koleksiDapat.episodeProgress = episodePengguna
        koleksiDapat.idAnime = anime.idAnime
        koleksiDapat.idPengguna = auth.uid
        koleksiDapat.idReview = idReview
        koleksiDapat.judulAnime = anime.judulAnime || ""
        koleksiDapat.jumlahTotalMembantu = koleksiPengguna?.jumlahTotalMembantu || 0
        koleksiDapat.membantu = koleksiPengguna?.membantu || 0
        koleksiDapat.mulaiNonton = sekonMulaiNonton || null
        koleksiDapat.reviewPengguna = review === '' ? null : review
        koleksiDapat.selesaiNonton = sekonSelesaiNonton || null
        koleksiDapat.skorPengguna = nilaiPengguna
        koleksiDapat.statusNonton = statusNonton
        koleksiDapat.tanggalEditReview = waktu
        koleksiDapat.tanggalReview = koleksiPengguna?.tanggalReview || waktu
        koleksiDapat.terdapatReview = (review !== null && review !== '')
        koleksiDapat.tidakMembantu = koleksiPengguna?.tidakMembantu || 0

        try{
          await firebase.firestore().doc('koleksiAnimekuPengguna/' + idReview).set(koleksiDapat)
          toast.dark('koleksi terupload', {autoClose: 2000})
          sejarah.goBack()
          setLoadingUpload(false)
        }catch(e){
          const er = e as Error
          console.log(er.message)
          toast.dark('eror: ' + er.message)
          setLoadingUpload(false)
        }
      }
    }
  }


  useEffect(() => {
    async function dapatkanWaktu() {
      try{
        const [waktuRealTime, waktuBenar] = await waktuRealtime()
        if(waktuBenar){
          setWaktuDapat(waktuRealTime)
        }
      }catch(e){
        console.log((e as Error).message)
      }
    }
    dapatkanWaktu()
  }, [])

  useEffect(() => {
    if(anime?.tanggalMulaiTayang !== undefined && anime?.tanggalMulaiTayang !== null){
      setWaktuRilis(anime?.tanggalMulaiTayang)
    }
  },[anime])
  
  return(
    (loadingAuth || loading || loadingLain) ? 
    <LoadingLaman />
    :
    (auth === null || auth === undefined) ? 
    <h6 className='peringatan-tambahkan-anime-apl'>
      Anda perlu login untuk menambahkan anime
    </h6>
    : (anime === undefined) ?
    <h6 className='peringatan-tambahkan-anime-apl'>
      Anime tidak ditemukan
    </h6>
    :
    <div className='tambahkan-anime-apl'>
      <h4 className='judul-anime'>
        {anime.judulAnime}
      </h4>
      <div className='kontainer-gbr-tbh-anime'>
        <img className='gbr' alt='' src={anime.urlFotoAnime || undefined}/>
      </div>
      <div className='input-skor-episode'>
        <InputAngka angkaInput={episodePengguna} setAngkaInput={setEpisodePengguna} angkaMin={0} 
          judul='Episode ke'
          angkaMaks={(!(anime.episodeAnime === null || anime.episodeAnime === undefined || anime.episodeAnime < 0)) ?
            anime.episodeAnime :
            Math.floor(((waktuDapat.seconds - waktuRilis.seconds)/(86500 * 7)) + 1) 
            }/>
        <InputAngka judul='Skormu' 
          angkaInput={nilaiPengguna} 
          setAngkaInput={setNilaiPengguna} 
          angkaMin={1} angkaMaks={10}
          skor
        />
      </div>
      <div className='review-pengguna'>
        <InputUmum 
          elemenInput={
            <InputTeks 
              teksIsi={review} 
              setTeksIsi={setReview} 
              kataMaksimal={1000} 
              setKurangDariMaks={setKurangDariMaks} 
              kurangDariMaks={kurangDariMaks}
              multiGaris
              teksLabel={'Review'}
              lebarMaksimal
            />}
          teksEror={'Lebih dari kata maksimal'}
          tampilkanEror
          apakahEror={!((review?.length || 0) < 1000)}
        />
      </div>
      <div className='tanggal-nonton'>
        <div className='isi'>
          <TanggalNonton tanggalTerpilih={tanggalMulaiNonton} 
            handelTanggalBerubah={setTanggalMulaiNonton}
            label='Tanggal mulai nonton'
          />
          <IconButton aria-label='hapus tanggal mulai nonton' onClick={() => {setTanggalMulaiNonton(null)}}>
            <IkonTutup />
          </IconButton>
        </div>
        <div className='isi'>
          <TanggalNonton tanggalTerpilih={tanggalSelesaiNonton} 
            handelTanggalBerubah={setTanggalSelesaiNonton}
            label='Tanggal selesai nonton'
          />
          <IconButton aria-label='hapus tanggal selesai nonton' onClick={() => {setTanggalSelesaiNonton(null)}} >
            <IkonTutup />
          </IconButton>
        </div>
      </div>
      <div className='klaster-status-nonton'>
        <Chip style={{margin: '0 3px 3px 3px'}} label='Ditonton' onClick={() => {setStatusNonton(indeksStatusNonton.NONTON)}} 
          color={statusNonton === indeksStatusNonton.NONTON ? 'primary' : 'default'}
        />
        <Chip style={{margin: '0 3px 3px 3px'}} label='Selesai' onClick={() => {setStatusNonton(indeksStatusNonton.SELESAI)}} 
          color={statusNonton === indeksStatusNonton.SELESAI ? 'primary' : 'default'}
        />
        <Chip style={{margin: '0 3px 3px 3px'}} label='Rencana' onClick={() => {setStatusNonton(indeksStatusNonton.RENCANA)}} 
          color={statusNonton === indeksStatusNonton.RENCANA ? 'primary' : 'default'}
        />
        <Chip style={{margin: '0 3px 3px 3px'}} label='Tidak selesai' onClick={() => {setStatusNonton(indeksStatusNonton.TAK_JADI)}} 
          color={statusNonton === indeksStatusNonton.TAK_JADI ? 'primary' : 'default'}
        />
      </div>
      {!loadingUpload ?
        <div className='kontainer-tbl-upload'> 
          <Tombol className='tombol-buat-akun-login'
            color='primary'
            variant='contained'
            onClick={handelTambahkanKoleksi}
            >
            Tambahkan anime
          </Tombol>
        </div>
        :
        <LoadingLaman />
      }
    </div>
  );
}

const InputAngka: FC<IInputAngka> = memo((p) => {
  const handelBerubah = (ev: ChangeEvent<HTMLInputElement>) => {
    if(p.skor){
      if(cekNumerik(ev.target.value)){
        if(Number(ev.target.value) > 10 && Number(ev.target.value) <= 100){
          const angkaDapat = Number(ev.target.value) - Math.floor(Number(ev.target.value)/10)*10
          if(angkaDapat < p.angkaMin){
            p.setAngkaInput(p.angkaMin)
          }else{
            p.setAngkaInput(angkaDapat)
          }
        }else if(Number(ev.target.value) > 100){
          p.setAngkaInput(p.angkaMaks)
        }else if(Number(ev.target.value) < p.angkaMin){
          p.setAngkaInput(p.angkaMin)
        }else{
          p.setAngkaInput(Math.floor(Number(ev.target.value)))
        }
      }else{
        p.setAngkaInput(p.angkaMin)
      }
    }else{
      if(cekNumerik(ev.target.value)){
        if(Number(ev.target.value) > p.angkaMaks){
          p.setAngkaInput(p.angkaMaks)
        }else if(Number(ev.target.value) < p.angkaMin){
          p.setAngkaInput(p.angkaMin)
        }else{
          p.setAngkaInput(Math.floor(Number(ev.target.value)))
        }
      }else{
        p.setAngkaInput(p.angkaMin)
      }
    }
  }
  return(
    <div className='input-angka'>
      <h6 className='judul'>
        {p.judul}
      </h6>
      <IconButton aria-label='tambah episode' >
        <IkonPlus style={{padding: '5px'}} onClick={() => {if(p.angkaInput < p.angkaMaks){p.setAngkaInput(p.angkaInput + 1)}}}/>
      </IconButton>
      <div className='kontainer-input'>
        <TextField onChange={handelBerubah} value={p.angkaInput} type='number' fullWidth/>
      </div>
      <IconButton aria-label='kurangi episode' >
        <IkonMinus style={{padding: '5px'}} onClick={() => {if(p.angkaInput > p.angkaMin)p.setAngkaInput(p.angkaInput -1)}}/>
      </IconButton>
    </div>
  )
}, (pSeb, pSet) => {
  if(pSeb.angkaInput !== pSet.angkaInput){
    return false
  }
  return true
})

const TanggalNonton: FC<ITanggalNonton> = memo((p) => {
  return (
    <div className='klaster-input-tanggal'>
      <InputTanggal handelTanggalBerubah={p.handelTanggalBerubah} 
        tanggalTerpilih={p.tanggalTerpilih}
        label={p.label}
        promptPetunjukTahun={false}
      />
    </div>
  )
}, (pSeb, pSet) => {
  if(pSeb.tanggalTerpilih !== pSet.tanggalTerpilih){
    return false
  }
  return true
})

export default TambahkanAnime

interface ITambahkanAnime extends RouteComponentProps<{idAnime: string}>{
  auth?: firebase.User | null
}

interface IInputAngka{
  angkaInput: number,
  angkaMin: number,
  angkaMaks: number,
  skor?: boolean,
  judul: string,
  setAngkaInput: (angka: number) => void,
}

interface ITanggalNonton {
  handelTanggalBerubah: Dispatch<SetStateAction<Date | null>>,
  tanggalTerpilih: Date | null,
  label?: string,
}