import { TypeAksi } from ".";

export interface IAksiUI {
  type: TypeAksi,
  payload: {[x: string]: any | number},
}


const modeGelapStorage = localStorage.getItem("modeGelap") 
const jsonModeGelap = (modeGelapStorage === null ? {modeGelap: 0} 
  : JSON.parse(modeGelapStorage))
const initialCondition = { ...jsonModeGelap}

export function ui(state: any = initialCondition, action: IAksiUI){
  switch(action.type){
    case 'MODE_GELAP':
      return {
        ...state,
        ...action.payload
      }
    default:
      return state 
  }
}