import {FC, RefObject} from 'react'
import './TabAnimePengguna.scss'
import IsiMusiman from "../../IsiAnime"
import { IKoleksiPengguna } from 'Data/KoleksiPengguna'

const TabAnimePengguna: FC<ITabAnimePengguna> = (p) => {
  return(
    <ul className='tab-anime-pengguna'>
      {
        p.animePengguna.map((nilai, indeks) => {
          return <IsiMusiman koleksiAnime={nilai} 
            indeks={indeks}
            animeTerakhirLoad={p.animeTerakhirLoad}
            refAnimeTerakhir={p.refAnimeTerakhir}
            cacheAnime={p.cacheAnime}
          />
        })
      }
    </ul>
  )
}

export default TabAnimePengguna

interface ITabAnimePengguna{
  animePengguna: Array<IKoleksiPengguna>;
  animeTerakhirLoad?: number;
  refAnimeTerakhir?: RefObject<HTMLLIElement>;
  cacheAnime: () => void;
}