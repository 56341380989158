import { DataPemberitahuan, IPengguna } from "Data/Pengguna";
import { combineReducers } from "redux";
import { cacheDataReducer } from "./cacheData";
import { cacheLamanReducer, ICacheLaman, IDataCache } from "./cacheLaman";
import { pengguna } from "./pengguna";
import { ui } from "./ui";

/**
 * @param {any | number} payload [jenisLaman] isi payload; [jenisLaman + 'scroll'] lokasi scroll Y
 */
export interface IRootRedux {
  cacheLaman?: {
    [x: string]: any,
    data: ICacheLaman | null | undefined
  };
  cacheData?: {
    [x: string]: any 
  }
  pengguna?: {
    pemberitahuan: DataPemberitahuan[];
    pengguna: IPengguna | null
  };
  ui?: {
    modeGelap: number
  }
}

export interface IAksi {
  type: TypeAksi,
  payload: {[x: string]: any | number},
}


const semuaReducer = combineReducers({
  cacheData: cacheDataReducer,
  cacheLaman: cacheLamanReducer,
  pengguna: pengguna,
  ui: ui
})

export type TypeAksi = 'CACHE_LAMAN' | 'FORUM_HAPUS'
  | 'STORE_PEMBERITAHUAN' | 'STORE_PENGGUNA' | 'MODE_GELAP'
  | 'CACHE_DATA' | "BUKA_DIALOG_KONFIRMASI"

export default semuaReducer

export type StateReducer = ReturnType<typeof semuaReducer>