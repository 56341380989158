import firebase from 'firebase';


const prosesBlob = async (blob: Blob | null, 
  kompres3Kali: boolean | null | undefined, 
  resizeFileBlob: (blob: Blob, lebar: number, tinggi: number, kompresi: number) => Promise<Blob>,
  rasioAspek: number,
  folderUpload: string,
  namaFile: string,
  ekstensiFileKecil?: string, 
  ekstensiFileSedang?: string, 
  ekstensiFileBesar?: string,
): Promise<{success: boolean, data: IURLGambar | null}> => {
  const efKecil = ekstensiFileKecil || 'Kecil'
  const efSedang = ekstensiFileSedang || 'Sedang'
  const efBesar = ekstensiFileBesar || 'Besar'
  const resizeKecilTinggi = 175
  const resizeSedangTinggi = 460
  const resizeBesarTinggi = 850
  const resizeKecilLebar = 175 * rasioAspek
  const resizeSedangLebar = 460 * rasioAspek
  const resizeBesarLebar = 850 * rasioAspek
  if (blob) {
    try {
      if (kompres3Kali !== null && kompres3Kali !== undefined && kompres3Kali !== false) {
        const gambarBesar: Blob = await resizeFileBlob(blob, resizeBesarLebar, resizeBesarTinggi, 70);
        const gambarSedang: Blob = await resizeFileBlob(blob, resizeSedangLebar, resizeSedangTinggi, 50);
        const gambarKecil: Blob = await resizeFileBlob(blob, resizeKecilLebar, resizeKecilTinggi, 30);

        const ssGambarBesar = await firebase.storage().ref().child(folderUpload + '/' + namaFile + efBesar).put(gambarBesar);
        const ssGambarSedang = await firebase.storage().ref().child(folderUpload + '/' + namaFile + efSedang).put(gambarSedang);
        const ssGambarKecil = await firebase.storage().ref().child(folderUpload + '/' + namaFile + efKecil).put(gambarKecil);

        const urlDownloadGambarBesar = await ssGambarBesar.ref.getDownloadURL();
        const urlDownloadGambarSedang = await ssGambarSedang.ref.getDownloadURL();
        const urlDownloadGambarKecil = await ssGambarKecil.ref.getDownloadURL();

        return {success: true, data: {
          urlDownloadGambarBesar, 
          urlDownloadGambarSedang, 
          urlDownloadGambarKecil
        }}
      } else {
        const gambarSedang: Blob = await resizeFileBlob(blob, resizeBesarLebar, resizeBesarTinggi, 75);
        const ssGambarSedang = await firebase.storage().ref().child(folderUpload + '/' + namaFile ).put(gambarSedang);
        const urlDownloadGambarBesar = await ssGambarSedang.ref.getDownloadURL();
        return{
          success: true, data:{
            urlDownloadGambarKecil: null,
            urlDownloadGambarSedang: null,
            urlDownloadGambarBesar: urlDownloadGambarBesar,
          }
        }
      }
    } catch (e) {
      return{
        success: false,
        data: null
      }
    }
  } else {
    return {success: true, data: {
      urlDownloadGambarKecil: null,
      urlDownloadGambarSedang: null,
      urlDownloadGambarBesar: null,
    }}
  }
}

export default prosesBlob

export interface IURLGambar{
  urlDownloadGambarBesar?: string | null
  urlDownloadGambarSedang?: string | null
  urlDownloadGambarKecil?: string | null
}