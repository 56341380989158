import firebase from "firebase";

export interface IKoleksiPengguna{
  episodeProgress: number,
  idAnime: string | null,
  idPengguna: string | null,
  idReview: string,
  judulAnime: string,
  jumlahTotalMembantu: number,
  membantu: number,
  mulaiNonton:  firebase.firestore.Timestamp | null,
  reviewPengguna: string | null,
  selesaiNonton: firebase.firestore.Timestamp | null,
  skorPengguna: number, 
  statusNonton: number | null,
  tanggalEditReview: firebase.firestore.Timestamp,
  tanggalReview: firebase.firestore.Timestamp,
  terdapatReview: boolean,
  tidakMembantu: number,
}

export const inisialisasiIKoleksiPengguna = (idAnime: string, idPengguna: string, idReview: string) => {
  const koleksiDapat = {
    episodeProgress: 0,
    idAnime: idAnime,
    idPengguna: idPengguna,
    idReview: idReview,
    judulAnime: '',
    jumlahTotalMembantu: 0,
    membantu: 0,
    mulaiNonton:  null,
    reviewPengguna: null,
    selesaiNonton: null,
    skorPengguna: 5, 
    statusNonton: 0,
    tanggalEditReview: firebase.firestore.Timestamp.now(),
    tanggalReview: firebase.firestore.Timestamp.now(),
    terdapatReview: false,
    tidakMembantu: 0,
  } as IKoleksiPengguna
  return koleksiDapat
}