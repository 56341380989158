import { useEffect, useState } from "react"
import {IKoleksiPengguna} from "Data/KoleksiPengguna"
import firebase from "firebase"
import { IDataAnime } from "Data/Anime"

const useFetchReviewAnime: TUseFetchReviewAnime = (anime) => {
  const [reviewAnime, setReviewAnime] = useState<IKoleksiPengguna[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetch = async () => {
      if(anime?.idAnime){
        setLoading(true)
        try{
          const data = (await firebase.firestore().collection('koleksiAnimekuPengguna')
          .where('idAnime', "==", anime.idAnime)
          .where('terdapatReview', "==", true)
          .orderBy('membantu', 'desc')
          .limit(5)
          .get())

          const reviewBaru: IKoleksiPengguna[] = []
          const promiseAr: Promise<IKoleksiPengguna>[] = []
          data.forEach(nilai => {
            promiseAr.push(new Promise((res) =>{
              const dataSatuan: IKoleksiPengguna = nilai.data() as any
              reviewBaru.push(dataSatuan)
              res(dataSatuan)
            }))
          })

          await Promise.all(promiseAr)
          setLoading(false)
          setReviewAnime(reviewBaru)
        }catch(e){
          const er: Error = e as any
          console.error(er.message)
          setLoading(false)
        }
      }
    }
    fetch()
  }, [anime?.idAnime])

  return [reviewAnime, loading]
}

type TUseFetchReviewAnime = (anime?: IDataAnime) => [IKoleksiPengguna[], boolean]

export default useFetchReviewAnime