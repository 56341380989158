import { useAuthState } from "react-firebase-hooks/auth"
import firebase from "firebase"
import { IPengguna } from "Data/Pengguna"
import { useEffect, useState } from "react"
import { useDocumentData } from "react-firebase-hooks/firestore"


export const usePengguna = () => {
  const [auth] = useAuthState(firebase.auth())
  const [query, setQuery] = useState<firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | undefined>(undefined)

  useEffect(() => {
    if(auth === undefined || auth === null) return
    setQuery(firebase.firestore().doc(`pengguna/${auth.uid}`))
  }, [auth])

  const pengguna = useDocumentData<IPengguna>(query);
  
  return pengguna
}

export const usePenggunaId = (id: string | undefined | null) => {
  const [query, setQuery] = useState<firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | undefined>(undefined)

  useEffect(() => {
    if(id === undefined || id === null) return
    setQuery(firebase.firestore().doc(`pengguna/${id}`))
  }, [id])

  const pengguna = useDocumentData<IPengguna>(query);

  return pengguna
}