import "./InputRadioDialog.scss"
import {FC, useRef, useEffect, useState, ChangeEvent, memo, CSSProperties, Dispatch} from 'react';
import { Dialog, DialogTitle, DialogContent, RadioGroup, Button, DialogActions, FormControlLabel, Radio} from '@material-ui/core';


const InputRadioDialog: FC<IInputRadioDialog> = (p) => {

  const refRadioGrup = useRef<HTMLElement>(null);
  const [terpilih, setTerpilih] = useState(p.pilihanTerpilih)

  useEffect(() => {
    if (!p.buka) {
      setTerpilih(p.pilihanTerpilih);
    }
  }, [p.pilihanTerpilih, p.buka]);

  const handelMasukNilai = () => {
    if (refRadioGrup.current != null) {
      refRadioGrup.current.focus()
    }
  }

  const handelOk = () => {
    p.onOK(terpilih)
    p.setBuka(false)
  }
  const handelBatal = () => {
    p.onBatal && p.onBatal(terpilih)
    p.setBuka(false)
  }

  const handelPerubahan = (event: ChangeEvent<HTMLInputElement>) => {
    setTerpilih(Number((event.target as HTMLInputElement).value));
  };

  return(
    <Dialog
      maxWidth="xs"
      onEntering={handelMasukNilai}
      aria-labelledby="confirmation-dialog-title"
      open={p.buka}
      keepMounted
    >
      <DialogTitle id="confirmation-dialog-title">{p.judulPilihan}</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={refRadioGrup}
          name="gender"
          value={terpilih}
          onChange={handelPerubahan}
        >
          {p.isiPilihan.map((pilihan, kunci) => (
            <FormControlLabel value={kunci} key={pilihan} control={<Radio />} label={pilihan} />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handelBatal} color="primary">
          Batal
        </Button>
        <Button onClick={handelOk} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

interface IInputRadioDialog{
  judulPilihan?: string;
  isiPilihan: Array<string>;
  pilihanTerpilih: number | null;
  buka: boolean;
  setBuka: Dispatch<boolean>;
  onOK: (nilaiTerpilih: number | null) => void;
  onBatal?: (nilaiTerpilih: number | null) => void;
}

export default InputRadioDialog