import {FC, useEffect, useState} from 'react'
import logo from '../../logo/logoAniMemo.svg';
import './NavigasiAtas.scss';
import IkonPencarian from '@material-ui/icons/SearchOutlined';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IkonAkun from '@material-ui/icons/AccountCircle'
import {Link, useHistory} from 'react-router-dom';
import {IPengguna} from '../../Data/Pengguna';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core';
import { useAuthState } from 'react-firebase-hooks/auth';
import { DataPemberitahuan } from 'Data/Pengguna';
import firebase from 'firebase';
import { useDispatch } from 'react-redux';
import { storePemberitahuan } from 'store/actions/pengguna';

export const Navbar: FC<IPropNavBar> = (prop) => {
  const [auth] = useAuthState(firebase.auth())
  const sejarah = useHistory()
  const handelKePemberitahuan = () => {
    if(auth){
      sejarah.push('/h/pemberitahuan')
    }else{
      sejarah.push('/h/login')
    }
  }
  const [pemberitahuan] = useFetchPemberitahuan(auth?.uid);
  const pembTakTerbaca = useScrapeTakTerbaca(pemberitahuan);

  return(
    <nav className="navbar">
      <ul className="navbar-nav">
        {
          <Link className="nav-seluruh-logo"
          to={'/'}
          style={{ textDecoration: 'none' }}>
            <LogoHeader/>
            {((prop.lebarLayar ?? 0) > 600) &&
              <div className='nav-teks-logo'>
                <p>AniMemo</p>
              </div>
            }
          </Link>
        }
        <div className="item-nav">
          <ItemNavigasi text={pembTakTerbaca} handelKlik={handelKePemberitahuan} ikonItem={NotificationsIcon}/>
          <ItemNavigasi handelKlik={prop.handelCari} ikonItem={IkonPencarian}/>
          <Link to={auth != null ? '/p/' + auth.uid : '/h/login'} 
          style={{textDecoration: 'none'}}>
            <ItemNavigasi 
            urlFoto={prop.pengguna?.urlFotoProfilKecilPengguna} 
            ikonItem={IkonAkun}/>
          </Link>
        </div>
      </ul>
    </nav>
  )
}

const useScrapeTakTerbaca = (pemberitahuan: DataPemberitahuan[]) => {
  const [pembTakTerbaca, setPembTakTerbaca] = useState<number | null>(null);

  useEffect(() => {
    let takTerbaca = 0
    pemberitahuan.map(isi => {
      if(!isi.dibaca){
        takTerbaca = takTerbaca + 1
      }
    });
    setPembTakTerbaca(takTerbaca || null);
  }, [pemberitahuan])

  return pembTakTerbaca
}

const useFetchPemberitahuan: TUseFetchPemberitahuan = (idPengguna?: string | null) => {
  const [pemberitahuan, setPemberitahuan] = useState<DataPemberitahuan[]>([])
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch();
  useEffect(() => {
    const fetch = async () => {
      if(idPengguna){
        setLoading(true);
        const pemberitahuanDapat = (await firebase.firestore().collection('pemberitahuan')
          .where("idKe", "==", idPengguna)
          .orderBy("waktu", "desc")
          .limit(10)
          .get())
        const arPromise: Promise<DataPemberitahuan>[] = []
        const arPemberitahuan: DataPemberitahuan[] = []
        
        pemberitahuanDapat.forEach((doc) => {
          arPromise.push(new Promise((res, rej) => {
            const data: DataPemberitahuan = doc.data() as any
            arPemberitahuan.push(data)
            res(data)
          }))
        })

        await Promise.all(arPromise);

        dispatch(storePemberitahuan(arPemberitahuan))
        setPemberitahuan(arPemberitahuan);
        setLoading(false);
      }
    }
    fetch()
  }, [idPengguna])

  return [pemberitahuan, loading]
}

export const LogoHeader: FC<any> = (prop) => {
  return (
    <img src={logo} className="nav-logo" alt="logo animemo" />
  )
}

export const ItemNavigasi: FC<IPropItemNav> = (prop) => {
  return(
    <div className="tombol-ikon" onClick={prop.handelKlik}>
      {prop.text != null ?
      <h6>{prop.text}</h6> :
      prop.urlFoto != null ? 
      <img src={prop.urlFoto ?? undefined} className='gambar-item-nav-atas'/>
      :
      prop.ikonItem != null && <prop.ikonItem/>
      }
    </div>
  )
}

type TUseFetchPemberitahuan = (idPengguna?: string | null) => [DataPemberitahuan[], boolean]

interface IPropItemNav{
  handelKlik?: () => void;
  text?: string | number | null;
  ikonItem?: OverridableComponent<SvgIconTypeMap<{}, "svg">>;
  urlFoto?: string | null
}

interface IPropNavBar{
  handelCari?: () => void;
  pengguna?: IPengguna | null;
  lebarLayar?: number
}