import { useCallback } from "react";
import {DataAnakForum} from "Data/DataForum"
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from 'firebase';
import { toast } from "react-toastify";

const useHapusAnakForum = () => {
  const [auth] = useAuthState(firebase.auth())
  const hapusAnakForum = async (anakForum: DataAnakForum | null) => {
    if(auth && anakForum && auth.uid === anakForum.idPengguna){
      toast.dark("menghapus balasan", {position: "top-center", autoClose: 200})
      try{
        const refBesar = firebase.storage().ref().child(`fotoForum/ForumAnak${anakForum.idAnakForum}Sedang`)
        await refBesar.delete()
      }catch(e){
        
      }
      try{
        await firebase.firestore().doc(`koleksiAnakForum/${anakForum.idAnakForum}`).delete()
        toast.success("balasan telah dihapus", {position: "top-center"})
        return true
      }catch(e){
        const er = e as Error
        toast.error("balasan tidak dapat dihapus", {position: "top-center"})
        return false
      }
    }else{
      return false
    }
  }
  return hapusAnakForum
}

export default useHapusAnakForum