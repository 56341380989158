

export default function hitungWaktu(waktu: number | null): string{
  const perbedaanWaktu =  (Date.now()/1000) - (waktu || 0)
  const perbedaanWaktuMenit = (((perbedaanWaktu/60) <= 0) ? 1.0 : (perbedaanWaktu/60)).toFixed(0)
  const perbedaanWaktuJam = ((perbedaanWaktu/(60*60) <= 0) ? 1.0 : (perbedaanWaktu/(60*60))).toFixed(0)
  const perbedaanWaktuHari = ((perbedaanWaktu/(24*60*60)<= 0) ? 1.0 : (perbedaanWaktu/(24*60*60))).toFixed(0)
  const perbedaanWaktuMinggu = ((perbedaanWaktu/(7*24*60*60)<= 0) ? 1.0 : (perbedaanWaktu/(7*24*60*60))).toFixed(0)
  const perbedaanWaktuBulan = ((perbedaanWaktu/(4*7*24*60*60)<= 0) ? 1.0 : (perbedaanWaktu/(4*7*24*60*60))).toFixed(0)
  const perbedaanWaktuTahun = ((perbedaanWaktu/(12*4*7* 24*60*60)<= 0) ? 1.0 : (perbedaanWaktu/(12*4*7* 24*60*60))).toFixed(0)
  var teksWaktu = ''
  switch(true){
    case perbedaanWaktu <= 60:
      teksWaktu = `${perbedaanWaktu.toFixed(0)} detik yang lalu`;
      break;
    case (perbedaanWaktu > 60 && perbedaanWaktu <= 3600):
      teksWaktu = `${perbedaanWaktuMenit} menit yang lalu`;
      break;
    case (perbedaanWaktu > 3600 && perbedaanWaktu <= 86400):
      teksWaktu = `${perbedaanWaktuJam} jam yang lalu`;
      break;
    case (perbedaanWaktu > 86400 && perbedaanWaktu <= 604800):
      teksWaktu = `${perbedaanWaktuHari} hari yang lalu`;
      break;
    case (perbedaanWaktu > 604800 && perbedaanWaktu <= 2419200):
      teksWaktu = `${perbedaanWaktuMinggu} minggu yang lalu`;
      break;
    case (perbedaanWaktu > 2419200 && perbedaanWaktu <= 31536000):
      teksWaktu = `${perbedaanWaktuBulan} bulan yang lalu`;
      break;
    default :
      teksWaktu = `${perbedaanWaktuTahun} tahun yang lalu`;
  }
  return teksWaktu
}