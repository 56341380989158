import {FC, useEffect, useState} from 'react'
import firebase from "firebase";
import {inisFirebase} from '../../fungsi/inisialisasiFirebase'
import { DataKomik } from '../../Data/Komik';

export const Komik: FC<MatchKomik> = (prop) =>  {
  useEffect(() => {
    fetchKomik();
  }, [prop.match]);

  const[komik, setKomik] = useState<DataKomik | undefined>(undefined);
  const fetchKomik = async () => {
    const komikDapat = await dapatkanKomik(prop.match.params.idKomik)
    setKomik(komikDapat)
  };

  return (
  <div className="konten">
      <h2>
        {komik?.judulKomik || '-'}
      </h2>
      <p>
        Skor AniMemo: {(((komik?.skor || 0) >= 1) ? komik?.skor?.toFixed(2) : '?')} | Skor MyAnimeList: {(((komik?.skorMal || 0) >= 1) ? komik?.skorMal?.toFixed(2) : '?')}
        
      </p>
      <img src={komik?.urlFotoKomik} className="gambar-komik" alt="Foto Komik" />
      <h3>
        beri sendiri skor kamu dengan instal <a
        className="App-link"
        href="https://play.google.com/store/apps/details?id=com.animemodev.animemo"
        target="_blank"
        rel="noopener noreferrer"
      >
        aplikasi AniMemo
      </a>
      </h3>
      jika aplikasi sudah terinstall, pada saat ditanya "buka dengan/open with", pilih AniMemo
      <br/>
      <br/>
    </div>
  );
}

async function dapatkanKomik(idKomik?: string){
  const db = firebase.firestore()
  const kolRefKomik = db.collection('databaseKomik')
  const dokKomik: any = (await kolRefKomik.doc(idKomik || '').get()).data()
  const komik: DataKomik = dokKomik
  return komik
}

interface MatchKomik{
  history: any;
  match: ParamKomik;
  location: any;
}

interface ParamKomik {
  params: IdKomik;
  path: string;
  isExact: boolean;
}

interface IdKomik{
  idKomik: string;
}