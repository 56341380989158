import { Dispatch, FC, useEffect, useRef, useState } from "react"
import { TextField } from '@material-ui/core'
import IkonKirim from '@material-ui/icons/Send';
import IkonTambahGambar from '@material-ui/icons/AddAPhoto';
import IkonTutup from '@material-ui/icons/Close';
import InputGambar, { uploadGambarFirebase } from "komponen/input/inputGambar/InputGambar"
import firebase from "firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import {waktuRealtime} from "fungsi/fungsiWaktu"
import './BalasForum.scss'
import { DataAnakForum, DataForum } from "Data/DataForum";
import { toast } from "react-toastify";
import { indeksUrutanAnakForum } from "laman/ForumAnak/src/ForumAnak";
import cekKataKotor from "fungsi/cekKataKotor"

const BalasForum: FC<IBalasForum> = (p) =>{
  const [inputFokus, setInputFokus] = useState(false)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const [logikaDummy, setLogikaDummy] = useState(false)
  const [tblAktif, setTblAktif] = useState(true)

  const [waktuUploadGbr, setWaktuUploadGbr] = useState(-1)

  const [cropBalas, setCropBalas] = useState<ReactCrop.Crop | null>(null) 
  const [kanvasGambarProfil, setKanvasGambarProfil] = useState<HTMLCanvasElement | null>(null)
  const [URLGambarBesar, setURLGambarBesar] = useState<string | null>(null)
  const [URLGambarSedang, setURLGambarSedang] = useState<string | null>(null)
  const [URLGambarKecil, setURLGambarKecil] = useState<string | null>(null)
  const [uploadGbrSelesai, setUploadGBrSelesai] = useState(false)
  const setURLGambar = (urlGambarBesarInput: string | null
    , urlGambarSedangInput: string | null, urlGambarKecilInput: string | null) => {
    setURLGambarBesar(urlGambarBesarInput)
    setURLGambarSedang(urlGambarSedangInput)
    setURLGambarKecil(urlGambarKecilInput)
  }

  const handelBukaGambar = () => {
    setKanvasGambarProfil(null)
    setCropBalas(null)
    setInputFokus(!inputFokus)
  }
  const handelHapusGbr = () => {
    setKanvasGambarProfil(null)
    setCropBalas(null)
    setInputFokus(false)
  }

  const [auth, loading, er] = useAuthState(firebase.auth())

  const handelUploadBalasan = async () => {
    if((inputRef.current?.value?.length || 0) > 5000){
      toast.error("maksimal 5000 karakter")
      return 
    }
    if(auth && inputRef.current?.value){
      setTblAktif(false)
      toast.dark("Sedang upload", {position: "top-center"})
      try{
        const [waktuRealTime, waktuBenar] = await waktuRealtime()
        const namaFile = "ForumAnak" + auth.uid + waktuRealTime.seconds
        setWaktuUploadGbr(waktuRealTime.seconds)
        await uploadGambarFirebase(kanvasGambarProfil, cropBalas, 
          "fotoForum", namaFile, setUploadGBrSelesai, setURLGambar, false)
        setUploadGBrSelesai(false)
      }catch(e){
        toast.dark("Upload gagal", {position: "top-center"})
        setTblAktif(true)
      }
    }else if(auth){
      toast.dark("Pos anda kosong", {position: "top-center"})
    }else{
      toast.dark("Anda perlu login terlebih dahulu", {position: "top-center"})
    }
  }

  const handelHapusBalasan = () => {
    p.setBalasan(null)
  }

  const handelHapusMembalas = () => {
    if(inputRef && inputRef.current && inputRef.current.value){
      inputRef.current.value = ""
      setLogikaDummy(!logikaDummy)
    }
  }

  useEffect(() => {
    const uploadFirestore = async () => {
      if(uploadGbrSelesai){
        const adaKataKotor = cekKataKotor(inputRef.current?.value, "balasan")
        if(uploadGbrSelesai && auth 
            && inputRef.current?.value 
            && !adaKataKotor
          ){
          try{
            const [waktuDapat, waktuBenar] = await waktuRealtime()
            const idAnakForum = auth.uid + waktuUploadGbr
            let balasan = new DataAnakForum()
            balasan.idForum = p.idForum
            balasan.idAnakForum = idAnakForum
            balasan.idPengguna = auth.uid
            balasan.namaPengguna = auth.displayName
            balasan.isiAnakForum = inputRef.current?.value || null
            balasan.balasanAnakForum = p.balasan
            balasan.gambarAnakForum = URLGambarSedang
            balasan.tanggalBuatAnakForum = waktuDapat
            balasan.tanggalEditAnakForum = waktuDapat
            balasan.apakahBalasan = true
            await firebase.firestore().doc('koleksiAnakForum/' + idAnakForum).set(Object.assign({}, balasan))
            p.setUrutanAnFor(indeksUrutanAnakForum.TERBARU)
            setUploadGBrSelesai(false)
            handelHapusGbr()
            handelHapusBalasan()
            handelHapusMembalas()
            toast.dark("Upload berhasil", {position: "top-center"})
            p.refreshLamanTerbalik()
            setTblAktif(true)
          }catch(e){
            console.error(e)
            setUploadGBrSelesai(false)
            handelHapusGbr()
            handelHapusBalasan()
            handelHapusMembalas()
            toast.dark("Upload gagal", {position: "top-center"})
            setTblAktif(true)
          }
        }else{
          setTblAktif(true)
        }
      }
    }
    uploadFirestore()
  }, [uploadGbrSelesai])
  
  return(
    <div className='kontainer-balas-anak-forum-apl-animemo'>
      {p.balasan && 
      <div className='kontainer-input-membalas'>
        <h6 className='input-membalas'>
          {p.balasan}
        </h6>
        <button className='kontainer-gbr-tbl' onClick={handelHapusBalasan}>
          <IkonTutup />
        </button>
      </div>
      }
      { inputFokus && <div className='kontainer-input-gbr'>
        <InputGambar 
          setCropSelesai={setCropBalas}
          setKanvas={setKanvasGambarProfil}
        />
        <button className="kontainer-gbr-tbl" onClick={handelBukaGambar}>
          <IkonTutup />
        </button>
      </div>
      }
      <div className='kontainer-teks-tbl'>
        <div className='kontainer-teks'>
          <TextField 
            inputRef={inputRef} 
            multiline
            placeholder='Beri balasan kamu'
            variant='outlined'
            maxRows={5}
            fullWidth
          />
        </div>
        <button className="kontainer-gbr-tbl" onClick={() => {handelBukaGambar()}}>
          <IkonTambahGambar />
        </button>
        <button className="kontainer-gbr-tbl" 
          onClick={tblAktif ? handelUploadBalasan : undefined}
          style={tblAktif ? {} : {color: "#252525" }}
          >
          <IkonKirim />
        </button>
      </div>
    </div>
  )
}

interface IBalasForum{
  balasan: string | null
  setBalasan: Dispatch<string | null>
  idForum: string | null
  setUrutanAnFor: Dispatch<number>
  refreshLamanTerbalik: () => void
}

export default BalasForum