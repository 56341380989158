import {Button, Dialog, DialogActions, DialogTitle} from "@material-ui/core"
import { FC } from "react"


const DialogHapusUmum: FC<IDialogHapusForum> = (p) => {
  return(
    <Dialog
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={p.buka}
      keepMounted
    >
      <DialogTitle id="confirmation-dialog-title">{p.teks ? p.teks : "hapus balasan?"}</DialogTitle>
      <DialogActions>
        <Button onClick={() => {p.handelBatal && p.handelBatal()}} color="primary">
          Batal
        </Button>
        <Button onClick={() => {p.handelOK && p.handelOK()}} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  )
}

interface IDialogHapusForum{
  buka: boolean,
  teks?: string,
  handelBatal?: () => void
  handelOK?: () => void
}

export default DialogHapusUmum