import { DataForum } from "Data/DataForum";
import { Dispatch, FC, MutableRefObject } from "react";
import { Link } from "react-router-dom";
import IkonTutup from '@material-ui/icons/Close';
import IkonBendera from '@material-ui/icons/Flag'
import { useHistory } from "react-router";
import './IsiForum.scss'
import { useAuthState } from "react-firebase-hooks/auth";
import firebase from "firebase";
import hitungWaktu from "fungsi/hitungWaktu";
import useDimensiLayar from "fungsi/UseDimensiLayar";

const IsiForum: FC<IIsiForum> = (p) => {
  const [auth] = useAuthState(firebase.auth());
  const sejarah = useHistory()
  const {lebarLayar, tinggiLayar} = useDimensiLayar()
  const layarKecil = lebarLayar < 400
  const besarIkon = layarKecil ? '15px' : '25px'
  return(
    <li ref={p.indeks === p.indeksTerakhir ? p.refArTerakhir : null}
     onClick={() => {p.cacheLaman && p.cacheLaman(); 
     sejarah.push(`/h/forum-balasan/${p.isi.idForum}/terlama`)}}>
      <div className='isi-forum-apl-animemo'>
        {p.isi.gambarKecilForum && <img className='gbr-forum' src={p.isi.gambarKecilForum}/>}
        <div className="kontainer-isi-forum">
          <div className='isi-forum'>
            <div className='teks-forum'>
              <div className='kontainer-judul-forum'>
                <h4 className='judul-forum'>
                  {p.isi.judulForum}
                </h4>
                {
                  auth && auth.uid === p.isi.idPengguna ?
                  <button className='ikon-bendera' onClick={(e) => {e.stopPropagation()}}>
                    <IkonTutup style={{width: besarIkon, height: besarIkon}}
                      onClick={(e) => {
                        e.stopPropagation();
                        p.setHapusBuka(true);
                        p.setHapusForum(p.isi)
                      }}
                    />
                  </button>
                  :
                  <button className='ikon-bendera'>
                    <IkonBendera style={{width: besarIkon, height: besarIkon}} 
                      onClick={(e) => {e.stopPropagation(); 
                        p.setLaporkanBuka(true); 
                        p.setForumLaporkan(p.isi)}
                      }
                    />
                  </button>
                }
              </div>
              <h6 className='isi-forum'>
                {p.isi.isiForum}
              </h6>
            </div>
            <h6 className='tgl-forum'>
              {p.isi.tanggalBuatForum.toDate().toString}
            </h6>
          </div>
          <div className='statistik-bawah'>
            <h6 className='uploader'>
              {p.isi.namaPengguna}
            </h6>
            <h6 className='waktu'>
              {hitungWaktu(p.isi.tanggalBuatForum.seconds)}
            </h6>
          </div>
        </div>
      </div>
    </li>
  )
}

export default IsiForum

interface IIsiForum{
  indeks: number
  indeksTerakhir: number
  refArTerakhir: MutableRefObject<HTMLLIElement | null>
  isi: DataForum
  setHapusForum: Dispatch<DataForum | null>
  setLaporkanBuka: Dispatch<boolean>
  setHapusBuka: Dispatch<boolean>
  setForumLaporkan: Dispatch<DataForum | null>
  cacheLaman?: () => void,

}