import { Dispatch, FC, MutableRefObject, useEffect, useState } from 'react'
import './IsiForumAnak.scss'
import {DataAnakForum} from "Data/DataForum"
import IkonBendera from '@material-ui/icons/Flag'
import IkonAkun from '@material-ui/icons/AccountCircle'
import IkonHapus from '@material-ui/icons/Close';
import firebase from 'firebase';
import { Link } from 'react-router-dom'
import { useAuthState } from 'react-firebase-hooks/auth'
import hitungWaktu from 'fungsi/hitungWaktu'

const IsiForumAnak: FC<IIsiForumAnak> = (p) => {

  const storage = firebase.storage()
  const [urlGbr, setUrlGbr] = useState<string | undefined>(undefined)
  const [auth] = useAuthState(firebase.auth())

  useEffect(() => {
    const idPengguna = p.isi?.idPengguna
    const dapatkanFoto = async () => {
      if(idPengguna){
        try{
          const urlGbrDapat = await storage.ref('fotoProfil/fp' + idPengguna + 'Kecil').getDownloadURL()
          setUrlGbr(urlGbrDapat)
        }catch(e){
          console.log("fetch url eror")
          console.log(idPengguna)
          if(e instanceof Error){
            console.log(e.message)
          }
        }
      }
    }
    dapatkanFoto()
  }, [p.isi?.idPengguna])

  const handelBalas = () => {
    let stringDapat = p.isi.isiAnakForum?.substr(0, 99) || null
    if(stringDapat){
      stringDapat = "Mengutip " + p.isi.namaPengguna + ": "+ stringDapat + ( (p.isi.isiAnakForum?.length || 0) >= 100 ? " ..." : "")
    }
    p.setBalasanPengguna(stringDapat)
  }

  return(
    <li className='isi-forum-anak-apl-animemo'
      ref={p.indeks === p.indeksTerakhir ? p.refTerakhir : undefined}>
      <div className='header-anak-forum'>
        <Link to={'/p/' + p.isi.idPengguna}
          onClick={() => {p.cacheLaman()}}>
          <div className='kontainer-gbr'>
            {urlGbr ? <img className='foto-profil-anak-forum' src={urlGbr} /> 
            : <IkonAkun style={{width: '35px', height: '35px', marginRight: '5px'}}/>}
          </div>
        </Link>
        <div className='teks-header'>
          <h6 className='nama-pengguna'>
            {p.isi.namaPengguna}
          </h6>
          <h6 className='tgl-pos'>
            {hitungWaktu(p.isi.tanggalBuatAnakForum.seconds)}
          </h6>
        </div>
        {(auth && (auth.uid === p.isi.idPengguna))? 
        <button className='ikon-bendera' onClick={() => {
          p.setAnakForumHapus(p.isi)
          p.setBukaHapus(true)
          }}>
          <IkonHapus style={{width: '25px', height: '25px'}}/>
        </button>
        :
        <button className='ikon-bendera' onClick={() => {
          p.setAnakForumLaporkan(p.isi)
          p.setBukaLaporkan(true)
          }}>
          <IkonBendera style={{width: '25px', height: '25px'}}/>
        </button>}
      </div>
      {p.isi.balasanAnakForum &&
        <h6 className='balasan'>
          {p.isi.balasanAnakForum}
        </h6>
        }
      {p.isi.gambarAnakForum && <img className='gbr-pos' src={p.isi.gambarAnakForum}/>}
      <h6 className='isi-teks' onClick={handelBalas}>
        {p.isi.isiAnakForum}
      </h6>
    </li>
  )
}

export default IsiForumAnak

interface IIsiForumAnak{
  isi: DataAnakForum
  indeks: number
  indeksTerakhir: number
  refTerakhir: MutableRefObject<HTMLLIElement | null>
  cacheLaman: () => void,
  setBalasanPengguna: Dispatch<string | null>
  setBukaLaporkan: Dispatch<boolean>
  setAnakForumLaporkan: Dispatch<DataAnakForum>
  setBukaHapus: Dispatch<boolean>
  setAnakForumHapus: Dispatch<DataAnakForum | null>
}