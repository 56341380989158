import { useEffect, useState } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import firebase from 'firebase';
import { IPengguna } from "Data/Pengguna";
import { useDispatch } from "react-redux";
import { storePengguna } from "store/actions";


const useAuthAniMemo = () => {
    const [auth] = useAuthState(firebase.auth())
    const [pengguna, setPengguna] = useState<IPengguna | null>(null)
    const [dbPenggunaKosong, setDBPenggunaKosong] = useState(false)
    const [telahLogin, setTelahLogin] = useState(false)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(storePengguna(pengguna || null))
    }, [pengguna])
    useEffect(() => {
        const dbPenggunaDapat = auth?.uid ? firebase.firestore().doc('pengguna/' + auth.uid) : undefined
        const unsubscribe = dbPenggunaDapat?.onSnapshot(ss =>{
          if(ss.exists){
            const dokPengguna: any = ss.data()
            const penggunaDapat: IPengguna = dokPengguna
            setPengguna(penggunaDapat)
            setDBPenggunaKosong(false)
          }else if(!window.navigator.onLine){
            setDBPenggunaKosong(false)
          }else{
            setDBPenggunaKosong(true)
          }
        }, e => {
          setDBPenggunaKosong(false)
        })
    
        if(auth === null || auth === undefined){
          setTelahLogin(false)
          setPengguna(null)
        }else{
          setTelahLogin(true)
        }
        return () => { unsubscribe && unsubscribe()}
    }, [auth])

    return {pengguna, dbPenggunaKosong, setDBPenggunaKosong, telahLogin}
}

export default useAuthAniMemo