import {DataForum} from 'Data/DataForum'
import { DataLaporanPengguna } from 'Data/LaporanPengguna'
import { waktuRealtime } from 'fungsi/fungsiWaktu'
import { useAuthState } from 'react-firebase-hooks/auth'
import firebase from "firebase";
import { toast } from 'react-toastify';
import { useCallback } from 'react';

const useLaporkanForum = (forum: DataForum | null) => {
  const [auth] = useAuthState(firebase.auth())
  const handelLaporkanForum  = useCallback(async (nilaiLaporkan: number | null) => {
    if(forum){
      const [waktuSaatIni] = await waktuRealtime()
      const laporkan = new DataLaporanPengguna()
      const idPelapor = auth?.uid || "ANONIM"
      laporkan.idAnakForum = null
      laporkan.idForum = forum.idForum
      laporkan.idOrtuForum = forum.idOrtuForum
      laporkan.gambarAnakForum = null
      laporkan.gambarForum = forum.gambarForum
      laporkan.balasanAnakForum = null
      laporkan.idPelapor = auth?.uid || "ANONIM"
      laporkan.idPengguna = forum.idPengguna
      laporkan.isiAnakForum = null
      laporkan.isiForum = null
      laporkan.jenisLaporan = 1
      laporkan.namaPengguna = forum.namaPengguna
      laporkan.sebabLapor = nilaiLaporkan || 0
      laporkan.tanggalLapor = waktuSaatIni
      const dbLaporan = firebase.firestore()
        .doc('laporanDariPengguna/Lapor' + idPelapor + forum.idPengguna + waktuSaatIni.seconds)
      try{
        await dbLaporan.set(Object.assign({}, laporkan))
        toast.dark("Terlaporkan, terima kasih sudah melaporkan")
        return true
      }catch(e){
        const er = e as Error
        toast.dark("Laporkan forum gagal")
        return false
      }
    }else{
      return false
    }
  }, [forum])

  return handelLaporkanForum
}

export default useLaporkanForum