import firebase from 'firebase'

export class DataOrtuForum {
  idOrtuForum: string | null = null;
  judulOrtuForum: string | null = null;
  deskripsiOrtuForum: string | null = null;
  tanggalUploadOrtuForum: firebase.firestore.Timestamp = firebase.firestore.Timestamp.now();
  tanggalEditOrtuForum: firebase.firestore.Timestamp = firebase.firestore.Timestamp.now();
  prioritasOrtuForum: number | null = null;
  statusEditPengguna: boolean | null = null;
  fotoOrtuForum: string | null = null;
  fotoKecilOrtuForum: string | null = null;
}

export class DataForum {
  idOrtuForum: string | null = null;
  idForum: string | null = null;
  idPengguna: string | null = null;
  judulForum: string | null = null;
  isiForum: string | null = null;
  namaPengguna: string | null = null;
  gambarForum: string | null = null;
  gambarKecilForum: string | null = null;
  tanggalBuatForum: firebase.firestore.Timestamp = firebase.firestore.Timestamp.now();
  tanggalEditForum: firebase.firestore.Timestamp = firebase.firestore.Timestamp.now();
  terakhirDibalas: firebase.firestore.Timestamp = firebase.firestore.Timestamp.now();
  jumlahBalasan: number = 0;
  statusEditPengguna: boolean | null = false
}

export class DataAnakForum{
  idForum: string | null = null;
  idAnakForum: string | null = null;
  idPengguna: string | null = null;
  namaPengguna: string | null = null;
  isiAnakForum: string | null = null;
  balasanAnakForum: string | null = null;
  gambarAnakForum: string | null = null;
  tanggalBuatAnakForum: firebase.firestore.Timestamp = firebase.firestore.Timestamp.now();
  tanggalEditAnakForum: firebase.firestore.Timestamp = firebase.firestore.Timestamp.now();
  apakahBalasan: boolean | null = true;
}

export interface IFeeds{
  fotoFeeds: string | null;
  idFeeds: string | null;
  idPengguna: string | null;
  isiFeeds: string | null;
  jmlKomentar: number;
  jmlSuka: number;
  namaPengguna: string | null;
  idGambar: string | null;
  tanggalEdit: firebase.firestore.Timestamp
  tanggalUpload: firebase.firestore.Timestamp
}

export interface ISukaiFeeds{
  idPengguna: string | null
  idFeeds: string | null
  idPos: string | null 
  idYangNgepos: string | null
  statusSukai: boolean
  waktuSukai: firebase.firestore.Timestamp
}

export interface IKomentarFeeds{
  idFeeds: string | null
  idKomentarFeeds: string | null
  idPengguna: string | null
  idPemilikFeeds: string | null
  isiKomentar: string | null
  namaPengguna: string | null
  tanggalUpload: firebase.firestore.Timestamp
}




