import firebase from "firebase";

export class DataLaporkanPos{
  anakForum: string[] = [];
  forum: string[] = [];
  pengguna: string[] = []
}

export class DataLaporanPengguna{
  idAnakForum: string | null = null;
  idForum: string | null = null;
  idOrtuForum: string | null = null;
  gambarAnakForum: string | null = null;
  gambarForum: string | null = null;
  balasanAnakForum: string | null = null;
  idPelapor: string = "Anonim";
  idPengguna: string | null = null;
  isiAnakForum: string | null = null;
  isiForum: string | null = null;
  /**jenisLaporan: 0 Anak Forum, 1 Forum, 2 Pengguna */
  jenisLaporan: 0 | 1 | 2 = 0;
  namaPengguna: string | null = null;
  sebabLapor: number = 0;
  tanggalLapor: firebase.firestore.Timestamp = firebase.firestore.Timestamp.now();
}