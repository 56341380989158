import { TextField } from '@material-ui/core';
import IkonKirim from '@material-ui/icons/Send';
import { IFeeds, IKomentarFeeds } from 'Data/DataForum';
import useFetchFirestore from 'fungsi/useFetchFirestore';
import { FC, useRef, useState } from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import "./KomentarFeeds.scss"
import firebase from "firebase"
import IkonHapus from "./cross-hapus.svg"
import { usePengguna } from 'fungsi/usePengguna';
import { toast } from 'react-toastify';
import cekKataKotor from 'fungsi/cekKataKotor';
import { waktuRealtime } from 'fungsi/fungsiWaktu';
import DialogHapusUmum from 'laman/ForumAnak/Komponen/DialogHapusUmum';

const KomentarFeeds: FC<IKomentarFeedsProps> = (p) => {
  const inputRef = useRef<HTMLInputElement | null>(null)
  const db = firebase.firestore()
  const [arKomentar, refKomentarTerakhir, indeksKomentarTerakhir, loadingKomentar, dokKomentarTerakhirDalamLayar, _, refreshKomentar ] 
  = useFetchFirestore<IKomentarFeeds>(10, db.collection('komentarFeeds').where("idFeeds", "==", p.match.params.idFeeds).orderBy('tanggalUpload', 'asc'),
    `komentar-feeds-${p.match.params.idFeeds}`)
  const [bukaHapus, setBukaHapus] = useState(false)
  const idKomentarHapus = useRef<null | string>(null)
  const [loadingUpload, setLoadingUpload] = useState(false)
  const [pengguna] = usePengguna()
  const sejarah = useHistory()
  const handelHapusKomentar = async () => {
    try{
      const idKomentarDapat = idKomentarHapus.current
      console.log(idKomentarHapus.current)
      if(idKomentarDapat === null){
        toast.error("id komentar kosong")
        return
      }
      const refKomentarHapus = firebase.firestore().doc(`komentarFeeds/${idKomentarDapat}`)
      await refKomentarHapus.delete()
      toast.success("Sukses menghapus komentar")
      setBukaHapus(false)
      refreshKomentar()
    }catch(e){
      console.error(e);
      setBukaHapus(false)
      toast.error("Kesalahan hapus komentar")
    }
  }
  const handelTambahkanKomentar = async () => {
    setLoadingUpload(true)
    try{
      if(pengguna === undefined){
        setLoadingUpload(false)
        sejarah.push(`/h/login`)
        return
      }
      if(pengguna?.jenisPengguna?.statusBlokir){
        setLoadingUpload(false)
        toast.error("Anda diblokir")
        return
      }
      const komentarDapat = inputRef?.current?.value
      if(komentarDapat === undefined || komentarDapat === ""){
        setLoadingUpload(false)
        toast.error("Komentar kosong")
        return
      }
      if(cekKataKotor(inputRef?.current?.value)){
        setLoadingUpload(false)
        return
      }
      const [waktuDapat] = await waktuRealtime()
      const idFeeds = p.match.params.idFeeds
      const feeds = (await firebase.firestore().doc(`feeds/${idFeeds}`).get()).data() as (IFeeds | undefined | null)
      if(feeds === undefined || feeds === null){
        setLoadingUpload(false)
        toast.error("Feeds tidak ada")
        return
      }
      const komentarFeeds: IKomentarFeeds = {
        idFeeds: p.match.params.idFeeds,
        idKomentarFeeds: idFeeds + pengguna.uid + waktuDapat.seconds,
        idPengguna: pengguna.uid,
        idPemilikFeeds: feeds.idPengguna,
        isiKomentar: komentarDapat,
        namaPengguna: pengguna.namaPengguna,
        tanggalUpload: waktuDapat
      }
      const refKomentar = firebase.firestore().doc(`komentarFeeds/${komentarFeeds.idKomentarFeeds}`)
      await refKomentar.set(komentarFeeds)
      if(inputRef.current) {inputRef.current.value = ""}
      toast.success("Sukses upload komentar")
      refreshKomentar()
      setLoadingUpload(false)
    }catch(e){
      toast.error("Gagal upload komentar")
      console.error(e)
      setLoadingUpload(false)
    }
  }
  return(
    <div className='kontainer-seluruh-komentar-apl-animemo'>
      {arKomentar.length > 0 && <ul className='list-komentar'>
        {arKomentar.map((nilai, i) => <li className='isi-list-komentar' ref={i === indeksKomentarTerakhir ? refKomentarTerakhir : undefined}>
          <p><b>{nilai.namaPengguna}</b> {nilai.isiKomentar}</p>
          {nilai.idPengguna === pengguna?.uid && 
            <img 
              onClick={() => {idKomentarHapus.current = nilai.idKomentarFeeds; setBukaHapus(true)}}
              className='ikon-hapus' src={IkonHapus}/>}
        </li>)}
      </ul>}
      {arKomentar.length === 0 &&
        <p>
          Belum ada komentar, jadilah yang pertama!
        </p>
      }
      <div className='kontainer-teks-komentar-feeds'>
        <div className='kontainer-teks'>
          <TextField 
            inputRef={inputRef} 
            multiline
            placeholder='Beri balasan kamu'
            variant='outlined'
            maxRows={5}
            fullWidth
          />
        </div>
        {!loadingUpload && <button className="kontainer-gbr-tbl" 
          onClick={handelTambahkanKomentar}
          >
          <IkonKirim />
        </button>}
      </div>
      <DialogHapusUmum teks="anda yakin ingin menghapus feeds?"
      buka={bukaHapus} 
      handelBatal={() => {idKomentarHapus.current = null; setBukaHapus(false)}} 
      handelOK={() => {handelHapusKomentar()}}
      />
    </div>
  )
}

interface IKomentarFeedsProps extends RouteComponentProps<{idFeeds: string}>{}

export default KomentarFeeds