import { Button, TextField } from "@material-ui/core";
import { InputGambarV2, useUploadGbr } from "komponen/input/inputGambar/InputGambar";
import { ChangeEvent, FC, useRef, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { toast } from "react-toastify";
import "./TambahkanFeeds.scss"
import firebase from 'firebase';
import { waktuRealtime } from "fungsi/fungsiWaktu";
import { IFeeds } from "Data/DataForum";
import { IPengguna } from "Data/Pengguna";
import { useHistory } from "react-router-dom";
import axios from "axios"
import { IResponseVision, IRequestVision, IResponseUmum } from "Data/Request";
import Skeleton from 'react-loading-skeleton'
import { useDispatch } from "react-redux";
import { cacheData } from "store/actions";
import cekKataKotor from "fungsi/cekKataKotor";
import { usePengguna } from "fungsi/usePengguna";

const aplExpress = process.env.REACT_APP_API_EXPRESS

const TambahkanFeeds: FC<IPropFeeds> = ({}) => {
  const isiFeeds = useRef("")
  const urlGbrFeeds = useRef<string | null>(null)
  const idGbrFeeds = useRef<string | null>(null)
  const [reqVisionState, setReqVisionState] = useState<IResponseVision | null>(null)
  const [errorFeeds, setErrorFeeds] = useState<null | string>(null)
  const [setKanvas, kanvas, crop, setCrop, handelUploadGbr, setGbrKosong, loading] =
    useUploadGbr()
  const sejarah = useHistory()
  const handleTeksBerubah = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    isiFeeds.current = e.currentTarget.value
    const isiEror = (e.currentTarget.value.length > 2500) ? "Teks maksimal 2500 karakter" : null
    setErrorFeeds(isiEror)
  }
  const [auth] = useAuthState(firebase.auth())

  const [loadingGbrFeeds, setLoadingGbrFeeds] = useState(false)
  const uploadGbrFeeds = async () => {
    setLoadingGbrFeeds(true)
    try{
      const idRefGbr = idGbrFeeds.current
      if(idRefGbr){
        try{
          await firebase.storage().ref().child(`feeds/${idRefGbr}`).delete()
        }catch(e){
          console.error(e)
        }
      }
      const [waktu, waktuBenar] = await waktuRealtime()
      
      if(!waktuBenar || auth === null || auth === undefined) {
        toast.error("Belum login")
        console.error(waktuBenar, auth)
        setLoadingGbrFeeds(false)
        return
      }
      const idGbr = auth.uid + waktu.seconds + waktu.nanoseconds
      const uploadGbr = (await handelUploadGbr(idGbr, "feeds"))
      const urlGbr = uploadGbr.data?.urlDownloadGambarBesar
      if(!uploadGbr.success || urlGbr === null || urlGbr === undefined){
        toast.error("gagal upload gambar")
        console.error(uploadGbr)
        setLoadingGbrFeeds(false)
        return
      }
      const reqVision: IRequestVision = {
        jenisRequest: "REQUEST_CLIENT",
        urlGbr: urlGbr,
        id: idGbr
      }
      const data = (await axios.post(`${aplExpress}/cek-gambar`, reqVision)).data as IResponseUmum<IResponseVision> | null | undefined || null
      const dataVision = data?.data || null
      if(data?.status === 200){
        setReqVisionState(dataVision)
      }
      if(dataVision?.dewasa){
        await firebase.storage().ref().child(`feeds/${idGbr}`).delete()
      }else{
        urlGbrFeeds.current = urlGbr
      }
    }catch(e){
      toast.error("gagal upload gambar")
      console.error(e)
    }
    setLoadingGbrFeeds(false)
  }
  const [pengguna] = usePengguna()
  const [loadingUploadFeeds, setLoadingUploadFeeds] = useState(false)
  const dispatch = useDispatch()
  const handelPosFeeds = async () => {
    setLoadingUploadFeeds(true)
    const kataKotor = cekKataKotor(isiFeeds.current)
    try{
      if(kataKotor) {
        setLoadingUploadFeeds(false)
        return
      }
      if(pengguna === undefined){
        setLoadingUploadFeeds(false)
        sejarah.push(`/h/login`)
        return 
      }
      if(pengguna?.jenisPengguna?.statusBlokir){
        setLoadingUploadFeeds(false)
        toast.error("Anda diblokir")
        return
      }
      if(reqVisionState?.dewasa) {
        toast.error("Gambar dewasa, pilih gambar lain")
        setLoadingUploadFeeds(false)
        return
      }
      if(idGbrFeeds === null || urlGbrFeeds === null){
        toast.error("gambar kosong")
        setLoadingUploadFeeds(false)
        return
      }
      if(auth === null || auth === undefined){
        toast.error("belum login")
        setLoadingUploadFeeds(false)
        return
      }
      const [waktu, waktuBenar] = await waktuRealtime()
      if(!waktuBenar){
        toast.error("kesalahan waktu")
        setLoadingUploadFeeds(false)
        return
      }
      const idFeeds = auth.uid + waktu.seconds + waktu.nanoseconds

      const feeds: IFeeds = {
        fotoFeeds: urlGbrFeeds.current,
        idFeeds: idFeeds,
        idPengguna: auth.uid,
        isiFeeds: isiFeeds.current,
        jmlKomentar: 0,
        jmlSuka: 0,
        namaPengguna: pengguna.namaPengguna,
        tanggalEdit: waktu,
        tanggalUpload: waktu,
        idGambar: idGbrFeeds.current
      }
      const refFeeds = firebase.firestore().doc(`feeds/${idFeeds}`)
      await refFeeds.set(feeds)
      setLoadingUploadFeeds(false)
      dispatch(cacheData("cacheFeedTakValid", true))
      toast.success("Sukses upload feeds")
      sejarah.push("/h/forum-ortu")
    }catch(e){
      toast.error("Tidak dapat upload feeds")
    }
    setLoadingUploadFeeds(false)
  }
  return (
    <div className="tambahkan-feeds-apl-animemo">
      <h4>
        Tambahkan feeds
      </h4>
      <InputGambarV2 setKanvas={setKanvas} kanvas={kanvas} setCropSelesai={setCrop} 
          rasioAspek={1} 
          onPotongGambar={() => uploadGbrFeeds()}
          />
      <TextField label={"Isi feeds"}
        variant="filled"
        color='secondary'
        size='medium'
        onChange={(e) => handleTeksBerubah(e)}
        error={errorFeeds !== null}
        style={{width: "80%", marginTop: "10px"}}
      />
      {errorFeeds !== null && <p style={{color: "red", fontSize: "14px"}}>
        {`teks maksimal 2500 karakter`}
      </p>}
      {reqVisionState?.dewasa && <p style={{color: "red", fontSize: "14px"}}>
        {`Gambar yang anda pilih sepertinya mengandung unsur dewasa ` + 
          `(${reqVisionState?.ketelanjangan ? "ketelanjangan" : "kekerasan"})` + 
          `, mohon pilih gambar lain`
        }
      </p>}
      <div className="kontainer-tbl">
        {loadingGbrFeeds || loadingUploadFeeds ? 
          <Skeleton width='120px' height='36px'/> :
          <Button onClick={handelPosFeeds} color="primary" variant="contained" >
            Pos feeds
          </Button>}
      </div>
    </div>
  )
}



interface IPropFeeds{}

export default TambahkanFeeds