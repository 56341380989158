import { FC, useRef, useState } from 'react';
import './TambahkanForum.scss';
import { InputGambarV2 } from 'komponen/input/inputGambar/InputGambar';
import ReactCrop from 'react-image-crop';
import { Button, TextField } from '@material-ui/core';
import useUploadGbrForum from '../fungsi/useUploadGbrForum';
import useUploadData from '../fungsi/useUploadData';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { useDocumentDataOnce, useDocumentOnce } from 'react-firebase-hooks/firestore';
import firebase from 'firebase';
import { DataOrtuForum } from 'Data/DataForum';
import LoadingLaman from 'laman/loadingLaman/LoadingLaman';
import { toast } from 'react-toastify';
import { Close } from '@material-ui/icons';
import { useAuthState } from 'react-firebase-hooks/auth';

const TambahkanForum: FC<ITambahkanForum> = (p) => {

  const [setKanvas, kanvas, crop, setCrop, handelUploadGbr, setGbrKosong] = useUploadGbrForum()
  const [refInputIsi, refInputJudul, handelUploadData] = useUploadData(handelUploadGbr)
  const refOrtu = firebase.firestore().doc('ortuForumPengguna/' + p.match.params.idOrtuForum)
  const [doc] = useDocumentOnce<DataOrtuForum>(refOrtu)
  const [data, loadingData] = useDocumentDataOnce<DataOrtuForum>(refOrtu)
  const sejarah = useHistory()
  const [loading, setLoading] = useState(false)
  const [logikaHapus, setLogikaHapus] = useState(false)
  const [auth] = useAuthState(firebase.auth())

  const handelKlikUpload = async () => {
    const ortuForum = doc?.data()
    if(ortuForum){
      setLoading(true)
      const sukses = await handelUploadData(ortuForum.idOrtuForum, ortuForum.statusEditPengguna)
      if(sukses){
        sejarah.push('/h/forum/' + ortuForum.idOrtuForum + '/balasan-baru')
        toast.success("Forum ditambahkan silahkan refresh")
      }else{
      }
      setLoading(false)
    }
  }
  if(loadingData){
    return(<LoadingLaman />)
  }else if(auth && data){
    return (
      <section className='tambahkan-forum-apl-animemo'>
        <h5>
          Tambahkan forum
        </h5>
        <div className='isian-forum'>
          <TextField id='tf-teks-input' label={"Judul Forum"}
            variant="filled"
            color='secondary'
            size='medium'
            inputRef={refInputJudul}
            fullWidth
          />
        </div>
        <div className='kontainer-isian-foto'>
          <div className='isian-foto'>
            <InputGambarV2 setCropSelesai={setCrop} setKanvas={setKanvas} 
              kanvas={kanvas} logikaLuarHapusGbr={logikaHapus}/>
          </div>
          <button className='tbl-hapus-foto' onClick={() => {setLogikaHapus(!logikaHapus); console.log(logikaHapus); setGbrKosong()}}>
            <Close style={{width: "25px", height: "25px"}}/>
          </button>
        </div>
        <div className='isian-forum'>
          <TextField id='tf-teks-input' label={"Isi"}
            variant="filled"
            color='secondary'
            size='medium'
            inputRef={refInputIsi}
            multiline
            fullWidth
            maxRows={5}
          />
        </div>
        <div className='kontainer-tbl'>
          {(loading) ?
            <LoadingLaman />
            :
            <Button color='primary' variant='contained' onClick={() => {handelKlikUpload()}}>
              Tambahkan Forum
            </Button>
          }
        </div>
      </section>
    )
  }else if(data){
    return(
      <h6>
        Anda perlu login untuk menggunakan fitur itu
      </h6>
    )
  }else{
    return(
      <h6>
        Forum tidak ditemukan
      </h6>
    )
  }
}

export default TambahkanForum

interface ITambahkanForum extends RouteComponentProps<{idOrtuForum: string}> {
}