import { FC } from "react";
import './NavigasiBawah.scss'
import LogoBerandaIkon from '@material-ui/icons/HomeOutlined';
import LogoAwanOutline from '@material-ui/icons/CloudQueue';
import IkonListAnime from '@material-ui/icons/ListAlt';
import LogoChat from '@material-ui/icons/Chat';
import { Link } from "react-router-dom";

const NavigasiBawah: FC<INavigasiBawah> = (p) => {
  return(
    <ul className='navigasi-bawah-website'>
      <IsiNavigasi gbr={<LogoBerandaIkon/>} teks={'Beranda'} kunci={0} tautan={'/'}/>
      {/* <IsiNavigasi gbr={<LogoAkunOutline/>} teks={'Akun'}/> */}
      <IsiNavigasi gbr={<LogoChat/>} teks={'Forum'} kunci={1} tautan={'/h/forum-ortu'} />
      <IsiNavigasi gbr={<LogoAwanOutline/>} teks={'Musiman'} kunci={1} tautan={'/h/musiman/ini'}/>
      <IsiNavigasi gbr={<IkonListAnime/>} teks={'Daftarku'} kunci={2} tautan={'/h/daftar/nonton'}/>
    </ul>
  )
}

const IsiNavigasi: FC<IIsiNavigasi> = (p) => {
  return(
    <li>
      <Link to={p.tautan} style={{textDecoration: 'none'}}>
        <div className='isi-navigasi-bawah-website' key={p.kunci}>
          {p.gbr}
          <h6>
            {p.teks}
          </h6>
        </div>
      </Link>
    </li>
  )
}


interface INavigasiBawah {

}

interface IIsiNavigasi{
  gbr: JSX.Element;
  teks: string;
  tautan: string;
  kunci: number;
}

export default NavigasiBawah