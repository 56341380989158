import { FC, useEffect, useState, ChangeEvent, useRef } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import firebase from 'firebase';
import {RouteComponentProps} from 'react-router-dom';
import InputUmum from '../../komponen/input/inputUmum/InputUmum';
import InputTeks from '../../komponen/input/inputTeks/InputTeks';
import InputTanggal from '../../komponen/input/inputTanggal/InputTanggal';
import InputGambar, {uploadGambarFirebase} from '../../komponen/input/inputGambar/InputGambar'
import { FingerprintOutlined } from '@material-ui/icons';
import InputRadio from '../../komponen/input/inputRadio';
import EventIcon from '@material-ui/icons/Event';
import DataGender from '../../Data/DataGender'
import WcIcon from '@material-ui/icons/Wc';
import InputCekBoks from '../../komponen/input/inputCekBoks/InputCekBoks';
import FavoriteIcon from '@material-ui/icons/Favorite';
import DataGenre from '../../Data/DataGenre';
import DataProvinsi from '../../Data/DataProvinsi'
import AddLocationIcon from '@material-ui/icons/AddLocation';
import LoadingLaman from '../loadingLaman/LoadingLaman'
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDocument } from 'react-firebase-hooks/firestore';
import { Redirect } from 'react-router';
import { IPengguna, IJenisPengguna, IEditPengguna } from '../../Data/Pengguna';
import IkonAkunLingkaran from '@material-ui/icons/AccountCircle';
import Tombol from '@material-ui/core/Button';
import { toast } from 'react-toastify';
import {waktuRealtime} from '../../fungsi/fungsiWaktu'
import ReactCrop from 'react-image-crop';
import './LoginPengguna.scss';
import {Checkbox, FormGroup, Button, FormControlLabel} from '@material-ui/core';
import { ar } from 'date-fns/locale';
import useCekOnline from 'fungsi/useCekOnline'
import cekKataKotor from 'fungsi/cekKataKotor';

const uiConfig = {
  signInFlow: 'popup',
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    firebase.auth.FacebookAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    signInSuccessWithAuthResult: () => false,
    //coba sudah selesai
  },
};

const LoginPengguna: FC<ILoginPengguna> = (p) =>{
  const editAkun = p.editAkun !== undefined && p.editAkun

  // loadingLaman
  const [loading, setLoading] = useState(true);
  const [tblBisaKlik, setTblBisaKlik] = useState(true);

  //firebase
  const [authPengguna, loadingAuth, erorAuth] = useAuthState(firebase.auth())
  const dbPengguna = firebase.firestore().doc('pengguna/' + authPengguna?.uid)
  const [ssPengguna, loadingFStore, _erorFBase] = useDocument(dbPengguna)
  const [pengguna, setPengguna] = useState<IPengguna | null>(null)

  useEffect(() => {
    const loadData = async () => {
      await new Promise((r) => setTimeout(r, 500));
      setLoading((loading) => !loading);
    };
    loadData();
  }, [])



  //isian
  const [tampilkanSeluruhEror, setTampilkanSeluruhEror] = useState(false)

  //nama
  const maksNama = 25
  const [teksNama, setTeksNama] = useState<string | null>(null)
  const refNama = useRef<HTMLInputElement | null>(null)
  const [teksAwalNama, setTeksAwalNama] = useState<string | null | undefined>(null)
  useEffect(() =>{
    setTeksAwalNama(authPengguna?.displayName || null)
    setTeksNama(authPengguna?.displayName || null)
  }, [authPengguna])  
  const [kurangDariNamaMaks, setKDNamaMaks] = useState(true)
  const kurDarNama: string = !kurangDariNamaMaks ? `Maksimal ${maksNama} karakter` : 'eror lainnya'
  const [erTeksKosong, setErTeksKosong] = useState<boolean>(true)
  useEffect(() => {
    if(teksNama != null && teksNama?.length == 0){
      setErTeksKosong(true)
    }else if(teksNama == null){
      setErTeksKosong(true)
    }else{
      setErTeksKosong(false)
    }
  }, [teksNama])
  
  //tanggal lahir
  const [tanggalTerpilih, setTanggalTerpilih] = useState<Date | null>(null);
  const handelTanggalBerubah = (date: Date | null) => {
    setTanggalTerpilih(date);
  };

  //tentangPengguna 
  const maksTentangPengguna = 150
  const [teksTentangPengguna, setTeksTentangPengguna] = useState<string | null>(null)
  const refTentangPengguna = useRef<HTMLInputElement | null>(null)
  const [kurangDariTentang, setKDTentang] = useState(true)
  const teksErorTentang: string = !kurangDariTentang ? `Maksimal ${maksTentangPengguna} karakter` : 'eror lainnya'

  const arGender = (new DataGender()).arrayGender
  const [genderTerpilih, setGenderTerpilih] = useState<number | null >(null)
  const [erorGender, setErorGender] = useState(true)
  const [erTentangKosong, setErTentangKosong] = useState<boolean>(true)
  useEffect(() => {
    setErorGender(genderTerpilih == null)
  }, [genderTerpilih])
  const teksErorGender = 'pilih gender';
  useEffect(() => {
    if(teksTentangPengguna != null && teksTentangPengguna?.length == 0){
      setErTentangKosong(true)
    }else if(teksTentangPengguna == null){
      setErTentangKosong(true)
    }else{
      setErTentangKosong(false)
    }
  }, [teksTentangPengguna])

  const arGenre = (new DataGenre()).arGenre
  const [arGenreTerp, setArGenreTerp] = useState<Array<boolean>>(
    () => {
      let arGenreTerpilih: Array<boolean> = []
      for (let i = 0; i < arGenre.length; i++){
        arGenreTerpilih.push(false)
      }
      return arGenreTerpilih
    }
  )


  const [arGenreTerpString, setArGenreTerpString] = useState<Array<string>>([])
 
  const [erGenreTerp, setErGenreTerp] = useState<boolean>(true)

  useEffect(()=>{
    const dokPengguna: any = ssPengguna?.data()
    const penggunaDapat: IPengguna | null = dokPengguna ? dokPengguna : null
    setPengguna(penggunaDapat)
    if(editAkun && penggunaDapat){
      setTeksAwalNama(penggunaDapat.namaPengguna)
      const tanggal = penggunaDapat.tanggalLahirPengguna?.toDate() || null
      setTanggalTerpilih(tanggal)
      setGenderTerpilih(penggunaDapat.genderPengguna)
      if(penggunaDapat.genreFavorit){
        for(let j = 0; j < arGenre.length; j++){
          arGenreTerp[j] = penggunaDapat.genreFavorit.includes(arGenre[j]) 
        }
      }
      const baru = arGenreTerp
      setArGenreTerp(baru)
      setTeksTentangPengguna(penggunaDapat.tentangPengguna)
      setProvTerpilih(penggunaDapat.lokasiAsal)
    }
  },[ssPengguna])

  //lokasi asal
  const arProvinsi = (new DataProvinsi()).arrayProvinsi
  const [provTerpilih, setProvTerpilih] = useState<number | null >(null)
  const [erProv, setErProv] = useState(true)
  useEffect(() => {
    setErProv(provTerpilih == null)
  }, [provTerpilih])
  const teksErProv = 'pilih gender';

  //gambar
  const [cropGambarProfil, setCropGambarProfil] = useState<ReactCrop.Crop | null>(null) 
  const [kanvasGambarProfil, setKanvasGambarProfil] = useState<HTMLCanvasElement | null>(null)
  const [URLGambarBesar, setURLGambarBesar] = useState<string | null>(null)
  const [URLGambarSedang, setURLGambarSedang] = useState<string | null>(null)
  const [URLGambarKecil, setURLGambarKecil] = useState<string | null>(null)
  const setURLGambar = (urlGambarBesarInput: string | null
    , urlGambarSedangInput: string | null, urlGambarKecilInput: string | null) => {
    setURLGambarBesar(urlGambarBesarInput)
    setURLGambarSedang(urlGambarSedangInput)
    setURLGambarKecil(urlGambarKecilInput)
  }

  //setuju kebijakan privasi
  const [setujuKebPriv, setSetujuKebPriv] = useState<boolean>(editAkun ? true : false)
  const handelSetuju = (event: ChangeEvent<HTMLInputElement>) => {
    setSetujuKebPriv(event.target.checked)
  }

  //upload
  const [gambarTerupload, setGambarTerupload] = useState<boolean>(false)
  const [akunTerupload, setAkunTerupload] = useState<boolean>(false)
  useEffect(() => {
    if(gambarTerupload){
      if(authPengguna !== null && authPengguna !== undefined && tanggalTerpilih){
        if(editAkun && pengguna){
          const penggunaUpload: IEditPengguna = {
            namaPengguna: refNama?.current?.value ? refNama.current.value : "-",
            genderPengguna: genderTerpilih,
            genreFavorit: arGenreTerpString,
            lokasiAsal: provTerpilih,
            tentangPengguna: refTentangPengguna?.current?.value ? refTentangPengguna.current.value : null,
            tanggalLahirPengguna: firebase.firestore.Timestamp.fromDate(tanggalTerpilih),
            
            urlFotoProfilBesarPengguna: URLGambarBesar ? URLGambarBesar : pengguna.urlFotoProfilBesarPengguna,
            urlFotoProfilPengguna: URLGambarSedang ? URLGambarSedang : pengguna.urlFotoProfilPengguna,
            urlFotoProfilKecilPengguna: URLGambarKecil ? URLGambarKecil : pengguna.urlFotoProfilKecilPengguna,
          }
          firebase.auth().currentUser?.updateProfile({
            displayName: penggunaUpload.namaPengguna,
            photoURL: penggunaUpload.urlFotoProfilPengguna,
          })
          const refDBPengguna = firebase.firestore().doc('pengguna/' + authPengguna.uid)
          refDBPengguna.set(penggunaUpload, {merge: true}).then((ss) => {
            setAkunTerupload(true)
            p.setDBPenggunaKosong && p.setDBPenggunaKosong(false)
          }).catch((e) => {
            toast.dark('eror upload data')
            p.setDBPenggunaKosong && p.setDBPenggunaKosong(true)
            setAkunTerupload(false)
          })
        }else{
          const jenisPengguna: IJenisPengguna = {
            pengguna: true,
            admin: false,
            moderator: false,
            editor: false,
            statusBlokir: false,
            premium: false,
            soelthan: false
          }

          const penggunaUpload: IPengguna = {
            uid: authPengguna.uid,
            email: authPengguna.email,
            namaPengguna: refNama?.current?.value ? refNama.current.value : "-",
            genderPengguna: genderTerpilih,
            genreFavorit: arGenreTerpString,
            lokasiAsal: provTerpilih,
            tentangPengguna: refTentangPengguna?.current?.value ? refTentangPengguna.current.value : null,
            tanggalLahirPengguna: firebase.firestore.Timestamp.fromDate(tanggalTerpilih),
            jenisPengguna: jenisPengguna,

            pengikut: 0,
            mengikuti: 0,
            
            urlFotoProfilBesarPengguna: URLGambarBesar,
            urlFotoProfilPengguna: URLGambarSedang,
            urlFotoProfilKecilPengguna: URLGambarKecil,
          }
          console.log("masuk sini")
          firebase.auth().currentUser?.updateProfile({
            displayName: penggunaUpload.namaPengguna,
            photoURL: penggunaUpload.urlFotoProfilPengguna,
          })
          const refDBPengguna = firebase.firestore().doc('pengguna/' + authPengguna.uid)
          refDBPengguna.set(penggunaUpload, {merge: true}).then((ss) => {
            setAkunTerupload(true)
            p.setDBPenggunaKosong && p.setDBPenggunaKosong(false)
          }).catch((e) => {
            toast.dark('eror upload data')
            p.setDBPenggunaKosong && p.setDBPenggunaKosong(true)
            setAkunTerupload(false)
          })
        }
      }
    }
  }, [gambarTerupload])

  useEffect(() => {
    if(editAkun && gambarTerupload && akunTerupload){
      setTblBisaKlik(true)
      setAkunTerupload(false)
      setGambarTerupload(false)
      toast.success("data terupload")
    }
  }, [gambarTerupload, akunTerupload])

  const handelBuatAkun = async () => {
    setTblBisaKlik(false)
    const [waktuSaatIni, waktuSaatIniBenar] = await waktuRealtime()
    const umurTigaBelas = 13 * 365 * 24 * 3600
    const umurBenar = waktuSaatIniBenar 
    && ((waktuSaatIni.seconds - ((tanggalTerpilih?.getTime() || waktuSaatIni.seconds) / 1000)) > umurTigaBelas)
    if(erorGender ){
      toast.dark('mohon pilih gender terlebih dahulu')
      setTblBisaKlik(true)
    }else if(erTeksKosong){
      toast.dark('mohon isi nama kamu')
      setTblBisaKlik(true)
    }else if(!(refNama?.current?.value && refNama.current.value.length < maksNama)){
      toast.dark(`namamu maksimal ${maksNama} karakter`)
      setKDNamaMaks(false)
      setTblBisaKlik(true)
    }else if(!(refTentangPengguna?.current?.value !== undefined && refTentangPengguna.current.value.length !== 0)){
      toast.dark(`ceritakan sedikit tentang kamu`)
      setTblBisaKlik(true)
    }else if(!(refTentangPengguna?.current?.value !== undefined && refTentangPengguna.current.value.length < maksTentangPengguna)){
      toast.dark(`tentang anda harus kurang dari ${maksTentangPengguna} karakter`)
      setKDTentang(false)
      setTblBisaKlik(true)
    }else if(erProv){
      toast.dark('pilih lokasi asal kamu')
      setTblBisaKlik(true)
    }else if(arGenreTerp.every((v: boolean) => v === false)){
      toast.dark('pilih genre favorit kamu')
      setTblBisaKlik(true)
    }else if(tanggalTerpilih == null){
      toast.dark('pilih tanggal lahir kamu')
      setTblBisaKlik(true)
    }else if(!umurBenar){
      toast.dark('umur anda minimal 13 tahun')
      setTblBisaKlik(true)
    }else if(!setujuKebPriv){
      toast.dark('Anda belum menyetujui kebijakan privasi kami')
      setTblBisaKlik(true)
    }else{
      toast.dark('sedang upload data')
      const namaKataKasar = cekKataKotor(refNama.current?.value, "Nama")
      const tentangKataKasar = cekKataKotor(refTentangPengguna.current?.value, "Tentang")
      if(authPengguna != null && !namaKataKasar && !tentangKataKasar){
        try{
          await uploadGambarFirebase(kanvasGambarProfil, cropGambarProfil, 'fotoProfil'
          , authPengguna.uid, setGambarTerupload, setURLGambar, true)
        }catch(e){
          toast.dark('eror upload data')
          setTblBisaKlik(true)
        }
      }else{
        setTblBisaKlik(true)
      }
    }
  } 

  const [online] = useCekOnline()
  
  // if(true){
  //   return <div>Laman sedang dibangun</div>
  // }
  // else
  useEffect(() => {
    console.log(loading, loadingAuth, loadingFStore)
  }, [loading, loadingAuth, loadingFStore])
  if(loading || loadingAuth || loadingFStore || !online){
    return <LoadingLaman/>
  }else{
    if(ssPengguna?.data() === undefined || editAkun && !akunTerupload){
      if((authPengguna !== null) && authPengguna !== undefined && (!erorAuth)){
        return (
          <div className='konten-login-pengguna'>
            <div className="kontainer-kontainer-input-gbr">
              <div className="kontainer-input-gambar">
                <InputGambar
                  sbrGambar={pengguna?.urlFotoProfilPengguna || undefined}
                  gbrPlaceHolder={IkonAkunLingkaran}
                  gambarLingkaran={true}
                  rasioAspek={1/1}
                  setKanvas={setKanvasGambarProfil}
                  setCropSelesai={setCropGambarProfil}
                  />
              </div>
            </div>
            <InputUmum
              gambar={FingerprintOutlined}
              elemenInput={<InputTeks
                teksLabel='Namamu'
                isiAwal={teksAwalNama}
                teksIsi={teksNama}
                inputRef={refNama}
                kurangDariMaks={kurangDariNamaMaks}
                setKurangDariMaks={setKDNamaMaks}
                kataMaksimal={maksNama}
                />}
              teksEror={kurDarNama}
              apakahEror={!(kurangDariNamaMaks)}
            />
            <InputUmum
              gambar={EventIcon}
              elemenInput={
                <InputTanggal
                  label='Tanggal lahir kamu'
                  handelTanggalBerubah={handelTanggalBerubah}
                  tanggalTerpilih={tanggalTerpilih}
                />
              }
            />
            <InputUmum
              gambar={AddLocationIcon}
              elemenInput={
                <InputRadio
                  judulPilihan='Lokasi asal'
                  isiPilihan={arProvinsi}
                  pilihanKosongString='Pilih lokasi asal'
                  pilihanTerpilih={provTerpilih}
                  onTerpilih={setProvTerpilih}
                />
              }
              teksEror={teksErProv}
              apakahEror={erProv}
              tampilkanEror={tampilkanSeluruhEror}
            />
            <InputUmum
              gambar={WcIcon}
              elemenInput={
                <InputRadio
                  judulPilihan='Pilih gender'
                  isiPilihan={arGender}
                  pilihanKosongString='Pilih gender'
                  pilihanTerpilih={genderTerpilih}
                  onTerpilih={setGenderTerpilih}
                />
              }
              teksEror={teksErorGender}
              apakahEror={erorGender}
              tampilkanEror={tampilkanSeluruhEror}
            />
            <InputUmum
              gambar={FavoriteIcon}
              elemenInput={
                <InputCekBoks
                  judulPilihan='Pilih Genre Favorit'
                  isiPilihan={arGenre}
                  arTerpilih={arGenreTerp}
                  onTerpilih={setArGenreTerp}
                  pilihanKosongString='Pilih Genre Favorit'
                  setStringTerpilih={setArGenreTerpString}
                  setErTeksTerpilih={setErGenreTerp}
                />
              }
            />
            <InputUmum
              elemenInput={
                <InputTeks
                  teksLabel='Tentang kamu'
                  teksIsi={teksTentangPengguna}
                  inputRef={(refTentangPengguna || undefined)}
                  setKurangDariMaks={setKDTentang}
                  kurangDariMaks={kurangDariTentang}
                  kataMaksimal={maksTentangPengguna}
                  multiGaris={true}
                  maksGaris={5}
                  lebarMaksimal={true}
                />
              }
              teksEror={teksErorTentang} 
              apakahEror={!(kurangDariTentang)}
            />
            {!editAkun && <FormControlLabel 
            control={<Checkbox 
              key={0}
              checked={setujuKebPriv}
              onChange={handelSetuju}
              name={'setuju kebijakan privasi'}
            />} 
            label={<h5>
              dengan mencek boks saya setuju dengan <a className='App-link' 
              href='https://animemoku.wordpress.com/2021/03/23/kebijakan-privasi/'
              target="_blank"
              rel="noopener noreferrer"
              >kebijakan privasi</a> animemo
            </h5>} />}
            <div className='kontainer-tombol-buat-akun-login'>
              <Tombol
                className='tombol-buat-akun-login'
                color={tblBisaKlik ? 'primary' : 'secondary'}
                variant='contained'
                onClick={tblBisaKlik ? handelBuatAkun : undefined}
                >
                {editAkun ? "Edit Akun" : "Buat akun"}
              </Tombol>
            </div>
            {(gambarTerupload && akunTerupload) && <div>
              gambarTerupload
            </div>}
          </div>
        )
      }else{
        return <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      }
    }else{
      return <Redirect to='/'/>
    }
  }
}

export default LoginPengguna


interface ILoginPengguna{
  setDBPenggunaKosong?: (dbPenggunaKosong: boolean) => void;
  editAkun?: boolean
}