import firebase from "firebase";
import {iRedirect} from "../Data/Redirect"
import {FC} from 'react';

export const AniRedirect: FC<IAniRedirect> = (prop) =>  {
  fRedirect(prop.lokasi)
  return (
  <div className="konten">
    <h2>
      {'Sedang mengarahkan kamu ke laman download...'}
    </h2>
    <a href='https://play.google.com/store/apps/details?id=com.animemodev.animemo'>
      klik di sini jika tidak diarahkan ke download
    </a>
  </div>
  )
}

interface IAniRedirect{
  lokasi: string;
}


async function tambahRedirect(tautan: string | undefined){
  const db = firebase.firestore()
  const dokRedirect: any = (await (db.collection('nilaiServer').doc('redirect').get())).data()
  const redirect: iRedirect = dokRedirect
  switch(tautan){
    case 'instagram':
      redirect.instagram = (redirect.instagram || 0 )+ 1
      break;
    case 'download':
      redirect.download = (redirect.download || 0) + 1
      break;
    case 'iklanFB':
      redirect.iklanFB = (redirect.iklanFB || 0) + 1
      break;
    default:
      (redirect as any)[tautan || "takDikenal"] 
      = ((redirect as any)[tautan || "takDikenal"] || 0) + 1
  }
  await db.collection('nilaiServer').doc('redirect').update(redirect);
}

export async function fRedirect(tautan: string | undefined){
  await tambahRedirect(tautan)
  window.location.href = 'https://play.google.com/store/apps/details?id=com.animemodev.animemo'; 
}