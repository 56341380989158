import useFetchFirestore from "fungsi/useFetchFirestore";
import { FC, useEffect, useMemo, useState } from "react";
import { RouteComponentProps, useHistory, useLocation } from "react-router";
import IsiForum from "../Komponen/IsiForum/src/IsiForum";
import firebase from "firebase";
import './Forum.scss'
import LoadingLaman from "laman/loadingLaman/LoadingLaman";
import { DataForum, DataOrtuForum } from "Data/DataForum";
import { useDocumentDataOnce } from "react-firebase-hooks/firestore";
import DialogHapusUmum from "laman/ForumAnak/Komponen/DialogHapusUmum";
import InputRadioDialog from "komponen/input/InputRadioDialog";
import useLaporkanForum from "../fungsi/useLaporkanForum";
import useIsiLaporkan from "../fungsi/useIsiLaporkan";
import useHapusForum from "laman/ForumAnak/Komponen/fungsi/useHapusForum";
import { Fab } from '@material-ui/core';
import IkonTambah from '@material-ui/icons/Add'


const Forum: FC<IForum> = (p) => {
  const [urutanForumTerpilih, setUrutanForumTerpilih] = useState(
    p.match.params.slugTab === 'balasan-baru' ? indeksUrutanForum.BALASAN_TERBARU : indeksUrutanForum.FORUM_TERBARU
  )
  const sejarah = useHistory()

  const [arForum, refForumTerakhir, indeksForumTerakhir, loading, dokTerakhirDalamLayar, cacheLaman] =
  useFetchFirestore<DataForum>(
    10, 
    (firebase.firestore().collection('forumPengguna')
      .where('idOrtuForum', '==', p.match.params.idOrtuForum)
      .orderBy('tanggalBuatForum', 'desc')), 
    'forum' + p.match.params.idOrtuForum,
    urutanForumTerpilih,
    undefined,
    500,
    indeksUrutanForum.FORUM_TERBARU
    )

  //ar forum yang terakhir dibalas
  const [arForumTerDibalas, 
    refForumTerakhirTerDibalas, 
    indeksForumTerakhirTerDibalas, 
    loadingTerDibalas, 
    dokTerakhirDalamLayarTerDibalas, 
    cacheLamanTerDibalas] =
  useFetchFirestore<DataForum>(
    10, 
    (firebase.firestore().collection('forumPengguna')
      .where('idOrtuForum', '==', p.match.params.idOrtuForum)
      .orderBy('terakhirDibalas', 'desc')), 
    'forum' + 'balasan' + p.match.params.idOrtuForum,
    urutanForumTerpilih,
    undefined,
    500,
    indeksUrutanForum.BALASAN_TERBARU,
    )

  const [ortuForum, loadingOrtu, er] 
  = useDocumentDataOnce<DataOrtuForum>(firebase.firestore().doc('ortuForumPengguna/' + p.match.params.idOrtuForum))

  useEffect(() => {
    switch(p.match.params.slugTab){
      case 'forum-baru':
        setUrutanForumTerpilih(indeksUrutanForum.FORUM_TERBARU);
        break;
      default:
        setUrutanForumTerpilih(indeksUrutanForum.BALASAN_TERBARU)
    }
  }, [p.match.params.slugTab])

  const [forumDihapus, setForumDihapus] = useState<DataForum | null>(null)
  const [bukaLaporkan, setBukaLaporkan] = useState(false)
  const [bukaHapus, setBukaHapus] = useState(false)
  const handelHapusForum = useHapusForum()

  const handelKlikTab = (indeksTerpilih: number) => {
    setUrutanForumTerpilih(indeksTerpilih)
    const slugDapat: TSlugTab = indeksTerpilih === indeksUrutanForum.BALASAN_TERBARU ? 'balasan-baru' : 'forum-baru'
    sejarah.push(`/h/forum/${p.match.params.idOrtuForum}/${slugDapat}`)
  }

  const [isiLaporkan] = useIsiLaporkan()
  const [forumLaporkan, setForumLaporkan] = useState<DataForum | null>(null)
  const handelLaporkanForum = useLaporkanForum(forumLaporkan)

  const [dokOrtu] = useDocumentDataOnce<DataOrtuForum>(firebase.firestore().doc('ortuForumPengguna/' + p.match.params.idOrtuForum))

  return(
    <>
      <div className='forum-apl-animemo'>
        <h3 className='judul-forum'>
          {ortuForum?.judulOrtuForum}
        </h3>
        <div className='pilihan-urutan'>
          <h5 className={urutanForumTerpilih === indeksUrutanForum.BALASAN_TERBARU ? 'urutan-terpilih' : 'urutan'}
          onClick={() => {handelKlikTab(indeksUrutanForum.BALASAN_TERBARU)}}>
            Balasan terbaru
          </h5>
          <h5 className={urutanForumTerpilih === indeksUrutanForum.FORUM_TERBARU ? 'urutan-terpilih' : 'urutan'} 
          onClick={() => {handelKlikTab(indeksUrutanForum.FORUM_TERBARU)}}>
            Forum terbaru
          </h5>
        </div>
        <ul className='list-isi-forum'>
          {urutanForumTerpilih === indeksUrutanForum.BALASAN_TERBARU ?      
            arForumTerDibalas.map((nilai, indeks) => {
              return <IsiForum indeks={indeks} 
                indeksTerakhir={indeksForumTerakhirTerDibalas}
                refArTerakhir={refForumTerakhirTerDibalas}
                isi={nilai}
                cacheLaman={cacheLamanTerDibalas}
                setHapusBuka={setBukaHapus}
                setLaporkanBuka={setBukaLaporkan}
                setForumLaporkan={setForumLaporkan}
                setHapusForum={setForumDihapus}
              />
            })
          :
            arForum.map((nilai, indeks) => {
              return <IsiForum indeks={indeks} 
                indeksTerakhir={indeksForumTerakhir}
                refArTerakhir={refForumTerakhir}
                isi={nilai}
                cacheLaman={cacheLaman}
                setHapusBuka={setBukaHapus}
                setLaporkanBuka={setBukaLaporkan}
                setForumLaporkan={setForumLaporkan}
                setHapusForum={setForumDihapus}
              />
            })
          }
        </ul>
        {(urutanForumTerpilih === indeksUrutanForum.BALASAN_TERBARU && loadingTerDibalas) && <LoadingLaman/>}
        {(urutanForumTerpilih === indeksUrutanForum.FORUM_TERBARU && loading) && <LoadingLaman/>}
        {
        dokOrtu?.statusEditPengguna && 
          <div className='fab-tambahkan-forum'>
            <Fab color='primary'  onClick={() => {sejarah.push('/h/tambahkan-forum/' + p.match.params.idOrtuForum)}}>
              <IkonTambah />
            </Fab>
          </div>
        }
      </div>
      <DialogHapusUmum buka={bukaHapus} handelBatal={() => {setBukaHapus(false)}}
        handelOK={async() => {
          const sukses = await handelHapusForum(forumDihapus);
          sukses && setBukaHapus(false);
        }}
       />
      <InputRadioDialog 
        judulPilihan={"Laporkan Forum"}
        isiPilihan={isiLaporkan.forum} 
        pilihanTerpilih={0}
        buka={bukaLaporkan}
        setBuka={setBukaLaporkan}
        onOK={async (nilai) => {
          const sukses = await handelLaporkanForum(nilai);
          if(sukses){
            setBukaLaporkan(false);
            setForumLaporkan(null)
          }
        }}
        onBatal={() => {
          setBukaLaporkan(false);
          setForumLaporkan(null);
        }}
      />
    </>
  )
}

export const indeksUrutanForum = {
  BALASAN_TERBARU: 0,
  FORUM_TERBARU: 1,
}

export default Forum

interface IForum extends RouteComponentProps<{idOrtuForum: string, slugTab: TSlugTab}> {

}

type TSlugTab = 'balasan-baru' | 'forum-baru'