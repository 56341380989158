import firebase from 'firebase'

export interface IPengguna{
  uid: string,
  email: string | null,
  namaPengguna: string | null,
  genderPengguna: number | null,
  tanggalLahirPengguna?: firebase.firestore.Timestamp,
  genreFavorit: Array<string> | null,
  lokasiAsal: number | null,
  tentangPengguna: string | null,
  urlFotoProfilPengguna: string | null,
  urlFotoProfilKecilPengguna: string | null,
  urlFotoProfilBesarPengguna: string | null,
  jenisPengguna: IJenisPengguna,
  pengikut: number | null,
  mengikuti: number | null,
  premiumKadaluarsa?: firebase.firestore.Timestamp | null,
  soelthanKadaluarsa?: firebase.firestore.Timestamp | null,
  jumlahNontonMenit?: number | null,
}

export interface IEditPengguna{
  uid?: string,
  email?: string | null,
  namaPengguna?: string | null,
  genderPengguna?: number | null,
  tanggalLahirPengguna?: firebase.firestore.Timestamp,
  genreFavorit?: Array<string> | null,
  lokasiAsal?: number | null,
  tentangPengguna?: string | null,
  urlFotoProfilPengguna?: string | null,
  urlFotoProfilKecilPengguna?: string | null,
  urlFotoProfilBesarPengguna?: string | null,
  jenisPengguna?: IJenisPengguna,
  pengikut?: number | null,
  mengikuti?: number | null,
  premiumKadaluarsa?: firebase.firestore.Timestamp | null,
  soelthanKadaluarsa?: firebase.firestore.Timestamp | null,
  jumlahNontonMenit?: number | null,
}

export interface IJenisPengguna{
  pengguna: boolean,
  admin: boolean,
  moderator: boolean,
  editor: boolean,
  statusBlokir: boolean,
  premium: boolean,
  soelthan: boolean,
}

export const JenisAktivitas = {
  tambahAnime: 0,
  editAnime: 1,
  tambakKomik: 2,
  editKomik: 3,
  forum: 4,
  anakForum: 5
}

export interface DataAktivitasPengguna{
    //tambahAnime = 0, editAnime = 1, tambahKomik = 2, editKomik = 3, anakForum = 4, Forum = 5
    jenisAktivitas: number;
    idPengguna: string;
    //id pos: anime = idReview + waktu, komik = idReviewKomik + waktu, anakForum = idAnakForum
    idPos: string;
    idAnKom?: string;
    namaPengguna?: string;
    //judul anime untuk anime/manga, judul forum untuk anak forum
    judulPos?: string;
    //isi anak forum
    isiPos?: string;
    //forum
    idForum?: string;
    //anime, 
    episodeSebelum?: number,
    episode?: number;
    //manga
    chapterSebelum?: number;
    chapter?: number;
    //volume
    volumeSebelum?: number;
    volume?: number;
    //skor
    skorSebelum?: number;
    skor?: number;
    //statusNonton atau baca
    statusSebelum?: number;
    status?: number;
    //review anime atau manga
    reviewSebelum?: string;
    review?: string;
    listPenggunaMengikuti?: Array<string  | null>;
    jenisPerubahan?: string;
    isiAktivitas?: string | null;
    waktuAktivitas?: firebase.firestore.Timestamp;
    //feeds
    idFeeds?: string | null;
    idPemilikFeeds?: string | null
}

export class DataIkutDiikuti{
  idDiikuti: string | null = null;
  idPengikut: string | null = null;
  waktuMengikuti: firebase.firestore.Timestamp | null = null;
}

export class DataPemberitahuan{
  idPemberitahuan: string | null = null
  dibaca: boolean = false;
  idAnakForum: string | null = null;
  idDari: string | null = null;
  idForum: string | null = null;
  idKe: string | null = null;
  isi: string | null = null;
  jenisPemberitahuan: number = -1;
  judul: string | null = null;
  matikanPemberitahuan: boolean = false;
  tokenKe: string | null = null;
  waktu: firebase.firestore.Timestamp | null = null;
}


export const JenisPemberitahuan = {
  JENIS: {
    balasanForum: 0,
    balasanForumOP: 1, 
    terblokir: 2,
    komentarDisukai: 3,
  }
}